<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { DEFAULT_LABEL_COLOR, DEFAULT_TEXT_COLOR } from "@/util/constants";
import { applyStyles } from "@/util/methods";
import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  ArrowLeftIcon,
  Edit01Icon,
  InfoCircleIcon,
} from "@gohighlevel/ghl-icons/24/outline";
import {
  UIAlert,
  UIButton,
  UICheckbox,
  UIDivider,
  UIInput,
  UITooltip,
  UICollapse,
  UICollapseItem,
  UITabs,
  UITabPane,
  UISwitch,
  UIInputGroup,
  UIInputGroupLabel,
  UIInputNumber,
} from "@gohighlevel/ghl-ui";
import { computed, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import ButtonSidebar from "../common/ButtonSidebar.vue";
import Card from "../common/Card.vue";
import CodeEditorModel from "../common/CodeEditorModel.vue";
import ColorInputField from "../common/ColorInputField.vue";
import CountInput from "../common/CountInput.vue";
import DateSettings from "../common/DateSettings.vue";
import EditOpportunitySettingsModal from "../common/EditOpportunitySettingsModal.vue";
import PaymentSettings from "../common/Payments/PaymentSettings.vue";
import RichTextEditor from "../common/RichTextEditor.vue";
import TextSidebar from "../common/TextSidebar.vue";
import ListEditor from "../common/ListEditor.vue";
import DropdownEditor from "../common/DropdownEditor.vue";
import RadioSelectEditor from "../common/RadioSelectEditor.vue";
import FileUploadEditor from "../common/FileUploadEditor.vue";
import ScoreEditor from "../common/ScoreEditor.vue";
import { storeToRefs } from "pinia";
import { fieldFullWidth } from "@/util/methods";
import { CONSTANTS } from "../../util/constants";
import { snakeCase } from "lodash";

const { t } = useI18n();
const store = useAppStore();

const props = defineProps({
  selectedIndex: {
    type: Number,
    default: 0,
  },
});

const fieldPlaceholder = store.elements[props.selectedIndex]?.placeholder;
const standardTextField = store.elements[props.selectedIndex];
const buttonField = store.elements[props.selectedIndex];
const { getCustomFieldsFromStore } = storeToRefs(store);

const showModal = ref(false);
const code = ref(store.elements[props.selectedIndex]?.html);
const uniqueKeyPlaceholder = ref(snakeCase(store.elements[props.selectedIndex]?.label));

const isHiddenFieldDisable = computed(() => {
  if (store.elements[props.selectedIndex]?.required) {
    return true;
  }
  return false;
});

const isRequiredFieldDisable = computed(() => {
  if (store.elements[props.selectedIndex]?.hidden) {
    return true;
  }
  return false;
});

const showModelUpdate = (modalUpdate: boolean) => {
  showModal.value = modalUpdate;
};

const submitUpdate = (updatedCode: string) => {
  code.value = updatedCode;
  if (store.elements[props.selectedIndex]) {
    store.elements[props.selectedIndex].html = code.value;
  }
  store.anyUnsavedChanges = true;
};

const onClickOfEditHTML = () => {
  showModal.value = !showModal.value;
  code.value = store.elements[props.selectedIndex]?.html;
};

const onBack = () => {
  store.currentCustomSidebarPreview = undefined;
  store.isAnyElementActive = false;
};

const getLabel = computed(() => {
  return store.elements[props.selectedIndex]?.label;
});

const getTypeLabel = computed(() => {
  return store.elements[props.selectedIndex]?.typeLabel;
});

const getPlaceholder = computed(() => {
  return store.elements[props.selectedIndex]?.placeholder;
});

const getShortLabel = computed(() => {
  return store.elements[props.selectedIndex]?.shortLabel;
});

const getHiddenQuery = computed(() => {
  return store.elements[props.selectedIndex]?.hiddenFieldQueryKey;
});

const getUniqueKey = computed(() => {
  return store.elements[props.selectedIndex]?.fieldKey;
});

const getRequired = computed(() => {
  return store.elements[props.selectedIndex]?.required;
});

const getHidden = computed(() => {
  return store.elements[props.selectedIndex]?.hidden;
});

const getEnableCountryPicker = computed(() => {
  if (store.elements[props.selectedIndex]?.enableCountryPicker === undefined) {
    return true;
  }
  return store.elements[props.selectedIndex].enableCountryPicker;
});

const enableValidateEmailModel = computed({
  get() {
    return store.elements[props.selectedIndex]?.enableValidateEmail ?? false;
  },
  set(value: boolean) {
    store.elements[props.selectedIndex].enableValidateEmail = value;
    store.anyUnsavedChanges = true;
  },
});

const enableValidatePhoneModel = computed({
  get() {
    return store.elements[props.selectedIndex]?.enableValidatePhone ?? false;
  },
  set(value: boolean) {
    store.elements[props.selectedIndex].enableValidatePhone = value;
    store.anyUnsavedChanges = true;
  },
});

const getHiddenValue = computed(() => {
  return store.elements[props.selectedIndex]?.hiddenFieldValue;
});

const getSource = computed(() => {
  return store.elements[props.selectedIndex]?.value;
});

const getAltImage = computed(() => {
  return store.elements[props.selectedIndex]?.alt;
});

const getSpreadColumns = computed(() => {
  return store.elements[props.selectedIndex]?.spreadColumns;
});

const getNumberOfColumns = computed(() => {
  return store.elements[props.selectedIndex]?.columnsNumber;
});

const getDefaultNumberOfColumns = computed(() => {
  return store.elements[props.selectedIndex]?.picklistOptionsImage ? undefined : 1;
});

const getFieldWidthPercentage = () => {
  if (!store.elements[props.selectedIndex]?.fieldWidthPercentage) {
    if (store.builderConfig.layout === CONSTANTS.ONE_COLUMN) {
      store.elements[props.selectedIndex].fieldWidthPercentage = 100;
    } else if (
      store.builderConfig.layout === CONSTANTS.TWO_COLUMN &&
      !fieldFullWidth(store.elements[props.selectedIndex]?.type)
    ) {
      store.elements[props.selectedIndex].fieldWidthPercentage = 50;
    } else if (store.builderConfig.layout === CONSTANTS.SINGLE_LINE) {
      store.elements[props.selectedIndex].fieldWidthPercentage = parseFloat(
        (100 / store.elements.length).toFixed(2)
      );
    }
  }
};

const handleImageWidth = (val) => {
  store.elements[props.selectedIndex].width = val;
  store.anyUnsavedChanges = true;
};

const handleImageHeight = (val) => {
  store.elements[props.selectedIndex].height = val;
  store.anyUnsavedChanges = true;
};

const handleTextColor = (val: string) => {
  store.elements[props.selectedIndex].textColor = val;
  store.anyUnsavedChanges = true;
};

const handleLinkColor = (val: string) => {
  store.elements[props.selectedIndex].linkColor = val;
  store.anyUnsavedChanges = true;
};

const alignImage = (align) => {
  store.elements[props.selectedIndex].align = align;
  store.addToHistory();
  store.anyUnsavedChanges = true;
};

const handleTextUpdate = (output) => {
  //To Maintain Reactivity of Store
  Object.assign(store.elements[props.selectedIndex], output);
};
const handleButtonUpdate = (output) => {
  //To Maintain Reactivity of Store
  Object.assign(store.elements[props.selectedIndex], output);
};
const updatePreview = (element) => {
  element.preview = applyStyles(element.placeholder, element.linkColor, element.textColor);
};

const updatePicklistOptions = (options) => {
  const element = store.elements[props.selectedIndex];
  if (["SINGLE_OPTIONS", "MULTIPLE_OPTIONS", "RADIO", "CHECKBOX"].includes(element?.dataType)) {
    if (element.enableCalculations) {
      element.calculatedOptions = options;
    }
    element.picklistOptions = options.map((option) => option.label);
  } else {
    element.picklistOptions = options;
  }
  if (element.id) {
    element.customfieldUpdated = true;
  }
  store.anyUnsavedChanges = true;
};

const updateEnableCalculations = (newValue) => {
  store.elements[props.selectedIndex].enableCalculations = newValue;
  store.anyUnsavedChanges = true;
};

const updateCalculation = (calculation) => {
  store.elements[props.selectedIndex].calculation = calculation;
  store.anyUnsavedChanges = true;
};

const saveOpportunitySettings = (settings) => {
  store.opportunitySettings = settings;
  store.anyUnsavedChanges = true;
  store.showOpportunitiesModal = false;
};

const tncElement = store.elements[props.selectedIndex];
const checkHideCondition = (elementTag) => {
  let showHideFieldsAlert = [];
  store.builderConfig?.conditionalLogic?.forEach((condition: any) => {
    if (condition?.outcome?.type === "showHideFields") {
      const { value } = condition.outcome;
      const fields = Array.isArray(value) ? value : [value];
      showHideFieldsAlert = [...new Set([...showHideFieldsAlert, ...fields])];
    }
  });
  return showHideFieldsAlert.includes(elementTag?.hiddenFieldQueryKey);
};
watch(
  () => [tncElement?.placeholder, tncElement?.linkColor, tncElement?.textColor],
  () => {
    updatePreview(tncElement);
  }
);

const updateLabel = (value: string) => {
  store.elements[props.selectedIndex].label = value;
  uniqueKeyPlaceholder.value = snakeCase(value);
  if (
    store.elements[props.selectedIndex]?.dataType === "SCORE" &&
    !uniqueKeyPlaceholder.value.startsWith("score_")
  ) {
    uniqueKeyPlaceholder.value = "score_" + uniqueKeyPlaceholder.value;
  }
  updateUniqueKey(uniqueKeyPlaceholder.value);
  store.anyUnsavedChanges = true;
};
const updatePlaceholder = (value: string) => {
  store.elements[props.selectedIndex].placeholder = value;
  store.anyUnsavedChanges = true;
};
const updateShortLabel = (value: string) => {
  store.elements[props.selectedIndex].shortLabel = value;
  store.anyUnsavedChanges = true;
};
const updateHiddenQuery = (value: string) => {
  store.elements[props.selectedIndex].hiddenFieldQueryKey = value;
  store.anyUnsavedChanges = true;
};

const updateUniqueKey = (value: string) => {
  if (value.trim() != "") {
    store.elements[props.selectedIndex].fieldKey = value;
    store.anyUnsavedChanges = true;
  }
};

const updateRequired = (value: boolean) => {
  store.elements[props.selectedIndex].required = value;
  store.addToHistory();
  store.anyUnsavedChanges = true;
};
const updateHidden = (value: boolean) => {
  store.elements[props.selectedIndex].hidden = value;
  store.addToHistory();
  store.anyUnsavedChanges = true;
};
const updateEnableCountryPicker = (value: boolean) => {
  store.elements[props.selectedIndex].enableCountryPicker = value;
  store.addToHistory();
  store.anyUnsavedChanges = true;
};
const updateHiddenValue = (value: string) => {
  store.elements[props.selectedIndex].hiddenFieldValue = value;
  store.anyUnsavedChanges = true;
};
const updateSource = (value: string) => {
  store.elements[props.selectedIndex].value = value;
  store.anyUnsavedChanges = true;
};
const updateAltImage = (value: string) => {
  store.elements[props.selectedIndex].alt = value;
  store.anyUnsavedChanges = true;
};
const updateDateFormat = (value: string) => {
  store.elements[props.selectedIndex].format = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};
const updateDateSeparator = (value: string) => {
  store.elements[props.selectedIndex].separator = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const updateDatedisablePicker = (value: boolean) => {
  store.elements[props.selectedIndex].disablePicker = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const updateSpreadColumns = (value: boolean) => {
  store.elements[props.selectedIndex].spreadColumns = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const updateNumberOfColumns = (value: number) => {
  store.elements[props.selectedIndex].columnsNumber = value;
};

const updateFieldWidthPercentage = (value: number) => {
  store.elements[props.selectedIndex].fieldWidthPercentage = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};
</script>

<template>
  <div>
    <div class="relative mb-6 flex items-center justify-center">
      <div class="arrow-button absolute left-0" @click="onBack">
        <ArrowLeftIcon class="mr-2 h-4 w-4 text-gray-800" />
      </div>
      <div class="text-md text-gray-900">{{ getTypeLabel }}</div>
    </div>
    <div
      :id="
        [
          'TEXTBOX_LIST',
          'SINGLE_OPTIONS',
          'MULTIPLE_OPTIONS',
          'RADIO',
          'CHECKBOX',
          'FILE_UPLOAD',
          'SCORE',
        ].includes(store.elements[selectedIndex]?.dataType) &&
        !store.elements[selectedIndex]?.picklistOptionsImage?.length
          ? ''
          : 'hide-tabs'
      "
    >
      <UITabs justify-content="space-around" default-value="Content" type="line">
        <UITabPane name="Content" :tab="t('common.content')">
          <UICollapse
            id="custom-field-settings"
            :arrow-placement="'left'"
            :default-expanded-names="['General Settings']"
          >
            <UICollapseItem id="general-settings" title="General Settings" name="General Settings">
              <div v-if="store.elements[selectedIndex]?.type == 'html'">
                <h6 v-if="store.elements[selectedIndex]?.label" class="field-name-sidebar">
                  {{ store.elements[selectedIndex]?.label }}
                </h6>
                <div class="form-group">
                  <Card :header="fieldPlaceholder">
                    <template #default>
                      <UIButton
                        id="editHtml"
                        type="default"
                        class="w-[100%]"
                        @click="onClickOfEditHTML"
                        >{{ t("common.editHTML") }}</UIButton
                      >
                    </template>
                  </Card>
                </div>
                <div>
                  <CodeEditorModel
                    v-if="showModal"
                    :model-active="showModal"
                    :code="code"
                    @update:show-model="showModelUpdate"
                    @update:submit="submitUpdate"
                  />
                </div>
              </div>
              <div v-else-if="store.elements[selectedIndex]?.tag === 'header'">
                <TextSidebar
                  :property="JSON.parse(JSON.stringify(store.elements[props.selectedIndex]))"
                  @update:text="handleTextUpdate"
                />
              </div>
              <div v-else-if="store.elements[selectedIndex]?.tag === 'source'">
                <h6 class="field-name-sidebar">{{ store.elements[selectedIndex]?.label }}</h6>
                <div class="form-group">
                  <UIInput
                    id="sourceLabel"
                    :placeholder="t('common.enterSourceValue')"
                    class="standard-input-ghl"
                    :model-value="getSource"
                    @update:model-value="updateSource"
                    @blur="store.addToHistory()"
                  ></UIInput>
                </div>
              </div>
              <div v-else-if="store.elements[selectedIndex]?.tag === 'button'">
                <ButtonSidebar
                  :property="JSON.parse(JSON.stringify(store.elements[props.selectedIndex]))"
                  @update:button="handleButtonUpdate"
                />
              </div>
              <div v-else-if="store.elements[selectedIndex]?.tag === 'captcha'">
                <h6 class="field-name-sidebar">
                  {{ store.elements[selectedIndex]?.label || store.elements[selectedIndex]?.name }}
                </h6>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("common.label") }}</span
                    >
                  </div>
                  <UIInput
                    id="captchaLabel"
                    class="standard-input-ghl"
                    :model-value="getLabel"
                    @update:model-value="updateLabel"
                    @blur="store.addToHistory()"
                  >
                  </UIInput>
                </div>
              </div>
              <div v-else-if="store.elements[selectedIndex]?.tag === 'image'">
                <h6 class="field-name-sidebar">
                  {{ store.elements[selectedIndex]?.label }}
                </h6>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("common.altText") }}</span
                    >
                  </div>
                  <UIInput
                    id="altText"
                    class="standard-input-ghl"
                    :model-value="getAltImage"
                    @update:model-value="updateAltImage"
                    @blur="store.addToHistory()"
                  ></UIInput>
                </div>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <div class="alignment">
                      <div class="alignment-div">{{ t("common.alignment") }}</div>
                      <div class="icons-collection">
                        <AlignLeftIcon
                          class="custom-icons h-5 w-5"
                          @click="alignImage('left')"
                        ></AlignLeftIcon>
                        <AlignCenterIcon
                          class="custom-icons h-5 w-5"
                          @click="alignImage('center')"
                        ></AlignCenterIcon>
                        <AlignRightIcon
                          class="custom-icons h-5 w-5"
                          @click="alignImage('right')"
                        ></AlignRightIcon>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <CountInput
                      :label="t('common.imageWidth')"
                      :value="store.elements[selectedIndex]?.width || ''"
                      :max="1800"
                      @update:value="handleImageWidth"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <CountInput
                      :label="t('common.imageHeight')"
                      :value="store.elements[selectedIndex]?.height || ''"
                      :max="1800"
                      @update:value="handleImageHeight"
                    />
                  </div>
                </div>
              </div>
              <div v-else-if="store.elements[selectedIndex]?.tag === 'terms_and_conditions'">
                <h6 class="field-name-sidebar">
                  {{ store.elements[selectedIndex]?.label || store.elements[selectedIndex]?.name }}
                </h6>
                <div class="form-group flex flex-col gap-y-4">
                  <div>
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("common.text") }}</span
                    >
                    <RichTextEditor
                      :is-submit-message="true"
                      :model-value="getPlaceholder"
                      @update:model-value="updatePlaceholder"
                    ></RichTextEditor>
                  </div>
                  <div>
                    <div class="flex space-x-3">
                      <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
                        {{ t("common.queryKey") }}
                        <UITooltip trigger="hover" placement="top">
                          <template #trigger>
                            <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
                          </template>
                          {{ t("common.querykeyTooltipMessage") }}
                        </UITooltip>
                      </div>
                    </div>
                    <div>
                      <UIInput
                        id="fieldQueryKey"
                        class="standard-input-ghl"
                        :model-value="getHiddenQuery"
                        @update:model-value="updateHiddenQuery"
                        @blur="store.addToHistory()"
                      ></UIInput>
                    </div>
                  </div>
                  <div class="form-check-req-hid">
                    <UICheckbox
                      id="fieldRequired"
                      :disabled="isRequiredFieldDisable"
                      :checked="getRequired"
                      @update:checked="updateRequired"
                      >{{ t("common.required") }}</UICheckbox
                    >
                  </div>
                  <div>
                    <span
                      class="hl-text-input-label mt-1 block text-sm font-medium text-gray-700"
                      >{{ t("common.color") }}</span
                    >
                    <div class="flex">
                      <ColorInputField
                        class="w-2/3"
                        :label="t('common.text')"
                        :color="store.elements[selectedIndex]?.textColor || DEFAULT_TEXT_COLOR"
                        @update:color="handleTextColor"
                      />
                      <ColorInputField
                        class="w-2/3"
                        :label="t('common.link')"
                        :color="store.elements[selectedIndex]?.linkColor || DEFAULT_LABEL_COLOR"
                        @update:color="handleLinkColor"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="store.elements[selectedIndex]?.tag === 'payment'">
                <PaymentSettings
                  :payment="store.elements[selectedIndex]"
                  :selected-index="selectedIndex"
                ></PaymentSettings>
              </div>
              <div v-else-if="['date'].includes(store.elements[selectedIndex]?.type)">
                <DateSettings
                  :date-element="store.elements[selectedIndex]"
                  :index="selectedIndex"
                  :is-required-field-disable="isRequiredFieldDisable"
                  :is-hidden-field-disable="isHiddenFieldDisable"
                  :get-field-width-percentage="getFieldWidthPercentage"
                  :is-form="true"
                  @update-label="updateLabel"
                  @update-placeholder="updatePlaceholder"
                  @update-short-label="updateShortLabel"
                  @update-hidden-query="updateHiddenQuery"
                  @update-field-width-percentage="updateFieldWidthPercentage"
                  @update-required="updateRequired"
                  @update-hidden="updateHidden"
                  @update-hidden-value="updateHiddenValue"
                  @update-date-format="updateDateFormat"
                  @update-date-separator="updateDateSeparator"
                  @update-date-disable-picker="updateDatedisablePicker"
                ></DateSettings>
                <div v-if="store.elements[selectedIndex]?.model === 'opportunity'">
                  <UIDivider />
                  <div class="flex justify-center">
                    <UIButton
                      id="edit-opportunity-settings"
                      type="tertiary"
                      @click="store.showOpportunitiesModal = true"
                    >
                      <Edit01Icon class="mr-2 h-4 w-4" /> {{ t("common.editOpportunitySettings") }}
                    </UIButton>
                  </div>
                </div>
                <EditOpportunitySettingsModal
                  :show="store.showOpportunitiesModal"
                  @update:success="saveOpportunitySettings"
                  @update:cancel="store.showOpportunitiesModal = false"
                />
              </div>
              <div v-else>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
                      t("common.label")
                    }}</span>
                  </div>
                  <UIInput
                    id="label"
                    class="standard-input-ghl"
                    :model-value="getLabel"
                    @update:model-value="updateLabel"
                    @blur="store.addToHistory()"
                  ></UIInput>
                </div>
                <div
                  v-if="
                    !['TEXTBOX_LIST', 'SIGNATURE'].includes(store.elements[selectedIndex]?.dataType)
                  "
                  class="form-group"
                >
                  <div class="flex space-x-3">
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("common.placeholder") }}</span
                    >
                  </div>
                  <UIInput
                    id="fieldPlaceholder"
                    class="standard-input-ghl"
                    :model-value="getPlaceholder"
                    @update:model-value="updatePlaceholder"
                    @blur="store.addToHistory()"
                  ></UIInput>
                </div>
                <div
                  v-if="
                    !['image', 'header', 'button', 'terms_and_conditions'].includes(
                      store.elements[selectedIndex]?.tag
                    )
                  "
                  class="form-group"
                >
                  <div class="flex space-x-3">
                    <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
                      t("common.shortLabel")
                    }}</span>
                  </div>
                  <UIInput
                    id="shortLabel"
                    class="standard-input-ghl"
                    :model-value="getShortLabel"
                    @update:model-value="updateShortLabel"
                    @blur="store.addToHistory()"
                  >
                  </UIInput>
                </div>
                <div
                  v-if="
                    !['TEXTBOX_LIST', 'MULTIPLE_OPTIONS', 'FILE_UPLOAD', 'SIGNATURE'].includes(
                      store.elements[selectedIndex]?.dataType
                    )
                  "
                  class="form-group"
                >
                  <div class="flex space-x-3">
                    <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
                      {{ t("common.queryKey") }}
                      <UITooltip trigger="hover" placement="top">
                        <template #trigger>
                          <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
                        </template>
                        {{ t("common.querykeyTooltipMessage") }}
                      </UITooltip>
                    </div>
                  </div>
                  <UIInput
                    id="fieldQueryKey"
                    class="standard-input-ghl"
                    :model-value="getHiddenQuery"
                    @update:model-value="updateHiddenQuery"
                    @blur="store.addToHistory()"
                  ></UIInput>
                </div>

                <div v-if="!fieldFullWidth(store.elements[selectedIndex]?.type)" class="form-group">
                  <div class="flex justify-between pb-2">
                    <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
                      {{ t("common.style.fieldWidth") }}
                    </div>
                  </div>
                  <UIInputGroup>
                    <UIInputNumber
                      id="fieldWidthKey"
                      size="large"
                      class="w-100"
                      :min="1"
                      :max="100"
                      :show-button="false"
                      :model-value="store.elements[props.selectedIndex]?.fieldWidthPercentage"
                      @update:model-value="updateFieldWidthPercentage"
                      @blur="getFieldWidthPercentage"
                    ></UIInputNumber
                    ><UIInputGroupLabel>%</UIInputGroupLabel>
                  </UIInputGroup>
                </div>
                <div class="form-check-req-hid">
                  <UICheckbox
                    id="fieldRequired"
                    :disabled="isRequiredFieldDisable"
                    :checked="getRequired"
                    @update:checked="updateRequired"
                    >{{ t("common.required") }}</UICheckbox
                  >
                  <UICheckbox
                    v-if="
                      ![
                        'TEXTBOX_LIST',
                        'SINGLE_OPTIONS',
                        'MULTIPLE_OPTIONS',
                        'FILE_UPLOAD',
                        'SIGNATURE',
                      ].includes(store.elements[selectedIndex]?.dataType)
                    "
                    id="fieldCheckbox"
                    :disabled="isHiddenFieldDisable"
                    :checked="getHidden"
                    @update:checked="updateHidden"
                    >{{ t("common.hidden") }}</UICheckbox
                  >
                </div>
                <div v-if="store.elements[selectedIndex]?.hidden" class="form-group hidden-field">
                  <div class="flex space-x-3">
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("common.hiddenValue") }}</span
                    >
                  </div>
                  <UIInput
                    id="fieldHiddenvalue"
                    class="standard-input-ghl"
                    :model-value="getHiddenValue"
                    @update:model-value="updateHiddenValue"
                    @blur="store.addToHistory()"
                  ></UIInput>
                </div>
                <div>
                  <UICheckbox
                    v-if="
                      store.elements[selectedIndex]?.tag == 'phone' ||
                      store.elements[selectedIndex]?.dataType == 'PHONE'
                    "
                    id="fieldCheckbox"
                    :checked="getEnableCountryPicker"
                    @update:checked="updateEnableCountryPicker"
                    >{{ t("common.enableCountryPicker") }}</UICheckbox
                  >
                </div>
                <div v-if="store.elements[selectedIndex]?.model === 'opportunity'">
                  <UIDivider />
                  <div class="flex justify-center">
                    <UIButton
                      id="edit-opportunity-settings"
                      type="tertiary"
                      @click="store.showOpportunitiesModal = true"
                    >
                      <Edit01Icon class="mr-2 h-4 w-4" /> {{ t("common.editOpportunitySettings") }}
                    </UIButton>
                  </div>
                  <p v-if="!store.opportunitySettings" class="pt-6">
                    <UIAlert id="warning-message" type="warning">
                      <template #content>
                        {{ t("common.opportunitySettingsWarning") }}
                      </template>
                    </UIAlert>
                  </p>
                </div>
                <EditOpportunitySettingsModal
                  :show="store.showOpportunitiesModal"
                  @update:success="saveOpportunitySettings"
                  @update:cancel="store.showOpportunitiesModal = false"
                />
                <p v-if="checkHideCondition(store.elements[selectedIndex])" class="mt-3">
                  <UIAlert id="warning-message" type="warning">
                    <template #content> {{ t("common.conditionalLogicAlertOnShowHide") }}</template>
                  </UIAlert>
                </p>
              </div>
              <div
                v-if="
                  store.elements[selectedIndex]?.tag === 'email' &&
                  !store.elements[selectedIndex]?.hidden
                "
              >
                <div class="mt-8 flex justify-between">
                  <span class="hl-text-input-label mb-1 block text-gray-700">
                    {{ t("common.validateEmailHeader") }}
                  </span>
                  <UICheckbox
                    id="email-checkbox"
                    v-model:checked="enableValidateEmailModel"
                    @update:checked="store.addToHistory"
                  />
                </div>
                <div class="text-xs text-gray-700">
                  {{ t("common.validateEmailDescription") }}
                </div>
              </div>
              <div
                v-if="
                  (store.elements[selectedIndex]?.tag === 'phone' ||
                    store.elements[selectedIndex]?.dataType === 'PHONE') &&
                  !store.elements[selectedIndex]?.hidden
                "
              >
                <div class="mt-8 flex justify-between">
                  <span class="hl-text-input-label mb-1 block text-gray-700">
                    {{ t("common.validatePhoneHeader") }}
                  </span>
                  <UICheckbox
                    id="phone-checkbox"
                    v-model:checked="enableValidatePhoneModel"
                    @update:checked="store.addToHistory"
                  />
                </div>
                <div class="mb-4 text-xs text-gray-700">
                  {{ t("common.validatePhoneDescription") }}
                </div>
                <UIAlert id="warning-message" type="warning">
                  <template #content>
                    {{ t("common.validatePhoneWarning") }}
                  </template>
                </UIAlert>
              </div>
            </UICollapseItem>
            <UICollapseItem
              v-if="!store.elements[selectedIndex]?.standard"
              id="advanced-settings"
              title="Advanced Settings"
              name="Advanced Settings"
            >
              <div>
                <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
                  Custom Field Name
                  <UITooltip trigger="hover" placement="top">
                    <template #trigger>
                      <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
                    </template>
                    This field name is unique for each custom field.
                  </UITooltip>
                </div>
                <UIInput
                  id="custom-field-label"
                  class="standard-input-ghl"
                  :model-value="
                    store.elements[props.selectedIndex]?.customFieldLabel ||
                    store.elements[props.selectedIndex]?.name ||
                    store.elements[props.selectedIndex]?.label
                  "
                  :disabled="
                    store.elements[selectedIndex]?.customEdited ||
                    !store.elements[selectedIndex]?.custom
                  "
                  @update:model-value="updateLabel"
                  @blur="store.addToHistory()"
                />
                <div class="switch-handle mb-1 mt-6 block text-sm font-medium text-gray-700">
                  Unique Key
                  <UITooltip trigger="hover" placement="top">
                    <template #trigger>
                      <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
                    </template>
                    This key is unique for each custom field.
                  </UITooltip>
                </div>
                <UIInput
                  id="uniqueKey"
                  class="standard-input-ghl"
                  :model-value="getUniqueKey"
                  :disabled="
                    store.elements[selectedIndex]?.customEdited ||
                    !store.elements[selectedIndex]?.custom ||
                    store.elements[selectedIndex]?.dataType === 'SCORE'
                  "
                  :placeholder="uniqueKeyPlaceholder"
                  v
                  @update:model-value="updateUniqueKey"
                  @blur="store.addToHistory()"
                />
              </div>
            </UICollapseItem>
          </UICollapse>
        </UITabPane>
        <UITabPane
          name="Options"
          :tab="
            store.elements[selectedIndex]?.dataType === 'FILE_UPLOAD'
              ? t('common.uploadOptions')
              : store.elements[selectedIndex]?.dataType === 'SCORE'
              ? t('common.calculations')
              : t('common.options')
          "
          ><ListEditor
            v-if="store.elements[selectedIndex]?.dataType === 'TEXTBOX_LIST'"
            :picklist-options="store.elements[props.selectedIndex]?.picklistOptions"
            :custom-field-created="
              store.elements[selectedIndex]?.customEdited || !store.elements[selectedIndex]?.custom
            "
            @update:picklist-options="updatePicklistOptions"
          />
          <DropdownEditor
            v-if="
              ['SINGLE_OPTIONS', 'MULTIPLE_OPTIONS', 'CHECKBOX'].includes(
                store.elements[selectedIndex]?.dataType
              )
            "
            :options="
              store.elements[props.selectedIndex]?.enableCalculations
                ? store.elements[props.selectedIndex]?.calculatedOptions
                : store.elements[props.selectedIndex]?.picklistOptions
            "
            :enable-calculations="store.elements[props.selectedIndex]?.enableCalculations"
            :custom-field-created="
              store.elements[selectedIndex]?.customEdited || !store.elements[selectedIndex]?.custom
            "
            @update:options="updatePicklistOptions"
          />
          <RadioSelectEditor
            v-if="store.elements[selectedIndex]?.dataType === 'RADIO'"
            :options="
              store.elements[props.selectedIndex]?.enableCalculations
                ? store.elements[props.selectedIndex]?.calculatedOptions
                : store.elements[props.selectedIndex]?.picklistOptions
            "
            :enable-calculations="store.elements[props.selectedIndex]?.enableCalculations"
            :custom-field-created="
              store.elements[selectedIndex]?.customEdited || !store.elements[selectedIndex]?.custom
            "
            @update:options="updatePicklistOptions"
          />
          <FileUploadEditor
            v-if="store.elements[selectedIndex]?.dataType === 'FILE_UPLOAD'"
            :options="store.elements[props.selectedIndex]?.picklistOptions || ['all']"
            @update:options="updatePicklistOptions"
          />
          <ScoreEditor
            v-if="store.elements[selectedIndex]?.dataType === 'SCORE'"
            :calculation="store.elements[props.selectedIndex]?.calculation"
            @update:calculation="updateCalculation"
          />
          <div v-if="store.elements[selectedIndex]?.dataType === 'FILE_UPLOAD'">
            <div class="my-4 flex items-center justify-between">
              <div class="my-auto text-sm font-normal text-gray-700">Allow Multiple Files</div>
              <UISwitch
                v-model:value="store.elements[props.selectedIndex].isMultipleFile"
                size="small"
              />
            </div>
            <div v-if="store.elements[props.selectedIndex].isMultipleFile">
              <div class="mb-2 mt-4 text-sm font-normal text-gray-700">Max File Limit</div>
              <UIInput
                id="max-file-limit"
                v-model:value="store.elements[props.selectedIndex].maxFileSize"
              />
            </div>
          </div>
          <div
            v-if="
              ['SINGLE_OPTIONS', 'MULTIPLE_OPTIONS', 'RADIO', 'CHECKBOX'].includes(
                store.elements[selectedIndex]?.dataType
              ) && store.elements[selectedIndex]?.model !== 'opportunity'
            "
            class="my-4 flex items-center justify-between"
          >
            <div class="my-auto block text-sm font-medium text-gray-700">
              {{ t("common.enableCalculations") }}
            </div>
            <UISwitch
              v-model:value="store.elements[props.selectedIndex].enableCalculations"
              @update:value="updateEnableCalculations"
            />
          </div>
          <div
            v-if="['RADIO', 'CHECKBOX'].includes(store.elements[selectedIndex]?.dataType)"
            class="form-group mt-4"
          >
            <div class="flex justify-between pb-2">
              <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
                Spread to Columns
              </div>
              <UISwitch :value="getSpreadColumns" @update:value="updateSpreadColumns"></UISwitch>
            </div>

            <UIInputGroup v-if="getSpreadColumns">
              <UIInputNumber
                id="fieldQueryKey"
                size="large"
                class="w-100"
                :min="1"
                :max="10"
                :default-value="getDefaultNumberOfColumns"
                :show-button="false"
                :model-value="getNumberOfColumns"
                @update:model-value="updateNumberOfColumns"
                @blur="store.addToHistory()"
              ></UIInputNumber
              ><UIInputGroupLabel>Cols</UIInputGroupLabel>
            </UIInputGroup>
          </div>
          <UIAlert
            v-if="
              ['TEXTBOX_LIST', 'SINGLE_OPTIONS', 'MULTIPLE_OPTIONS', 'RADIO', 'CHECKBOX'].includes(
                store.elements[selectedIndex]?.dataType
              ) &&
              (store.elements[selectedIndex]?.customEdited ||
                !store.elements[selectedIndex]?.custom)
            "
            id="warning-message"
            type="warning"
          >
            <template #content>
              {{ t("common.swapWarningMessageForm") }}
            </template>
          </UIAlert>
        </UITabPane>
      </UITabs>
    </div>
  </div>
</template>

<style>
.field-name-sidebar {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center !important;
  margin-bottom: 1rem !important;
}

#custom-sidebar-form {
  font-size: 14px;
}

.standard-input-ghl {
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.query-key {
  display: flex;
  align-items: baseline;
}

.form-group {
  margin-bottom: 1rem;
}

.hidden-field {
  margin-top: 1rem;
}

.form-check-req-hid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.arrow-button {
  margin-bottom: 0px;
  cursor: pointer;
}

.alignment {
  display: flex;
  margin-bottom: 10px;
}

.alignment-div {
  margin-right: 10px;
}

.custom-icons {
  color: #38a0db;
  margin: 0 10px;
  cursor: pointer;
}
#hide-tabs .n-tabs-nav-scroll-wrapper {
  display: none !important;
}
</style>
