<script setup lang="ts">
import { FormServices } from "@/service/FormServices";
import { SurveyServices } from "@/service/SurveyServices";
import { useAppStore } from "@/store/app";
import { applyStyles, showFieldLabel } from "@/util/methods";
import { XCircleIcon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton, UICheckbox } from "@gohighlevel/ghl-ui";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { CONSTANTS } from "../../util/constants";
import AlertModal from "./AlertModal.vue";
import TextElement from "./Fields/TextElement.vue";
import Payment from "./Payments/Payment.vue";
import SelectField from "./SelectField.vue";

const { t } = useI18n();
const store = useAppStore();

const props = defineProps({
  element: {
    type: Object,
    default() {
      return {};
    },
  },
  fieldStyle: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  slideIndex: {
    type: Number,
    default: undefined,
  },
  currentElement: {
    type: Object,
    required: true,
  },
  formLabelVisible: {
    type: Boolean,
    default: true,
  },
});

const showDeleteModal = ref(false);
const deleteIndex = ref(0);

const onFileChange = (e, index, slideIndex) => {
  const element = e.target;
  if (!element.files) {
    return;
  }
  for (let i = 0; i < element.files.length; i++) {
    imageUploadAndReszie(element.files[i], index, slideIndex);
  }
  element.files = null;
};

const imageUploadAndReszie = async (file: File, index, slideIndex) => {
  const bodyFormData = new FormData();
  let image;
  bodyFormData.append("locationId", store.locationId);
  bodyFormData.append("file", file);
  bodyFormData.append("type", "builder");
  if (store.formId) {
    bodyFormData.append("formId", store.formId);
    image = await FormServices.imageUpload(bodyFormData);
  } else {
    bodyFormData.append("surveyId", store.surveyId);
    image = await SurveyServices.imageUpload(bodyFormData);
  }

  setTimeout(() => {
    if (store.formId) {
      store.elements[
        index
      ].url = `https://images.leadconnectorhq.com/image/f_webp/q_85/r_1000/u_${image.data.imageUrl}`;
    } else {
      store.slides[slideIndex].slideData[
        index
      ].url = `https://images.leadconnectorhq.com/image/f_webp/q_85/r_1000/u_${image.data.imageUrl}`;
    }
  }, 1000);
};

const successCallbackDeleteUpdate = () => {
  store.builderConfig.conditionalLogic = store.builderConfig.conditionalLogic.filter((item) => {
    item.conditions = item?.conditions?.filter(
      (condition) =>
        condition.selectedField !== store.elements[deleteIndex.value].hiddenFieldQueryKey
    );
    return item.conditions?.length > 0;
  });
  store.elements.splice(deleteIndex.value, 1);
  showDeleteModal.value = false;
};

const cancelCallbackDeleteUpdate = () => {
  showDeleteModal.value = false;
};

const removeField = (tag, index, slideIndex) => {
  store.anyUnsavedChanges = true;
  if (store.surveyId) {
    store.slides[slideIndex].slideData.splice(index, 1);
    store.isAnyElementActive = false;
    store.currentActiveIndex = [undefined, undefined];
  } else {
    if (
      store.builderConfig.conditionalLogic?.some((item) =>
        item.conditions?.some(
          (condition) => condition.selectedField === store.elements[index].hiddenFieldQueryKey
        )
      )
    ) {
      showDeleteModal.value = true;
      deleteIndex.value = index;
    } else {
      store.elements.splice(index, 1);
    }
    if (tag === "payment") {
      store.addedProductList = [];
      store.formAction.actionType = "2";
    }
    store.currentCustomSidebarPreview = undefined;
  }
  store.addToHistory();
};

const isActiveElement = (index, slideIndex) => {
  if (store.formId) {
    return store.currentCustomSidebarPreview === index;
  }
  return store.currentActiveIndex[0] === slideIndex && store.currentActiveIndex[1] === index;
};
const headingStyleCSS = computed(() => {
  return {
    color: "#" + (props.element?.color || "000000"),
    fontFamily: props.element?.fontFamily ? `'${props.element?.fontFamily}'` : "Roboto",
    fontSize: (props.element?.fontSize || 40) + "px",
    fontWeight: props.element?.weight || 400,
    backgroundColor: "#" + (props.element?.bgColor || "FFFFFF"),
    boxShadow:
      props.element.shadow?.horizontal +
      "px " +
      props.element.shadow?.vertical +
      "px " +
      props.element.shadow?.blur +
      "px " +
      props.element.shadow?.spread +
      "px #" +
      props.element.shadow?.color,
    padding:
      props.element.padding?.top +
      "px " +
      props.element.padding?.right +
      "px " +
      props.element.padding?.bottom +
      "px " +
      props.element.padding?.left +
      "px",
    border:
      props.element.border?.border +
      "px " +
      props.element.border?.type +
      " #" +
      props.element.border?.color,
    borderRadius: props.element.border?.radius + "px",
    textAlign: "left",
    wordWrap: "break-word",
  };
});
const buttonStyleCSS = computed(() => {
  return {
    backgroundColor: "#" + props.element.bgColor,
    padding:
      props.element?.padding?.top +
      "px " +
      props.element?.padding?.right +
      "px " +
      props.element?.padding?.bottom +
      "px " +
      props.element?.padding?.left +
      "px",
    border:
      props.element?.border + "px " + props.element?.borderType + " #" + props.element?.borderColor,
    borderRadius: (props.element.radius || 0) + "px",
    boxShadow:
      props.element?.shadow?.horizontal +
      "px " +
      props.element?.shadow?.vertical +
      "px " +
      props.element?.shadow?.blur +
      "px " +
      props.element?.shadow?.spread +
      "px #" +
      props.element?.shadow?.color,
    marginTop:
      store.builderConfig.layout !== CONSTANTS.SINGLE_LINE
        ? "10px"
        : store.builderConfig.layout === CONSTANTS.SINGLE_LINE && props.formLabelVisible
        ? "30px"
        : "0px",
    width: props.element?.fullwidth
      ? "100%"
      : store.builderConfig.layout === CONSTANTS.SINGLE_LINE
      ? "100%"
      : props.element.fieldWidthPercentage + "%",
  };
});
const buttonStyleTextCSS = computed(() => {
  return {
    color: "#" + props.element?.color,
    fontSize: props.element?.fontSize + "px",
    fontWeight: props.element?.weight,
    fontFamily: `'${props.element?.fontFamily}'`,
    lineHeight: "1.575rem",
  };
});
const buttonSubTextStyleTextCSS = computed(() => {
  return {
    color: "#" + props.element?.subTextColor,
    fontSize: props.element?.subTextFontSize + "px",
    fontWeight: props.element?.subTextWeight,
    fontFamily: `'${props.element?.subTextFontFamily}'`,
    lineHeight: "1.575rem",
  };
});
const getInputCSSList = (): String => {
  return store.builderConfig.inputStyleType === "line" ? "line-form-style" : "";
};

const labelAlignment = () => {
  if (store.builderConfig.layout === CONSTANTS.ONE_COLUMN) {
    const validAlign = ["left", "right"].includes(store.fieldStyle.labelAlignment);
    return {
      width: validAlign ? store.fieldStyle.labelWidth + "px" : "",
      display: validAlign ? "inline-block" : "block",
      float: validAlign ? "left" : "",
      textAlign: validAlign ? store.fieldStyle.labelAlignment : "",
      padding: validAlign ? "0 10px 0 0" : "",
    };
  }
};
const getStyle = () => {
  return {
    fontSize: store.fieldStyle.placeholderFontSize + "px",
    width: "100%",
  };
};
const getEmailPhoneValidateClass = (fieldWidthPercentage: number) => {
  return [CONSTANTS.TWO_COLUMN, CONSTANTS.SINGLE_LINE].includes(store.builderConfig.layout) &&
    fieldWidthPercentage <= 50
    ? "block space-y-3"
    : "flex";
};
</script>

<template>
  <div class="menu-item">
    <div class="form-builder--item">
      <label
        v-if="element.description !== undefined"
        style="display: block; overflow-wrap: break-word"
        v-html="element.description"
      ></label>
      <div
        v-if="element.type !== 'submit' && showFieldLabel(formLabelVisible, element)"
        :style="labelAlignment()"
      >
        <label v-if="showFieldLabel(formLabelVisible, element)"
          >{{ element.label }}
          <span v-if="element.required">*</span>
        </label>
        <div v-if="element.hidden">
          <small style="background: #e1e1e1; padding: 2px 5px; border-radius: 2px; margin-left: 5px"
            >Hidden</small
          >
        </div>
      </div>
      <TextElement
        v-if="(element.type == 'text' && element.tag !== 'phone') || element.type == 'date'"
        :placeholder="element.placeholder"
        :class-list="[getInputCSSList()]"
        :style-obj="getStyle()"
        :element="element"
      ></TextElement>
      <div v-else-if="element.tag === 'email'" class="flex flex-col">
        <div
          class="gap-x-3 self-stretch"
          :class="[getEmailPhoneValidateClass(element.fieldWidthPercentage)]"
          :style="getStyle()"
        >
          <input
            :placeholder="element.placeholder"
            class="form-control"
            :class="getInputCSSList()"
            :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
            :readonly="true"
          />
          <UIButton
            v-if="element?.enableValidateEmail && !element.hidden"
            id="verify-email"
            class="pointer-events-none h-auto"
            :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
            >Verify Email</UIButton
          >
        </div>

        <div v-if="element.shortLabel" class="short-label">{{ element.shortLabel }}</div>
      </div>
      <div v-else-if="element.tag === 'phone'" class="flex flex-col">
        <div
          class="gap-x-3"
          :class="[getEmailPhoneValidateClass(element.fieldWidthPercentage)]"
          :style="getStyle()"
        >
          <input
            :placeholder="element.placeholder"
            class="form-control"
            :class="getInputCSSList()"
            :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
            :readonly="true"
          />
          <UIButton
            v-if="element?.enableValidatePhone && !element.hidden"
            id="verify-phone"
            class="pointer-events-none h-auto"
            :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
            >Verify Phone</UIButton
          >
        </div>

        <span v-if="element.shortLabel" class="short-label">{{ element.shortLabel }}</span>
      </div>
      <div v-else-if="element.tag === 'country'" class="flex flex-col">
        <SelectField
          :element="element"
          :placeholder="element.placeholder"
          :class="[getInputCSSList()]"
          :style="getStyle()"
        />
        <span v-if="element.shortLabel" class="short-label">{{ element.shortLabel }}</span>
      </div>
      <div v-else-if="element.tag === 'header'">
        <div class="text-element" :style="headingStyleCSS" v-html="element.label"></div>
      </div>
      <div v-else-if="element.tag === 'html'">
        <h5 v-if="element.html == ''">
          {{ element.placeholder }}
        </h5>
        <div v-else>
          <div>
            <small style="background: #e1e1e1; padding: 2px 5px; border-radius: 2px"
              >Script Added!</small
            >
          </div>
          <div v-html="element.html"></div>
        </div>
      </div>
      <div v-else-if="element.tag === 'image'">
        <input
          v-if="!('url' in element)"
          type="file"
          class="form-control"
          placeholder="Image"
          :name="element.tag"
          accept="image/*"
          @change="onFileChange($event, index, slideIndex)"
        />

        <div
          v-else-if="'url' in element"
          class="form-builder--item form-builder--image"
          :align="!element.align ? 'center' : element.align"
        >
          <img
            :src="element.url"
            :alt="element.alt"
            :style="{
              width: !element.width || element.width <= 0 ? '100%' : element.width + 'px',
              height: !element.height || element.height <= 0 ? '100%' : element.height + 'px',
            }"
          />
        </div>
      </div>
      <div v-else-if="element.tag === 'captcha'">
        <img
          class="captcha-wrap-img"
          src="../../assets/img/captcha.png"
          height="78"
          alt="Avatar Name"
        />
      </div>
      <div v-else-if="element.tag === 'source'">
        <div>
          <small style="background: #e1e1e1; padding: 2px 5px; border-radius: 2px"
            >Source Added!</small
          >
        </div>
        <div>
          {{ element.value }}
        </div>
      </div>
      <div v-else-if="element.tag === 'button'" :style="{ textAlign: element.align }">
        <button
          id="submit-button"
          type="button"
          :label="element.label"
          class="btn btn-dark button-element"
          :style="buttonStyleCSS"
        >
          <div :style="buttonStyleTextCSS" v-html="element?.label"></div>
          <div :style="buttonSubTextStyleTextCSS" v-html="element?.submitSubText"></div>
        </button>
      </div>
      <div v-else-if="element.tag === 'terms_and_conditions'">
        <div id="tnc-element" class="flex items-baseline">
          <UICheckbox id="terms-and-conditions" :checked="false" />
          <div
            class="tnc ml-2 text-sm text-gray-600"
            v-html="applyStyles(element.placeholder, element?.linkColor, element?.textColor)"
          />
        </div>
      </div>
      <div v-else-if="element.tag === 'payment'">
        <Payment :payment="element" :input-class="getInputCSSList()"></Payment>
      </div>
      <div v-else class="builder-item">
        {{ element }}
      </div>
    </div>
    <a
      v-if="isActiveElement(index, slideIndex)"
      class="close-icon"
      @mousedown="removeField(element.tag, index, slideIndex)"
    >
      <XCircleIcon class="h-5 w-5"></XCircleIcon>
    </a>
  </div>
  <AlertModal
    v-if="showDeleteModal"
    :description="t('form.conditonalLogic.deleteAlertMessage')"
    :title="t('form.conditonalLogic.deleteField')"
    :positive-text="t('common.delete')"
    :negative-text="t('common.cancel')"
    :show-modal-popup="showDeleteModal"
    header-type="error"
    footer-type="error"
    @pop:change="cancelCallbackDeleteUpdate"
    @pop:positive="successCallbackDeleteUpdate"
    @pop:negative="cancelCallbackDeleteUpdate"
  />
</template>

<style>
.close-icon {
  position: absolute;
  right: -10px;
  top: -10px;
  opacity: 1;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  font-size: 10px;
  /* padding: 5px 7px; */
  background: #f5f5f5;
  color: #2a3135;
  border-radius: 50%;
  height: 20px;
  cursor: pointer;
}
.menu-item {
  position: relative;
}

.form-builder--item {
  border-radius: 4px;
  border: 1px solid transparent;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  position: relative;
}

.form-builder--item h1,
.form-builder--item h2,
.form-builder--item h3,
.form-builder--item h4,
.form-builder--item h5,
.form-builder--item h6 {
  margin-bottom: 0;
}
.form-builder--item label {
  font-size: 80%;
  color: #2a3135;
  overflow-wrap: anywhere;
  hyphens: auto;
}
.form-builder--item label span {
  padding-left: 5px;
}

.captcha-wrap-img {
  width: 304px;
  display: block;
}
.button-element {
  min-width: 85px;
}

#submit-button blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0 !important;
}

#tnc-element {
  word-break: break-word;
}
</style>
