<script setup lang="ts">
import { UIContentWrap } from "@gohighlevel/ghl-ui";
import Postmate from "postmate";
import { ref } from "vue";
import { i18n } from "./plugin/vue-i18n";
import { useAppStore } from "./store/app";
import { setupPendo } from "./util/pendoConfig";
import { setSentryMeta } from "./util/setupSentry";

const appStore = useAppStore();

if (process.env.NODE_ENV === "development") {
  Postmate.debug = true;
}

const locale = ref("en-US");
const handshake = new Postmate.Model({
  updatedToken: (token: string) => appStore.refreshToken(token),
  customFieldAdd: (customAdded: boolean) => appStore.getCustomFields(),
  previewUrl: (previewData: string) => appStore.setPreviewUrl(previewData),
  sendIntegrateInfo: (integrate: object) => appStore.setIntegrateInfo(integrate),
});

handshake.then(async ({ model }: any) => {
  appStore.initialize(model);
  await appStore.getPaymentIntegrationDetails();
  appStore.setAppHandshake(handshake);
  await appStore.getFormOrSurvey();
  appStore.getIntegrateInfo();
  appStore.getCustomFields();
  appStore.getCustomFieldFolders();
  appStore.getPipelines();
  appStore.getPreviewUrl();
  appStore.getAllFonts();
  await appStore.getThemeList();
  if (model.formId) {
    await appStore.getProductStoredInForms();
    await appStore.getProductList();
  }

  i18n.global.locale.value = model.locale ?? "en-US";
  locale.value = model.locale;
  localStorage.setItem("locale", model.locale);
  setSentryMeta({
    userId: appStore.userId,
    companyId: appStore.company.id,
    locationId: appStore.locationId,
    email: appStore.email,
    locale: model.locale,
  });
  setupPendo(model);
});
</script>

<template>
  <UIContentWrap :full-screen="true" :locale="locale">
    <router-view></router-view>
  </UIContentWrap>
</template>
