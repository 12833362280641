<template>
  <UIForm id="facbookPixelEvents" class="w-100">
    <UIFormItem :label="t('common.onpageView')">
      <UISelect
        id="facebookEventsPageView"
        :value="store.facebook.pageViewEvent"
        :options="FB_EVENTS"
        @update:value="updateFacebookEventPageView"
      />
    </UIFormItem>
    <UIFormItem
      :label="isSurvey ? `${t('survey.onSurveySubmission')}` : `${t('form.onFormSubmission')}`"
    >
      <UISelect
        id="facebokEventsSubmission"
        :value="store.facebook.formSubmissionEvent"
        :options="FB_EVENTS"
        @update:value="updateFacebookEventsSubmission"
      />
    </UIFormItem>
  </UIForm>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { FB_EVENTS } from "@/util/constants";
import { UIForm, UIFormItem, UISelect } from "@gohighlevel/ghl-ui";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const store = useAppStore();

defineProps({
  isSurvey: {
    type: Boolean,
    default: false,
    required: true,
  },
});

const updateFacebookEventPageView = (value: string) => {
  store.facebook.pageViewEvent = value;
  store.anyUnsavedChanges = true;
  addToHistoryWithDelay();
};
const updateFacebookEventsSubmission = (value: string) => {
  store.facebook.formSubmissionEvent = value;
  store.anyUnsavedChanges = true;
  addToHistoryWithDelay();
};

const addToHistoryWithDelay = () => {
  setTimeout(() => store.addToHistory(), 100);
};
</script>
