<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { ArrowNarrowLeftIcon, InfoCircleIcon } from "@gohighlevel/ghl-icons/24/outline";
import {
  UICodeEditor,
  UICollapse,
  UICollapseItem,
  UITextSmMedium,
  UITooltip,
} from "@gohighlevel/ghl-ui";
import { useI18n } from "vue-i18n";
import FormStyle from "../StyleSettings/FormStyle.vue";
import InputFieldStyle from "../StyleSettings/InputFieldStyle.vue";
import LabelStyle from "../StyleSettings/LabelStyle.vue";
import ShortLabelStyle from "../StyleSettings/ShortLabelStyle.vue";
import PlaceholderStyle from "../StyleSettings/PlaceholderStyle.vue";

import { computed } from "vue";
const { t } = useI18n();
const store = useAppStore();

const props = defineProps({
  isSurvey: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["backToNormalSettings"]);

const customCSSModal = computed({
  get() {
    return store.formStyle.customStyle;
  },
  set(value) {
    store.formStyle.customStyle = value;
    store.anyUnsavedChanges = true;
  },
});

const backToNormalSettings = () => {
  emits("backToNormalSettings");
};
</script>

<template>
  <section id="advanceSettings" class="flex flex-col gap-2">
    <section class="flez items-center gap-2 px-3 pb-2">
      <div class="flex cursor-pointer items-center gap-2" @click="backToNormalSettings">
        <ArrowNarrowLeftIcon class="h-4 w-4"></ArrowNarrowLeftIcon>
        <UITextSmMedium>{{ t("common.back") }}</UITextSmMedium>
      </div>
    </section>
    <UICollapse :accordion="true" :arrow-placement="'left'" :default-expanded-names="['form']">
      <UICollapseItem id="form" name="form">
        <template #header
          ><div>{{ isSurvey ? t("survey.typeOfBuilder") : t("form.typeOfBuilder") }}</div></template
        >
        <div class="collapse-content-container">
          <div class="collapse-content">
            <FormStyle></FormStyle>
          </div>
        </div>
      </UICollapseItem>
      <UICollapseItem id="inputField" name="inputField">
        <template #header
          ><div>{{ t("common.style.inputField") }}</div></template
        >
        <div class="collapse-content-container">
          <div class="collapse-content">
            <InputFieldStyle></InputFieldStyle>
          </div>
        </div>
      </UICollapseItem>
      <UICollapseItem id="label" name="label">
        <template #header
          ><div>{{ t("common.label") }}</div></template
        >
        <div class="collapse-content-container">
          <div class="collapse-content">
            <LabelStyle></LabelStyle>
          </div>
        </div>
      </UICollapseItem>
      <UICollapseItem id="shortLabel" name="shortLabel">
        <template #header
          ><div>{{ t("common.shortLabel") }}</div></template
        >
        <div class="collapse-content-container">
          <div class="collapse-content">
            <ShortLabelStyle></ShortLabelStyle>
          </div>
        </div>
      </UICollapseItem>
      <UICollapseItem id="placeholder" name="placeholder">
        <template #header
          ><div>{{ t("common.placeholder") }}</div></template
        >
        <div class="collapse-content-container">
          <div class="collapse-content">
            <PlaceholderStyle></PlaceholderStyle>
          </div>
        </div>
      </UICollapseItem>
      <UICollapseItem id="customCSS" name="customCSS">
        <template #header>
          {{ t("common.customCSS") }}
          <UITooltip trigger="hover" placement="top" :style="{ maxWidth: '300px' }">
            <template #trigger>
              <InfoCircleIcon class="ml-1 h-4 w-4 text-gray-400"></InfoCircleIcon>
            </template>
            {{ t("common.customCSSTooltip") }}
          </UITooltip></template
        >
        <div class="collapse-content-container">
          <div class="collapse-content">
            <UICodeEditor
              id="ghl-editor"
              ref="editorRef"
              v-model:value="customCSSModal"
              language="css"
              :resize="true"
              :prettify="true"
            />
          </div>
        </div>
      </UICollapseItem>
    </UICollapse>
  </section>
</template>

<style>
.back-section {
  display: flex;
  width: 360px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
}
#advanceSettings {
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-self: stretch;
}
#advanceSettings .n-collapse-item__header {
  display: flex;
  align-items: start;
  padding: 16px;
  gap: 20px;
  border-bottom: 1px solid v #eaecf0;
  background: #f9fafb;
}
#advanceSettings .n-collapse .n-collapse-item {
  margin-top: 0;
}
</style>
