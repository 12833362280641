<script lang="ts" setup>
import { useAppStore } from "@/store/app";
import { IS_EMPTY, IS_FILLED } from "@/util/constants";
import {
  allOperationOptions,
  standardFields,
  dateFormatForUI,
  dateFormatForDayjs,
} from "@/util/methods";
import { Trash02Icon } from "@gohighlevel/ghl-icons/24/outline";
import {
  UIAlert,
  UIDatepicker,
  UIInput,
  UIModalFooter,
  UISelect,
  UITabPane,
  UITabs,
  UITooltip,
} from "@gohighlevel/ghl-ui";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { computed, h, ref, VNode } from "vue";
import RichTextEditor from "./RichTextEditor.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

dayjs.extend(customParseFormat);
const props = defineProps({
  editIndex: {
    type: Number,
    default: 0,
  },
  type: {
    type: String,
    default: "displayCustomMessage",
  },
});

const showPhoneAlert = ref(false);
const disqualifyActionOptions = [
  { label: t("form.conditonalLogic.showCustomMessage"), value: "showCustomMessage" },
  { label: t("common.openUrl"), value: "openUrl" },
];

const showHideOptions = [
  { label: "Hide", value: "Hide" },
  { label: "Show", value: "Show" },
  { label: "Hide Multiple", value: "Hide Multiple" },
  { label: "Show Multiple", value: "Show Multiple" },
];
const showMultiple = computed(() => hideType.value.includes("Multiple"));

const store = useAppStore();
const emit = defineEmits(["onSave"]);
const sharedSelectedOperation = ref(
  store.builderConfig.conditionalLogic?.[props.editIndex].conditionalOperation || "then"
);
const disqualifyAction = ref(
  store.builderConfig.conditionalLogic?.[props.editIndex].outcome?.disqualifyAction ||
    "showCustomMessage"
);
const outcomeValue = ref(
  store.builderConfig.conditionalLogic?.[props.editIndex].outcome?.value || ""
);
const hideType = ref(
  store.builderConfig.conditionalLogic?.[props.editIndex].outcome?.hideType || "Hide"
);
const conditions = ref(
  store.builderConfig.conditionalLogic?.[props.editIndex]?.conditions || [
    {
      selectedField: null,
      selectedOperation: null,
      inputValue: null,
    },
  ]
);

const addCondition = (condition, index) => {
  sharedSelectedOperation.value = condition;
  if (condition !== "then" && index === conditions.value.length - 1) {
    conditions.value.push({
      selectedField: null,
      selectedOperation: null,
      inputValue: null,
    });
  }
};

const deleteCondition = (index) => {
  conditions.value.splice(index, 1);
  if (conditions.value.length === 0) {
    conditions.value.push({
      selectedField: null,
      selectedOperation: null,
      inputValue: null,
    });
  }
};

const isConditionInvalid = (condition) => {
  if ([IS_EMPTY, IS_FILLED].includes(condition.selectedOperation)) {
    return false;
  }
  return !(condition.selectedField && condition.selectedOperation && condition.inputValue);
};

const isSaveDisabled = computed(
  () =>
    conditions.value.some((condition) => {
      if ([IS_EMPTY, IS_FILLED].includes(condition.selectedOperation)) {
        return false;
      }
      return !(condition.selectedField && condition.selectedOperation && condition.inputValue);
    }) ||
    (!Array.isArray(outcomeValue.value) && outcomeValue.value.trim() === "")
);

const onSave = () => {
  conditions.value = conditions.value.map((condition) => {
    if (getType(condition) === "monetory" && !isNaN(parseFloat(condition.inputValue))) {
      return {
        ...condition,
        inputValue: parseFloat(condition.inputValue).toFixed(2),
      };
    }
    return condition;
  });
  const savedLogic: any = {
    conditions: conditions.value,
    outcome: { type: props.type, value: outcomeValue.value },
    conditionalOperation: sharedSelectedOperation.value,
  };
  if (props.type === "disqualifyLead") {
    savedLogic.outcome = {
      ...savedLogic.outcome,
      disqualifyAction: disqualifyAction.value,
    };
  }
  if (props.type === "showHideFields") {
    savedLogic.outcome = {
      ...savedLogic.outcome,
      hideType: hideType.value,
    };
  }
  store.builderConfig.conditionalLogic[props.editIndex] = savedLogic;
  store.anyUnsavedChanges = true;
  emit("onSave");
  store.addToHistory();
};

const fieldOptions = store.elements
  .filter(
    ({ type }) =>
      ![
        ...[
          ...standardFields.customized.group.filter((x) => x.type !== "score"),
          ...standardFields.submit.group,
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...(standardFields?.integration?.group || []),
        ].map((x) => x.type),
        "signature",
        "file_upload",
        "textbox_list",
        "multiple_options",
        "checkbox",
      ].includes(type)
  )
  .map((item) => ({
    value: item.hiddenFieldQueryKey,
    label: item.label,
    type: item.type,
    picklistOptions:
      item.picklistOptions || item?.picklistOptionsImage?.map((x) => x.label) || null,
  }));

const showHideFieldOptions = store.elements
  .filter(
    ({ type }) =>
      ![
        ...[
          ...standardFields.submit.group,
          ...standardFields.customized.group.filter((x) => x.type !== "score"),
          // eslint-disable-next-line no-unsafe-optional-chaining
        ].map((x) => x.type),
      ].includes(type)
  )
  .map((item) => ({
    value: item.hiddenFieldQueryKey,
    label: item.label.replace(/<\/?[^>]+(>|$)/g, ""),
    type: item.type,
    picklistOptions:
      item.picklistOptions || item?.picklistOptionsImage?.map((x) => x.label) || null,
  }));

const operationOptions = ref(allOperationOptions);

const getType = (condition) => fieldOptions.find((x) => x.value === condition.selectedField)?.type;

const getPicklistOptions = (condition) =>
  fieldOptions
    .find((x) => x.value === condition.selectedField)
    ?.picklistOptions?.map((item) => ({
      value: item,
      label: item,
    }));

const updateOptions = (condition, value, oldSelectedField = null) => {
  const oldSelectedType = fieldOptions.find((x) => x.value === oldSelectedField)?.type;
  const selectedType = fieldOptions.find((x) => x.value === value).type;
  if (oldSelectedField && oldSelectedType !== selectedType) {
    condition.inputValue = "";
    if (["single_options", "radio"].includes(selectedType)) {
      condition.inputValue = getPicklistOptions(condition)?.[0]?.value;
    }
    if (selectedType === "date") {
      condition.inputValue = dayjs().format("YYYY-MM-DD");
    }
  }
  showPhoneAlert.value = selectedType === "phone" || value === "phone";
  operationOptions.value = allOperationOptions.filter((option) =>
    option.types.includes(selectedType)
  );
};

const hideInput = (condition) => [IS_FILLED, IS_EMPTY].includes(condition.selectedOperation);

const renderOptions = ({ node, option }: { node: VNode; option }) =>
  h(
    UITooltip,
    { placement: "left-start" },
    {
      trigger: () => node,
      default: () => option.label,
    }
  );
const dateTypeFieldValue = (condition: any) => {
  const format = dateTypeFieldFormatDayjs(condition);
  const date = dayjs(condition.inputValue, format).isValid()
    ? dayjs(condition.inputValue, format).valueOf()
    : new Date().valueOf();
  return date;
};

const dateTypeFieldFormat = (condition: any) => {
  const dateElement = store.elements.find(
    (item) => item.hiddenFieldQueryKey === condition.selectedField
  );
  const format = dateFormatForUI(dateElement.format, dateElement.separator);
  return format;
};
const dateTypeFieldFormatDayjs = (condition: any) => {
  const dateElement = store.elements.find(
    (item) => item.hiddenFieldQueryKey === condition.selectedField
  );
  const format = dateFormatForDayjs(dateElement.format, dateElement.separator);
  return format;
};
</script>

<template>
  <div class="divider h-[489px] overflow-auto">
    <div class="flex justify-between py-3 font-medium">
      {{ t("form.conditonalLogic.logic.if") }}
      <Trash02Icon
        v-if="conditions.length > 1"
        class="mr-3 h-4 w-4 cursor-pointer text-error-700"
        @click="deleteCondition(0)"
      />
    </div>
    <template v-for="(condition, index) in conditions" :key="index">
      <div class="mb-2 flex flex-col gap-y-2 text-base">
        <div class="mb-8 flex gap-x-2">
          <UISelect
            :id="`selectedField-${index}`"
            class="w-full"
            :value="condition.selectedField"
            :options="fieldOptions"
            :render-option="renderOptions"
            placeholder="Select"
            filterable
            @update:value="
              (value) => {
                const oldSelectedField = condition.selectedField;
                condition.selectedField = value;
                updateOptions(condition, value, oldSelectedField);
                condition.selectedOperation = operationOptions[0].value;
              }
            "
          />
          <UISelect
            :id="`selectedOperation-${index}`"
            class="w-full"
            :value="condition.selectedOperation"
            :options="operationOptions"
            :render-option="renderOptions"
            placeholder="Select"
            filterable
            @click="updateOptions(condition, condition.selectedField)"
            @update:value="(value) => (condition.selectedOperation = value)"
          />
          <UIDatepicker
            v-if="getType(condition) === 'date'"
            id="input_datepicker"
            class="w-full"
            :class="hideInput(condition) ? 'invisible' : 'visible'"
            :value="dateTypeFieldValue(condition)"
            :format="dateTypeFieldFormat(condition)"
            @update:value="
              (value) =>
                (condition.inputValue = dayjs(value).format(dateTypeFieldFormatDayjs(condition)))
            "
          />
          <UISelect
            v-else-if="['single_options', 'radio'].includes(getType(condition))"
            :id="`picklistOptions-${index}`"
            class="w-full"
            :class="hideInput(condition) ? 'invisible' : 'visible'"
            :value="condition.inputValue"
            :options="getPicklistOptions(condition)"
            :render-option="renderOptions"
            placeholder="Select"
            filterable
            @update:value="(value) => (condition.inputValue = value)"
          />
          <UIInput
            v-else
            :id="`inputValue-${index}`"
            class="w-full"
            :class="hideInput(condition) ? 'invisible' : 'visible'"
            :model-value="condition.inputValue"
            placeholder=""
            @update:model-value="(value) => (condition.inputValue = value)"
          />
        </div>
        <div class="flex items-center justify-between">
          <div :class="index === conditions.length - 1 ? 'w-[88%]' : 'w-3/5'">
            <UITabs
              default-value="then"
              type="segment"
              :value="index === conditions.length - 1 ? 'then' : sharedSelectedOperation"
              @update:value="(condition) => addCondition(condition, index)"
            >
              <UITabPane
                v-if="index === conditions.length - 1"
                name="then"
                :tab="t('form.conditonalLogic.logic.then')"
                >{{ t("form.conditonalLogic.logic.then") }}</UITabPane
              >
              <UITabPane
                name="and"
                :tab="t('form.conditonalLogic.logic.and')"
                :disabled="isConditionInvalid(condition)"
              >
                {{ t("form.conditonalLogic.logic.and") }}
              </UITabPane>
              <UITabPane
                name="or"
                :tab="t('form.conditonalLogic.logic.or')"
                :disabled="isConditionInvalid(condition)"
              >
                {{ t("form.conditonalLogic.logic.or") }}</UITabPane
              >
            </UITabs>
          </div>
          <Trash02Icon
            v-if="index !== conditions.length - 1"
            class="mr-3 h-4 w-4 cursor-pointer text-error-700"
            @click="deleteCondition(index + 1)"
          />
        </div>
      </div>
    </template>
    <UIAlert v-if="showPhoneAlert" id="warning-message" type="warning">
      <template #content>{{ t("form.conditonalLogic.phoneAlertMessage") }}</template>
    </UIAlert>
    <div id="output" class="mb-3 mt-3">
      <div class="mb-2 text-xs font-medium text-gray-700">
        <div class="mb-3 flex">
          <div v-if="props.type === 'disqualifyLead'" class="flex w-full gap-x-2 text-gray-900">
            <UISelect
              :id="`disqualify-action`"
              v-model:value="disqualifyAction"
              class="w-full"
              :options="disqualifyActionOptions"
              :render-option="renderOptions"
              placeholder="Select"
              @update:value="() => (outcomeValue = '')"
            />
          </div>
          <div v-if="props.type === 'displayCustomMessage'">
            {{ t("form.conditonalLogic.showCustomMessage") }}
          </div>
        </div>
        <RichTextEditor
          v-if="
            props.type === 'displayCustomMessage' ||
            (props.type === 'disqualifyLead' && disqualifyAction === 'showCustomMessage')
          "
          v-model:modelValue="outcomeValue"
          :is-submit-message="true"
        />
      </div>
      <div
        v-if="
          props.type === 'redirectToUrl' ||
          (props.type === 'disqualifyLead' && disqualifyAction === 'openUrl')
        "
        class="mb-2 text-xs font-medium text-gray-700"
      >
        {{ t("form.conditonalLogic.openBelowUrl") }}
        <UIInput
          id="redirectToUrlInput"
          v-model="outcomeValue"
          class="my-3 font-normal"
          placeholder="https://www.example.com"
        />
        <div class="text-xs font-normal text-gray-600">
          {{ t("common.addUrlLike") }}<span class="underline">example.com</span>,
          <span class="underline">https://example.com</span>,
          <span class="underline">example.com/path</span> {{ t("common.or") }}
          <span class="underline">https://www.example.com/path</span>.
        </div>
      </div>
      <div v-if="props.type === 'showHideFields'" class="mb-2 text-xs font-medium text-gray-700">
        <div class="mb-8 flex gap-x-2">
          <UISelect
            id="showHideFieldType"
            v-model:value="hideType"
            class="w-1/3"
            :options="showHideOptions"
            :render-option="renderOptions"
            placeholder="Select"
            filterable
          />
          <UISelect
            id="selectedFieldShowHide"
            v-model:value="outcomeValue"
            :options="showHideFieldOptions"
            :render-option="renderOptions"
            placeholder="Select"
            filterable
            :multiple="showMultiple"
            type="avatar"
          />
        </div>
      </div>
    </div>
  </div>
  <UIModalFooter
    id="modal-footer"
    :positive-text="t('common.save')"
    :disabled="isSaveDisabled"
    :loading="false"
    @positive-click="onSave"
  />
</template>

<style scoped>
.divider {
  border-bottom: 1px solid #eaecf0;
  width: 100%;
}
:deep(.icon-custom) {
  border: 0;
  box-shadow: none;
  display: block;
}
.n-tabs {
  width: 25%;
}
:deep(.n-tabs-tab) {
  border-radius: 6px !important;
}
.n-tab-pane {
  display: none;
}
:deep(.n-tabs-rail) {
  border-radius: 6px;
}
:deep(.n-avatar) {
  display: none !important;
}
:deep(.n-tag__content) {
  padding-left: 6px;
}
</style>
