{
  "common": {
    "addCustomFields": "Adicionar campos personalizados",
    "addElement": "Adicionar Elemento",
    "addUrlLike": "Adicione URLs válidos como",
    "address": "Endereço",
    "agencyBranding": "Marca da agência",
    "alert": "Alerta",
    "alignment": "Alinhamento",
    "altText": "Texto alternativo",
    "back": "Voltar",
    "background": "Fundo",
    "backgroundImage": "Imagem de fundo",
    "backgroundImageTooltipMessage": "A imagem de fundo tem como objetivo cobrir toda a página, proporcionando um cenário visualmente atraente para o formulário. \nPara visualizar a imagem de fundo, clique na opção Visualizar.",
    "blur": "Borrão",
    "border": "Fronteira",
    "borderType": "Tipo de borda",
    "bottom": "Fundo",
    "buttonStyle": "Estilo de botão",
    "cancel": "Cancelar",
    "cancelKeep": "Cancelar, manter",
    "color": "Cor",
    "conditionalLogicAlertOnSubmit": "A lógica condicional terá precedência sobre a mensagem/redirecionamento aqui",
    "contactInfo": "Informações de contato",
    "cornerRadius": "Raio de canto",
    "customCSS": "CSS customizado",
    "customCSSTooltip": "CSS personalizado tem precedência sobre o estilo do formulário",
    "customFieldSearch": "Pesquise por nome ou tipo",
    "customFields": "Os campos personalizados",
    "customized": "Personalizado",
    "delete": "Excluir",
    "doNotAddRelativePath": "Não adicione caminhos relativos como",
    "doNotShowMessage": "Não mostre novamente",
    "done": "Feito",
    "editHTML": "Editar HTML",
    "embed": "Embutir",
    "enableCountryPicker": "Ativar seletor de país",
    "enterSourceValue": "Insira o valor de origem",
    "facebookPixelEvents": "Eventos de pixel do Facebook",
    "facebookPixelID": "ID do pixel do Facebook",
    "facebookPixelIDMessage": "Ignore este campo se você planeja usar este formulário/pesquisa dentro de um funil",
    "fieldDescription": "Descrição do campo",
    "fieldStyle": "Estilo de campo",
    "fieldTitle": "Título do campo",
    "fontColor": "Cor da fonte",
    "fontFamily": "Família de fontes",
    "fontFamilyTooltip": "O Builder oferece suporte a todos os tipos de fontes, caso você não consiga vê-las na lista. \nPor favor pesquise no menu suspenso",
    "fullWidth": "Largura completa",
    "headerImage": "Imagem de cabeçalho",
    "headerImageTooltipMessage": "A imagem do cabeçalho foi projetada para cobrir toda a largura do formulário e permanece posicionada na parte superior do formulário. \nPara visualizar a imagem do cabeçalho, clique na opção Visualizar.",
    "hidden": "Escondido",
    "hiddenValue": "Valor oculto",
    "horizontal": "Horizontal",
    "htmlEditorBodyMessage": "Adicione seu código aqui",
    "htmlEditorHeader": "JAVASCRIPT/HTML PERSONALIZADO",
    "htmlMessage": "O HTML personalizado vai aqui",
    "iframeEmbed": "Incorporar Ifrmae",
    "imageHeight": "Altura da imagem",
    "imageUploadMessage": "Clique ou arraste um arquivo de imagem para esta área para fazer upload",
    "imageWidth": "Largura da imagem",
    "inputStyle": "Estilo de entrada",
    "integrate": "Integrar",
    "label": "Rótulo",
    "layout": "Disposição",
    "left": "Esquerda",
    "link": "Link",
    "logic": "Lógica",
    "miscellaneous": "Diversos",
    "onSubmit": "Ao enviar",
    "onpageView": "Na visualização da página",
    "options": "Opções",
    "or": "ou",
    "padding": "Preenchimento",
    "personalInfo": "Informação pessoal",
    "placeholder": "Espaço reservado",
    "placeholderFacebookPixelIdInput": "Insira o ID do pixel do Facebook",
    "pleaseInput": "Por favor insira",
    "preview": "Visualização",
    "proceed": "Continuar",
    "queryKey": "Chave de consulta",
    "querykeyTooltipMessage": "A chave de consulta que pode ser usada como parâmetro de URL para preencher este campo",
    "quickAdd": "Adição rápida",
    "required": "Obrigatório",
    "right": "Certo",
    "save": "Salvar",
    "saving": "Salvando",
    "shadow": "Sombra",
    "shortLabel": "Etiqueta curta",
    "showLabel": "Mostrar etiqueta",
    "size": "Tamanho",
    "spread": "Espalhar",
    "stickyContact": "Contato pegajoso",
    "stickyContactMessage": "O contato fixo preencherá automaticamente as informações de contato inseridas anteriormente para que você não precise inseri-las duas vezes.",
    "styles": "Estilos",
    "subText": "Sub-texto",
    "submit": "Enviar",
    "text": "Texto",
    "textStyle": "Estilo de texto",
    "themes": {
      "alertThemeMessage": "A troca de temas resultará na perda de todas as modificações de estilo atuais.",
      "removeTheme": "Remover tema",
      "themes": "Temas",
      "useTheme": "Usar tema"
    },
    "top": "Principal",
    "useGDPRCompliantFont": "Use fonte compatível com GDPR",
    "vertical": "Vertical",
    "weight": "Peso",
    "width": "Largura",
    "yesSave": "Sim, salvar",
    "box": "Caixa",
    "line": "Linha",
    "message": "Mensagem",
    "openUrl": "Abrir URL",
    "borderTypeList": {
      "dotted": "Pontilhado",
      "none": "Nenhum",
      "solid": "Sólido",
      "dashed": "Tracejadas"
    },
    "button": "Botão",
    "enableTimezone": "Ativar fuso horário",
    "emailNotification": "Notificação de Email",
    "emailNotificationSubheader": "Receba um e-mail quando alguém preencher seu formulário",
    "subject": "Assunto",
    "emailTo": "Email para)",
    "senderName": "Nome do remetente",
    "senderNameWarning": "Seu (nome da subconta) será adicionado se for deixado em branco",
    "emailTooltip": "O primeiro endereço de e-mail é designado para o campo ‘Para’. \nQuaisquer endereços de e-mail subsequentes serão incluídos no campo 'Cc'.",
    "emailError": "É necessário pelo menos um endereço de e-mail",
    "clear": "Claro",
    "showNotificationsSettings": "Mostrar configurações de notificações",
    "emailServicePaid": "Este recurso é um serviço pago e incorrerá em cobranças com base no seu uso.",
    "integration": "Integração",
    "payment": {
      "connect": "Conectar",
      "connected": "Conectado",
      "footerInfo": "Informações do rodapé",
      "includeOtherAmount": "Incluir outro valor",
      "live": "Ao vivo",
      "paymentFields": "Campos de pagamento",
      "selectCurrency": "Escolha a moeda",
      "status": "Status",
      "suggestAnAmount": "Sugira um valor",
      "test": "Teste",
      "liveModeOn": "Modo ao vivo",
      "customAmount": "Valor personalizado",
      "paymentType": "Tipo de pagamento",
      "product": {
        "addProduct": "Adicionar produto",
        "clickHere": "Clique aqui",
        "description": "Descrição",
        "editThisProduct": "Para editar este produto",
        "image": "Imagem",
        "layoutSettings": "Configurações de Layout",
        "maxProductError": "Máximo de 20 produtos são permitidos em um formulário",
        "productSelectPlaceholder": "Selecione um produto",
        "productSettings": "Configurações do produto",
        "selectProduct": "Selecione o produto",
        "show": "Mostrar",
        "singleColumn": "Coluna Única",
        "threeColumn": "Três Colunas",
        "twoColumn": "Duas colunas",
        "orderConfirmationWarning": "Uma página de confirmação do pedido será exibida assim que o pagamento for bem-sucedido",
        "orderConfirmation": "Confirmação do pedido",
        "createProduct": "Para criar um produto",
        "quantity": "Quantidade"
      },
      "sellProducts": "Vender produtos",
      "generalSettings": "Campos gerais",
      "tagActiveBGColor": "Cor da etiqueta ativa",
      "collectPayment": "Recolher pagamento",
      "payments": "Pagamentos",
      "coupons": {
        "couponAlignment": "Alinhamento de cupom",
        "coupons": "Cupons",
        "enableCoupon": "Ativar cupom",
        "fullWidth": "Largura completa",
        "left": "Esquerda",
        "right": "Certo",
        "tooltipMessage": "Defina a posição da entrada do cupom dos tipos abaixo"
      }
    },
    "autoResponder": "Resposta automática",
    "autoResponderSubheader": "Envie um e-mail automático para a pessoa que envia o formulário (o e-mail inclui uma cópia das informações inseridas no formulário)",
    "replyToEmail": "Responder ao e-mail",
    "open": "Abrir",
    "won": "Ganho",
    "lost": "Perdido",
    "abandon": "Abandono",
    "selectAPipeline": "Selecione um pipeline",
    "stage": "Estágio",
    "status": "Status",
    "opportunityValue": "Valor da oportunidade",
    "createNewPipeline": "Criar novo pipeline",
    "editOpportunitySettings": "Editar configurações de oportunidade",
    "contact": "Contato",
    "opportunity": "Oportunidade",
    "add": "Adicionar",
    "more": "Mais",
    "opportunityHeading": "Onde você deseja que sua oportunidade seja atualizada?",
    "date": {
      "dateFormat": "Formato de data",
      "dateFormatTooltipMessage": "Selecione um formato de data. \nD representava o dia, M representava meses e Y representava ano",
      "dateSeparator": "Separador de data",
      "dateSeparatorTooltipMessage": "Selecione um separador que separará dia, mês e ano no formato selecionado em Formato de data",
      "dateSettings": "Configurações de data",
      "disablePicker": "Desativar seletor"
    },
    "restoreVersion": "Restaurar versão",
    "versionHistory": "Histórico de versões",
    "errorMessage": "Erro, tente novamente",
    "version": "Versão:",
    "currentVersion": "Versão Atual",
    "conditionalLogicAlertOnShowHide": "A lógica condicional terá precedência sobre as configurações ocultas aqui",
    "validateEmailHeader": "Validar email",
    "validateEmailDescription": "O botão ficará visível assim que o usuário inserir seu e-mail. \nEste recurso foi projetado para ajudá-lo a gerar leads de qualidade.",
    "validatePhoneHeader": "Validar telefone",
    "validatePhoneDescription": "O botão ficará visível assim que o usuário entrar no telefone. \nEste recurso foi projetado para ajudá-lo a gerar leads de qualidade.",
    "validatePhoneWarning": "Para ativar a verificação SMS OTP, certifique-se de que seu número de telefone esteja configurado nas configurações de localização",
    "style": {
      "formLayoutType": "Tipo de formulário",
      "oneColumn": "Uma coluna",
      "singleLine": "Única linha",
      "twoColumn": "Duas colunas",
      "fieldWidth": "Largura do campo",
      "fieldSpacing": "Espaçamento de campo",
      "advanceSettings": "Configurações avançadas",
      "borderColor": "Cor da borda",
      "borderStyle": "Estilo de borda",
      "borderWidth": "Largura da borda",
      "color": "cor",
      "colorsAndBackground": "Cores",
      "fontColor": "Cor da fonte",
      "fontSize": "Tamanho da fonte",
      "fontWeight": "Espessura da fonte",
      "inputBackground": "Plano de fundo de entrada",
      "inputField": "Campo de entrada",
      "labelColor": "Cor da etiqueta",
      "layout": "Disposição",
      "placeholderColor": "Cor do espaço reservado",
      "shortLabelColor": "Cor da etiqueta curta"
    },
    "replyToTooltip": "Personalize o endereço de e-mail de resposta padrão usando {'{'}{'{'}contact.email{'}'}{'}'} para permitir respostas ao contato que envia o formulário.",
    "content": "Contente",
    "calculations": "Cálculos",
    "uploadOptions": "Opções de upload",
    "messageStyling": "Estilo de mensagem",
    "opportunitySettingsWarning": "Defina as configurações de oportunidade clicando no botão Editar configurações de oportunidade.",
    "swapWarningMessageSurvey": "A edição ou troca de opções afetará esse campo personalizado em todos os locais onde o campo for usado. \nTambém pode redefinir a lógica condicional.",
    "swapWarningMessageForm": "As opções de edição ou troca afetarão esse campo personalizado em todos os locais onde o campo for usado.",
    "addOption": "Adicionar opção",
    "calculationValue": "Valor de cálculo",
    "enableCalculations": "Habilitar cálculos",
    "optionLabel": "Etiqueta de opção"
  },
  "form": {
    "activateOn": "Ativar em",
    "activationOptions": "Opções de ativação",
    "addConditionalLogic": "Adicionar lógica condicional",
    "addFormElement": "Adicionar elemento de formulário",
    "allowMinimize": "Permitir minimizar",
    "alwaysActivated": "Sempre ativado",
    "alwaysShow": "Sempre aparece",
    "conditonalLogic": {
      "addNewCondition": "Adicionar nova condição",
      "alertConditionDelete": "Tem certeza de que deseja excluir esta condição?",
      "conditionExecutedMessage": "As condições serão executadas de cima para baixo.",
      "conditions": "Condições",
      "deleteCondition": "Excluir condição",
      "displayCustomMessage": "Exibir mensagem personalizada",
      "disqualifyLead": "Desqualificar lead",
      "freeRoamMessage": "Você está livre para vagar sem regras – pelo menos por enquanto",
      "noExistingCondition": "Ainda não existem condições existentes",
      "openBelowUrl": "Abra o URL abaixo",
      "redirectToUrl": "Redirecionar para URL",
      "showCustomMessage": "Mostrar mensagem personalizada",
      "showHideFields": "Mostrar/ocultar campos",
      "allOperationOptions": {
        "startsWith": "Começa com",
        "lessThan": "Menor que",
        "greaterThan": "Maior que",
        "endsWith": "Termina com",
        "isEmpty": "Está vazia",
        "before": "Antes",
        "contains": "Contém",
        "isEqualTo": "É igual a",
        "isFilled": "Está cheio",
        "after": "Depois",
        "isNotEqualTo": "Não é igual a"
      },
      "logic": {
        "and": "E",
        "if": "Se",
        "or": "Ou",
        "then": "Então"
      },
      "phoneAlertMessage": "Para pesquisar números de telefone com código de país, você precisa incluir explicitamente o símbolo '+' em sua consulta de pesquisa.",
      "deleteAlertMessage": "Tem certeza de que deseja excluir este campo? \nA condição associada a este campo também será removida.",
      "deleteField": "Excluir campo"
    },
    "minimizedTitlePlaceholder": "Título minimizado",
    "copyEmbedCode": "Cópia Código de incorporação",
    "deactivateAfterShowing": "Desativar depois de mostrar",
    "deactivationOptions": "Opções de desativação",
    "deactiveOnceLeadCollected": "Desative assim que o lead for coletado",
    "embedlayoutType": "Incorporar tipo de layout",
    "embedlayoutTypeSubMessage": "Selecione um layout para incorporar em um funil ou site externo",
    "formElement": "Elemento de formulário",
    "formName": "Nome do formulario",
    "formSettings": "Configurações de formulário",
    "formStyle": "Estilo de formulário",
    "fullScreenMode": "Modo tela cheia",
    "fullScreenModetooltip": "Ativá-lo fará com que o formulário se expanda por toda a largura do contêiner, estendendo-se até a borda da área de incorporação e aderindo à largura do formulário.",
    "inline": "Em linha",
    "inlineDescription": "Um formulário embutido aparece junto com o conteúdo da página como um elemento nativo. \nNão se sobrepõe e não pode ser fechado ou minimizado.",
    "inlineForm": "Formulário embutido",
    "inlineHoverDescription": "Um formulário embutido aparece junto com o conteúdo da página como um elemento nativo. \nNão se sobrepõe e não pode ser fechado ou minimizado.",
    "integrateForm": "Integrar formulário",
    "neverDeactivate": "Nunca desative",
    "onFormSubmission": "No envio do formulário",
    "politeSlideIn": "Deslize educado",
    "politeSlideInDescription": "Um slide educado aparece na parte inferior da tela e desaparece quando fechado",
    "politeSlideInHoverDescription": "Aparece na parte inferior da tela e desaparece quando fechada",
    "popup": "Aparecer",
    "popupDescription": "Um pop-up mostra o formulário como uma sobreposição dentro de uma caixa de luz. \nNão pode ser minimizado, mas fechado.",
    "popupHoverDescription": "Um pop-up mostra o formulário como uma sobreposição dentro de uma caixa de luz. \nNão pode ser minimizado, mas fechado.",
    "seconds": "segundos",
    "showAfter": "Mostrar depois",
    "showOnScrolling": "Mostrar na rolagem",
    "stickySidebar": "Barra lateral fixa",
    "stickySidebarDescription": "Uma barra lateral fixa aparecerá na lateral da tela e pode ser minimizada em uma guia que fica na lateral da janela",
    "stickySidebarHoverDescription": "Aparecem na lateral da tela e podem ser minimizados em uma guia que fica na lateral da janela",
    "thirdPartyScriptAlertMessage": "Scripts de terceiros dentro do bloco HTML podem fornecer funcionalidades poderosas, mas também trazem riscos à privacidade, segurança, desempenho e comportamento da página. \nPortanto, recomendamos que você revise o conteúdo deste script antes de salvá-lo no formulário.",
    "times": "vezes",
    "triggerType": "Tipo de gatilho",
    "typeOfBuilder": "Forma",
    "visit": "Visita",
    "dragYourElementFromLeft": "Arraste seus elementos aqui da esquerda",
    "enableTimezoneForm": "Ativar esta opção irá capturar as informações de fuso horário do indivíduo que envia o formulário",
    "formNotification": "Notificação de formulário",
    "copyFormLink": "Copiar link do formulário",
    "openFormLink": "Abrir link do formulário",
    "unsavedAlertMessage": "O formulário tem trabalho não salvo. \nTem certeza de que deseja sair e descartar todos os trabalhos não salvos?",
    "style": {
      "formPadding": "Preenchimento de formulário"
    }
  },
  "survey": {
    "addSlide": "Adicionar slide",
    "addSurveyElement": "Adicionar elemento de pesquisa",
    "backButton": "Botão \"voltar",
    "backButtonTooltipMessage": "O botão Voltar ajudará o usuário a mover o slide anterior",
    "delete": "Excluir",
    "disableAnimation": "Desativar animação",
    "disableAnimationTooltipMessage": "Ativar isso desativará a animação de transição de slides",
    "disableAutoNavigation": "Desativar navegação automática",
    "disableAutoNavigationTooltipMessage": "Se ativado, a pesquisa não irá automaticamente para o próximo slide",
    "disqualifyAfterSubmit": "Desqualificar após enviar",
    "disqualifyImmediately": "Desqualificar imediatamente",
    "editFooter": "Editar rodapé",
    "footer": {
      "backgroundFill": "Preenchimento de fundo",
      "buttonType": "Tipo de botão",
      "buttons": "Botões",
      "enableNewFooter": "Habilitar novo rodapé",
      "enableProgressBar": "Ativar barra de progresso",
      "fillComplete": "Preenchimento completo",
      "fillInactive": "Preencher inativo",
      "fillNext": "Preencher (próximo)",
      "fillPrevious": "Preencher (anterior)",
      "fillSubmit": "Preencher (enviar)",
      "footer": "Rodapé",
      "footerHeight": "Altura do rodapé",
      "nextText": "Próximo (texto)",
      "position": "Posição",
      "previousText": "Anterior (texto)",
      "stickToSurvey": "Atenha-se à pesquisa",
      "submitText": "Enviar (texto)",
      "textColor": "Cor do texto",
      "footerType": "Tipo de rodapé",
      "stickToCard": "Atenha-se ao cartão",
      "stickToPage": "Fique na página"
    },
    "footerHTML": "HTML do rodapé",
    "footerHTMLMessage": "Você pode escrever CSS personalizado (usando tag de estilo) e HTML dentro da mesma caixa",
    "integrateCopyLinkMessage": "Copie o link abaixo e compartilhe-o facilmente onde desejar.",
    "integrateHeader": "Integrar pesquisa",
    "integrateSubHeader": "Use as seguintes opções para integrar sua pesquisa personalizada ao seu site",
    "jumpTo": "Pule para",
    "onSurveySubmission": "No envio da pesquisa",
    "oneQuestionAtTime": "Uma pergunta de cada vez",
    "progressBar": "Barra de progresso",
    "progressBarTooltipMessage": "Desativar isso removerá a barra de progresso da pesquisa",
    "scrollToTop": "Role para cima",
    "scrollToTopTooltipMessage": "Se ativado, a pesquisa irá rolar para o topo no próximo slide",
    "settings": "Configurações",
    "slideConflictAlertMessage": "Este slide contém várias perguntas que possuem uma lógica definida. \nIsso pode causar algum comportamento não intencional",
    "slideDeleteAlertMessage": "Tem certeza de que deseja excluir este slide?",
    "slideDeleteBetweenAlertMessage": "Isso reorganizará os outros slides. Tem certeza de que deseja excluir este slide?",
    "slideName": "Nome do slide",
    "slidePosition": "Posição do slide",
    "surveyElement": "Elemento de pesquisa",
    "surveyName": "Nome da pesquisa",
    "surveySettings": "Configurações de pesquisa",
    "survyeStyle": "Estilo de pesquisa",
    "thirdPartyScriptAlertMessage": "Scripts de terceiros dentro do bloco HTML podem fornecer funcionalidades poderosas, mas também trazem riscos à privacidade, segurança, desempenho e comportamento da página. \nPortanto, recomendamos que você revise o conteúdo deste script antes de salvá-lo na pesquisa.",
    "typeOfBuilder": "Enquete",
    "enableTimezoneSurvey": "Ativar esta opção irá capturar as informações de fuso horário do indivíduo que envia a pesquisa",
    "surveyNotfication": "Notificação de pesquisa",
    "unsavedAlertMessage": "A pesquisa tem trabalhos não salvos. \nTem certeza de que deseja sair e descartar todos os trabalhos não salvos?",
    "style": {
      "surveyPadding": "Preenchimento de pesquisa"
    }
  }
}
