interface Config {
  SURVEY_SERVER_URL: any;
  mode: string;
  REST_API_URLS: string;
  FORM_SERVER_URL: string;
  LOCATION_SERVER_URL: string;
  FORM_EMBED_URL: string;
  PAYMENT_SERVER_URL: string;
  PRODUCT_SERVER_URL: string;
  OPPORTUNITY_SERVER_URL: string;
  GHL_DOMAIN: string;
  GHL_DOMAIN_UI: string;
}

const config: { [key: string]: Config } = {
  development: {
    mode: "dev",
    REST_API_URLS: "https://staging.services.leadconnectorhq.com",
    FORM_SERVER_URL: "https://staging.services.leadconnectorhq.com",
    SURVEY_SERVER_URL: "https://staging.services.leadconnectorhq.com",
    LOCATION_SERVER_URL: "https://staging.services.leadconnectorhq.com",
    FORM_EMBED_URL: "https://staging.link.gohighlevel.com/js",
    PAYMENT_SERVER_URL: "https://staging.services.leadconnectorhq.com",
    PRODUCT_SERVER_URL: "https://staging.services.leadconnectorhq.com",
    OPPORTUNITY_SERVER_URL: "https://staging.services.leadconnectorhq.com",
    GHL_DOMAIN: "localhost:8080",
    GHL_DOMAIN_UI: "http://localhost:3344",
  },
  staging: {
    mode: "staging",
    REST_API_URLS: "https://staging.services.leadconnectorhq.com",
    FORM_SERVER_URL: "https://staging.services.leadconnectorhq.com",
    SURVEY_SERVER_URL: "https://staging.services.leadconnectorhq.com",
    LOCATION_SERVER_URL: "https://staging.services.leadconnectorhq.com",
    FORM_EMBED_URL: "https://staging.link.gohighlevel.com/js",
    PAYMENT_SERVER_URL: "https://staging.services.leadconnectorhq.com",
    PRODUCT_SERVER_URL: "https://staging.services.leadconnectorhq.com",
    OPPORTUNITY_SERVER_URL: "https://staging.services.leadconnectorhq.com",
    GHL_DOMAIN: "staging.gohighlevel.com",
    GHL_DOMAIN_UI:
      "https://version-history-dot-funnel-preview-dot-highlevel-staging.uc.r.appspot.com", // "https://staging.gohighlevel.com",
  },
  beta: {
    mode: "beta",
    REST_API_URLS: "https://services.leadconnectorhq.com",
    FORM_SERVER_URL: "https://services.leadconnectorhq.com",
    SURVEY_SERVER_URL: "https://services.leadconnectorhq.com",
    LOCATION_SERVER_URL: "https://services.leadconnectorhq.com",
    FORM_EMBED_URL: "https://link.msgsndr.com/js",
    PAYMENT_SERVER_URL: "https://services.leadconnectorhq.com",
    PRODUCT_SERVER_URL: "https://services.leadconnectorhq.com",
    OPPORTUNITY_SERVER_URL: "https://services.leadconnectorhq.com",
    GHL_DOMAIN: "app.gohighlevel.com",
    GHL_DOMAIN_UI: "https://link.apisystem.tech",
  },
  production: {
    mode: "production",
    REST_API_URLS: "https://services.leadconnectorhq.com",
    FORM_SERVER_URL: "https://services.leadconnectorhq.com",
    SURVEY_SERVER_URL: "https://services.leadconnectorhq.com",
    LOCATION_SERVER_URL: "https://services.leadconnectorhq.com",
    FORM_EMBED_URL: "https://link.msgsndr.com/js",
    PAYMENT_SERVER_URL: "https://services.leadconnectorhq.com",
    PRODUCT_SERVER_URL: "https://services.leadconnectorhq.com",
    OPPORTUNITY_SERVER_URL: "https://services.leadconnectorhq.com",
    GHL_DOMAIN: "app.gohighlevel.com",
    GHL_DOMAIN_UI: "https://link.apisystem.tech",
  },
};

const override = false;

const overrideConfig = {
  override: true,
};
//@ts-ignore
const envConfig = config[process.env.NODE_ENV];

export default override ? { ...envConfig, ...overrideConfig } : envConfig;
