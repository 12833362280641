{
  "common": {
    "addCustomFields": "Tilføj brugerdefinerede felter",
    "addElement": "Tilføj element",
    "addUrlLike": "Tilføj gyldige webadresser som",
    "address": "Adresse",
    "agencyBranding": "Agency Branding",
    "alert": "Alert",
    "alignment": "Justering",
    "altText": "Alt tekst",
    "back": "Tilbage",
    "background": "Baggrund",
    "backgroundImage": "Baggrundsbillede",
    "backgroundImageTooltipMessage": "Baggrundsbilledet er beregnet til at dække hele siden, hvilket giver en visuelt tiltalende baggrund for formularen. \nFor at se baggrundsbilledet skal du klikke på indstillingen Eksempel.",
    "blur": "Slør",
    "border": "Grænse",
    "borderType": "Border Type",
    "bottom": "Bund",
    "buttonStyle": "Knap stil",
    "cancel": "Afbestille",
    "cancelKeep": "Annuller, behold",
    "color": "Farve",
    "conditionalLogicAlertOnSubmit": "Betinget logik vil have forrang over meddelelsen/omdirigeringen her",
    "contactInfo": "Kontaktoplysninger",
    "cornerRadius": "Hjørne radius",
    "customCSS": "Brugerdefineret CSS",
    "customCSSTooltip": "Tilpasset CSS har forrang frem for formstyling",
    "customFieldSearch": "Søg på navn",
    "customFields": "Brugerdefinerede felter",
    "customized": "Tilpasset",
    "delete": "Slet",
    "doNotAddRelativePath": "Tilføj ikke relative stier som",
    "doNotShowMessage": "Vis ikke igen",
    "done": "Færdig",
    "editHTML": "Rediger HTML",
    "embed": "Indlejre",
    "enableCountryPicker": "Aktiver landevælger",
    "enterSourceValue": "Indtast kildeværdi",
    "facebookPixelEvents": "Facebook Pixel-begivenheder",
    "facebookPixelID": "Facebook Pixel ID",
    "facebookPixelIDMessage": "Ignorer dette felt, hvis du planlægger at bruge denne formular/undersøgelse i en tragt",
    "fieldDescription": "Feltbeskrivelse",
    "fieldStyle": "Feltstil",
    "fieldTitle": "Felt titel",
    "fontColor": "Skrift farve",
    "fontFamily": "Fontfamilie",
    "fontFamilyTooltip": "Builder understøtter alle typer skrifttyper, hvis du ikke kan se det på listen. \nSøg venligst i dropdown-menuen",
    "fullWidth": "Fuld bredde",
    "headerImage": "Overskriftsbillede",
    "headerImageTooltipMessage": "Overskriftsbilledet er designet til at dække hele formularens bredde og forbliver placeret i toppen af ​​formularen. \nFor at se sidehovedbilledet skal du klikke på indstillingen Eksempel.",
    "hidden": "Skjult",
    "hiddenValue": "Skjult værdi",
    "horizontal": "Vandret",
    "htmlEditorBodyMessage": "Tilføj din kode her",
    "htmlEditorHeader": "TILPASSET JAVASCRIPT / HTML",
    "htmlMessage": "Den brugerdefinerede HTML kommer her",
    "iframeEmbed": "Ifrmae Embed",
    "imageHeight": "Billedhøjde",
    "imageUploadMessage": "Klik eller træk en billedfil til dette område for at uploade",
    "imageWidth": "Billedbredde",
    "inputStyle": "Indtastningsstil",
    "integrate": "Integrere",
    "label": "Etiket",
    "layout": "Layout",
    "left": "Venstre",
    "link": "Link",
    "logic": "Logik",
    "miscellaneous": "Diverse",
    "onSubmit": "Ved indsend",
    "onpageView": "På sidevisning",
    "options": "Muligheder",
    "or": "eller",
    "padding": "Polstring",
    "personalInfo": "Personlig information",
    "placeholder": "Pladsholder",
    "placeholderFacebookPixelIdInput": "Indtast Facebook Pixel ID",
    "pleaseInput": "Indtast venligst",
    "preview": "Forhåndsvisning",
    "proceed": "Fortsæt",
    "queryKey": "Forespørgselsnøgle",
    "querykeyTooltipMessage": "Forespørgselsnøglen, der kan bruges som URL-parameter til at udfylde dette felt",
    "quickAdd": "Hurtig tilføjelse",
    "required": "Påkrævet",
    "right": "Højre",
    "save": "Gemme",
    "saving": "Gemmer",
    "shadow": "Skygge",
    "shortLabel": "Kort etiket",
    "showLabel": "Vis etiket",
    "size": "Størrelse",
    "spread": "Spredning",
    "stickyContact": "Sticky kontakt",
    "stickyContactMessage": "Sticky contact vil automatisk udfylde tidligere indtastede kontaktoplysninger, så du ikke behøver at indtaste dem to gange.",
    "styles": "Stilarter",
    "subText": "Undertekst",
    "submit": "Indsend",
    "text": "Tekst",
    "textStyle": "Tekststil",
    "themes": {
      "alertThemeMessage": "Skift af tema vil resultere i tab af alle de aktuelle stilændringer.",
      "removeTheme": "Fjern tema",
      "themes": "Temaer",
      "useTheme": "Brug tema"
    },
    "top": "Top",
    "useGDPRCompliantFont": "Brug GDPR-kompatibel skrifttype",
    "vertical": "Lodret",
    "weight": "Vægt",
    "width": "Bredde",
    "yesSave": "Ja, Gem",
    "box": "Boks",
    "line": "Linje",
    "message": "Besked",
    "openUrl": "Åbn URL",
    "borderTypeList": {
      "dotted": "Stiplet",
      "none": "Ingen",
      "solid": "Solid",
      "dashed": "Stiplet"
    },
    "button": "Knap",
    "enableTimezone": "Aktiver tidszone",
    "emailNotification": "E-mail notifikation",
    "emailNotificationSubheader": "Modtag en e-mail, når nogen udfylder din formular",
    "subject": "Emne",
    "emailTo": "E-mail (til)",
    "senderName": "Afsender navn",
    "senderNameWarning": "Dit (underkontonavn) vil blive tilføjet, hvis det efterlades tomt",
    "emailTooltip": "Den første e-mailadresse er angivet til feltet 'Til'. \nEventuelle efterfølgende e-mailadresser vil blive inkluderet i 'Cc'-feltet.",
    "emailError": "Der kræves mindst én e-mailadresse",
    "clear": "Klar",
    "showNotificationsSettings": "Vis meddelelsesindstillinger",
    "emailServicePaid": "Denne funktion er en betalt tjeneste og vil medføre gebyrer baseret på dit forbrug.",
    "integration": "Integration",
    "payment": {
      "connect": "Forbinde",
      "connected": "Forbundet",
      "footerInfo": "Sidefod info",
      "includeOtherAmount": "Inkluder andet beløb",
      "live": "Direkte",
      "paymentFields": "Betalingsfelter",
      "selectCurrency": "Vælg Valuta",
      "status": "Status",
      "suggestAnAmount": "Foreslå et beløb",
      "test": "Prøve",
      "liveModeOn": "Live-tilstand",
      "customAmount": "Brugerdefineret beløb",
      "paymentType": "Betalings type",
      "product": {
        "addProduct": "Tilføj produkt",
        "clickHere": "Klik her",
        "description": "Beskrivelse",
        "editThisProduct": "For at redigere dette produkt",
        "image": "Billede",
        "layoutSettings": "Layoutindstillinger",
        "maxProductError": "Maksimalt 20 produkter er tilladt i en formular",
        "productSelectPlaceholder": "Vælg venligst et produkt",
        "productSettings": "Produktindstillinger",
        "selectProduct": "Vælg produkt",
        "show": "At vise",
        "singleColumn": "Enkelt kolonne",
        "threeColumn": "Tre kolonne",
        "twoColumn": "To kolonner",
        "orderConfirmationWarning": "En ordrebekræftelsesside vil blive vist, når betalingen er gennemført",
        "orderConfirmation": "Ordrebekræftelse",
        "createProduct": "At skabe et produkt",
        "quantity": "Antal"
      },
      "sellProducts": "Sælg produkter",
      "generalSettings": "Generelle felter",
      "tagActiveBGColor": "Aktiv tag farve",
      "collectPayment": "Opkræve betaling",
      "payments": "Betalinger",
      "coupons": {
        "couponAlignment": "Kuponjustering",
        "coupons": "Kuponer",
        "enableCoupon": "Aktiver kupon",
        "fullWidth": "Fuld bredde",
        "left": "Venstre",
        "right": "Højre",
        "tooltipMessage": "Indstil positionen for kuponinput fra nedenstående typer"
      }
    },
    "autoResponder": "Auto Responder",
    "autoResponderSubheader": "Send en automatisk e-mail til den person, der indsender formularen (e-mail indeholder en kopi af de oplysninger, de har indtastet i formularen)",
    "replyToEmail": "Svar til e-mail",
    "open": "Åben",
    "won": "Vandt",
    "lost": "Faret vild",
    "abandon": "Opgive",
    "selectAPipeline": "Vælg en pipeline",
    "stage": "Scene",
    "status": "Status",
    "opportunityValue": "Mulighedsværdi",
    "createNewPipeline": "Opret ny pipeline",
    "editOpportunitySettings": "Rediger mulighedsindstillinger",
    "contact": "Kontakt",
    "opportunity": "Lejlighed",
    "add": "Tilføje",
    "more": "Mere",
    "opportunityHeading": "Hvor vil du have din mulighed for at blive opdateret?",
    "date": {
      "dateFormat": "Datoformat",
      "dateFormatTooltipMessage": "Vælg et datoformat. \nD står for dag, M for måneder og Y for år",
      "dateSeparator": "Datoseparator",
      "dateSeparatorTooltipMessage": "Vælg en separator, som adskiller dag, måned og år i formatet valgt på datoformat",
      "dateSettings": "Datoindstillinger",
      "disablePicker": "Deaktiver Vælger"
    },
    "restoreVersion": "Gendan version",
    "versionHistory": "Versionshistorik",
    "errorMessage": "Fejl. Prøv igen",
    "version": "Version:",
    "currentVersion": "Nuværende version",
    "conditionalLogicAlertOnShowHide": "Betinget logik vil have forrang over de skjulte indstillinger her",
    "validateEmailHeader": "Valider e-mail",
    "validateEmailDescription": "Knappen bliver synlig, når brugeren indtaster sin e-mail. \nDenne funktion er designet til at hjælpe dig med at generere kundeemner af høj kvalitet.",
    "validatePhoneHeader": "Valider telefon",
    "validatePhoneDescription": "Knappen bliver synlig, når brugeren indtaster sin telefon. \nDenne funktion er designet til at hjælpe dig med at generere kundeemner af høj kvalitet.",
    "validatePhoneWarning": "For at aktivere SMS OTP-bekræftelse skal du sørge for, at dit telefonnummer er konfigureret i dine placeringsindstillinger",
    "style": {
      "fieldWidth": "Feltbredde",
      "formLayoutType": "Formulartype",
      "oneColumn": "Én kolonne",
      "singleLine": "Enkelt linje",
      "twoColumn": "To kolonner",
      "fieldSpacing": "Feltafstand",
      "advanceSettings": "Avancerede indstillinger",
      "borderColor": "Kantfarve",
      "borderStyle": "Grænsestil",
      "borderWidth": "Border Bredde",
      "color": "farve",
      "colorsAndBackground": "Farver",
      "fontColor": "Skrift farve",
      "fontSize": "Skriftstørrelse",
      "fontWeight": "Skrifttypevægt",
      "inputBackground": "Indtast baggrund",
      "inputField": "Indtastningsfelt",
      "labelColor": "Etiket farve",
      "layout": "Layout",
      "placeholderColor": "Pladsholder farve",
      "shortLabelColor": "Kort Label farve",
      "labelAlignment": {
        "labelAlignment": "Etiketjustering",
        "labelWidth": "Etiketbredde"
      }
    },
    "replyToTooltip": "Tilpas standardsvar-til-e-mailadressen ved at bruge {'{'}{'{'}contact.email{'}'}{'}'} for at aktivere svar til den kontakt, der indsender formularen.",
    "content": "Indhold",
    "calculations": "Beregninger",
    "uploadOptions": "Upload muligheder",
    "messageStyling": "Beskedstil",
    "opportunitySettingsWarning": "Indstil mulighedsindstillinger ved at klikke på knappen Rediger mulighedsindstillinger.",
    "swapWarningMessageSurvey": "Redigerings- eller udskiftningsmuligheder vil påvirke dette brugerdefinerede felt på tværs af alle steder, hvor feltet bruges. \nDet kan også nulstille betinget logik.",
    "swapWarningMessageForm": "Redigerings- eller udskiftningsindstillinger vil påvirke dette brugerdefinerede felt på tværs af alle steder, hvor feltet bruges.",
    "addOption": "Tilføj mulighed",
    "calculationValue": "Beregningsværdi",
    "enableCalculations": "Aktiver beregninger",
    "optionLabel": "Option Label"
  },
  "form": {
    "activateOn": "Aktiver til",
    "activationOptions": "Aktiveringsmuligheder",
    "addConditionalLogic": "Tilføj betinget logik",
    "addFormElement": "Tilføj formularelement",
    "allowMinimize": "Tillad at minimere",
    "alwaysActivated": "Altid aktiveret",
    "alwaysShow": "Vis altid",
    "conditonalLogic": {
      "addNewCondition": "Tilføj ny tilstand",
      "alertConditionDelete": "Er du sikker på, at du vil slette denne betingelse?",
      "conditionExecutedMessage": "Betingelser vil blive udført fra top-down ordre.",
      "conditions": "Betingelser",
      "deleteCondition": "Slet tilstand",
      "displayCustomMessage": "Vis tilpasset besked",
      "disqualifyLead": "Diskvalificere leder",
      "freeRoamMessage": "Du er fri til at roame uden reglerne - i hvert fald indtil videre",
      "noExistingCondition": "Ingen eksisterende betingelser endnu",
      "openBelowUrl": "Åbn nedenstående URL",
      "redirectToUrl": "Omdiriger til URL",
      "showCustomMessage": "Vis tilpasset besked",
      "showHideFields": "Vis/skjul felter",
      "allOperationOptions": {
        "after": "Efter",
        "before": "Før",
        "contains": "Indeholder",
        "endsWith": "Ender med",
        "greaterThan": "Bedre end",
        "isEmpty": "Er tom",
        "isEqualTo": "Er lig med",
        "isFilled": "Er fyldt",
        "isNotEqualTo": "Er ikke lig med",
        "lessThan": "Mindre end",
        "startsWith": "Starter med"
      },
      "logic": {
        "and": "Og",
        "if": "Hvis",
        "or": "Eller",
        "then": "Derefter"
      },
      "phoneAlertMessage": "For at søge efter telefonnumre med en landekode skal du udtrykkeligt inkludere symbolet '+' i din søgeforespørgsel.",
      "deleteAlertMessage": "Er du sikker på, at du vil slette dette felt? \nBetingelsen knyttet til dette felt vil også blive fjernet.",
      "deleteField": "Slet felt"
    },
    "minimizedTitlePlaceholder": "Minimeret titel",
    "copyEmbedCode": "Kopiér indlejringskoden",
    "deactivateAfterShowing": "Deaktiver efter visning",
    "deactivationOptions": "Deaktiveringsmuligheder",
    "deactiveOnceLeadCollected": "Deaktiver, når bly er samlet",
    "embedlayoutType": "Integrer layouttype",
    "embedlayoutTypeSubMessage": "Vælg et layout, der skal integreres i en tragt eller et eksternt websted",
    "formElement": "Formelement",
    "formName": "Formularnavn",
    "formSettings": "Formularindstillinger",
    "formStyle": "Formstil",
    "fullScreenMode": "Fuldskærmstilstand",
    "fullScreenModetooltip": "Hvis du tænder for det, vil formularen udvide sig over hele bredden af ​​dens beholder og strække sig til kanten af ​​indlejringsområdet, mens den klæber til formularens bredde.",
    "inline": "Inline",
    "inlineDescription": "En indlejret formular vises sammen med indholdet på siden som et indbygget element. \nDen ligger ikke over og kan ikke lukkes eller minimeres.",
    "inlineForm": "Inline formular",
    "inlineHoverDescription": "En indlejret formular vises sammen med indholdet på siden som et indbygget element. \nDen ligger ikke over og kan ikke lukkes eller minimeres.",
    "integrateForm": "Integrer formular",
    "neverDeactivate": "Deaktiver aldrig",
    "onFormSubmission": "Ved indsendelse af formularer",
    "politeSlideIn": "Høflig slid-in",
    "politeSlideInDescription": "En høflig slide-in vises nederst på skærmen og forsvinder, når den er lukket",
    "politeSlideInHoverDescription": "Vises på bunden af ​​skærmen og forsvinder, når den er lukket",
    "popup": "Pop op",
    "popupDescription": "En pop-up viser form som en overlejring inde i en lysboks. \nDet kan ikke minimeres men lukkes.",
    "popupHoverDescription": "En pop-up viser form som en overlejring inde i en lysboks. \nDet kan ikke minimeres men lukkes.",
    "seconds": "sekunder",
    "showAfter": "Vis efter",
    "showOnScrolling": "Vis ved rulning",
    "stickySidebar": "Sticky sidebar",
    "stickySidebarDescription": "En Sticky sidebar vises på siden af ​​skærmen og kan minimeres til en fane, der klæber til siden af ​​vinduet",
    "stickySidebarHoverDescription": "Vises på siden af ​​skærmen og kan minimeres til en fane, der klæber til siden af ​​vinduet",
    "thirdPartyScriptAlertMessage": "Tredjepartsscripts inde i HTML-blokken kan give kraftfuld funktionalitet, men de medfører også risici for privatliv, sikkerhed, ydeevne og sideadfærd. \nDerfor anbefaler vi, at du gennemgår indholdet af dette script, før du gemmer det i formularen.",
    "times": "gange",
    "triggerType": "Trigger type",
    "typeOfBuilder": "Form",
    "visit": "besøg",
    "dragYourElementFromLeft": "Træk dine elementer hertil fra venstre",
    "enableTimezoneForm": "Aktivering af denne mulighed vil fange tidszoneoplysningerne for den person, der indsender formularen",
    "formNotification": "Formular meddelelse",
    "copyFormLink": "Kopiér formularlinket",
    "openFormLink": "Åbn formularlinket",
    "unsavedAlertMessage": "Formularen har ikke-gemt arbejde. \nEr du sikker på, at du vil forlade og kassere alt ikke-gemt arbejde?",
    "style": {
      "formPadding": "Formpolstring"
    }
  },
  "survey": {
    "addSlide": "Tilføj slide",
    "addSurveyElement": "Tilføj undersøgelseselement",
    "backButton": "Tilbage knap",
    "backButtonTooltipMessage": "Tilbage-knappen hjælper brugeren med at flytte det forrige dias",
    "delete": "Slet",
    "disableAnimation": "Deaktiver animation",
    "disableAnimationTooltipMessage": "Hvis du slår dette til, deaktiveres diasovergangsanimationen",
    "disableAutoNavigation": "Deaktiver automatisk navigation",
    "disableAutoNavigationTooltipMessage": "Hvis den er slået til, vil undersøgelsen ikke automatisk gå til næste slide",
    "disqualifyAfterSubmit": "Diskvalificere efter indsendelse",
    "disqualifyImmediately": "Diskvalificere øjeblikkeligt",
    "editFooter": "Rediger sidefod",
    "footer": {
      "backgroundFill": "Baggrundsfyld",
      "buttonType": "Knaptype",
      "buttons": "Knapper",
      "enableNewFooter": "Aktiver ny sidefod",
      "enableProgressBar": "Aktiver statuslinje",
      "fillComplete": "Fyld fuldført",
      "fillInactive": "Udfyld inaktiv",
      "fillNext": "Fyld (næste)",
      "fillPrevious": "Udfyld (forrige)",
      "fillSubmit": "Udfyld (Send)",
      "footer": "Sidefod",
      "footerHeight": "Sidefod Højde",
      "nextText": "Næste (tekst)",
      "position": "Position",
      "previousText": "Forrige (tekst)",
      "stickToSurvey": "Hold dig til Survey",
      "submitText": "Send (tekst)",
      "textColor": "Tekst farve",
      "footerType": "Sidefodstype",
      "stickToCard": "Hold dig til kortet",
      "stickToPage": "Hold dig til siden"
    },
    "footerHTML": "Sidefod HTML",
    "footerHTMLMessage": "Du kan skrive tilpasset CSS (ved hjælp af style tag) og HTML inde i den samme boks",
    "integrateCopyLinkMessage": "Kopier linket nedenfor og del det nemt hvor som helst du ønsker det.",
    "integrateHeader": "Integrer undersøgelse",
    "integrateSubHeader": "Brug følgende muligheder til at integrere din tilpassede undersøgelse på dit websted",
    "jumpTo": "Hop til",
    "onSurveySubmission": "Ved indsendelse af undersøgelse",
    "oneQuestionAtTime": "Et spørgsmål ad gangen",
    "progressBar": "Fremskridtslinje",
    "progressBarTooltipMessage": "Hvis du slår dette fra, fjernes statuslinjen fra undersøgelsen",
    "scrollToTop": "Rul til toppen",
    "scrollToTopTooltipMessage": "Hvis den er slået til, vil undersøgelsen rulle til toppen på næste dias",
    "settings": "Indstillinger",
    "slideConflictAlertMessage": "Dette dias indeholder flere spørgsmål, der har en logik sat til dem. \nDette kan forårsage utilsigtet adfærd",
    "slideDeleteAlertMessage": "Er du sikker på, at du vil slette dette dias?",
    "slideDeleteBetweenAlertMessage": "Dette vil omarrangere de andre dias. Er du sikker på, at du vil slette dette dias?",
    "slideName": "Slide navn",
    "slidePosition": "Slideposition",
    "surveyElement": "Undersøgelseselement",
    "surveyName": "Navn på undersøgelse",
    "surveySettings": "Undersøgelsesindstillinger",
    "survyeStyle": "Undersøgelsesstil",
    "thirdPartyScriptAlertMessage": "Tredjepartsscripts inde i HTML-blokken kan give kraftfuld funktionalitet, men de medfører også risici for privatliv, sikkerhed, ydeevne og sideadfærd. \nDerfor anbefaler vi, at du gennemgår indholdet af dette script, før du gemmer det i undersøgelsen.",
    "typeOfBuilder": "Undersøgelse",
    "enableTimezoneSurvey": "Aktivering af denne mulighed vil fange tidszoneoplysningerne for den person, der indsender undersøgelsen",
    "surveyNotfication": "Undersøgelsesmeddelelse",
    "unsavedAlertMessage": "Undersøgelsen har ikke-gemt arbejde. \nEr du sikker på, at du vil forlade og kassere alt ikke-gemt arbejde?",
    "style": {
      "surveyPadding": "Undersøgelsespolstring"
    }
  }
}
