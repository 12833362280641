<template>
  <UIModal :show="show">
    <template #header>
      <UIModalHeader
        id="modal-header"
        type="error"
        title="Delete Option"
        description="Deleting an option will impact this custom field across all places where the field is used. Are you sure you wish to delete it?"
        @close="cancel"
      >
      </UIModalHeader>
    </template>
    <UIModalContent>
      <UIForm id="modal-form" ref="formRef" :model="formValue" :rules="rules">
        <label class="mb-2"
          ><span class="text-black">Type <strong>DELETE</strong> to confirm</span
          ><span class="pl-1 text-[#D92D20]">&nbsp;*</span></label
        >
        <UIInput
          id="deleteConfirm"
          v-model="formValue.deleteConfirm"
          class="mb-3"
          placeholder="DELETE"
        />
      </UIForm>
    </UIModalContent>
    <template #footer>
      <UIModalFooter
        id="modal-footer"
        positive-text="Delete"
        negative-text="Cancel"
        type="error"
        @positive-click="confirm"
        @negative-click="cancel"
      >
      </UIModalFooter>
    </template>
  </UIModal>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import {
  UIModal,
  UIModalHeader,
  UIModalFooter,
  UIModalContent,
  UIForm,
  UIFormItem,
  UIInput,
} from "@gohighlevel/ghl-ui";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

watch(
  () => props.show,
  () => {
    if (props.show) {
      formValue.value.deleteConfirm = "";
    }
  }
);

const emit = defineEmits(["confirm", "cancel"]);

const formRef = ref(null);

const rules = {
  deleteConfirm: {
    required: true,
    message: "Please enter DELETE to confirm.",
    trigger: "blur",
    validator(_, value) {
      if (value.trim().toLowerCase() !== "delete") {
        return new Error("Please enter DELETE");
      }
    },
  },
};

const formValue = ref({
  deleteConfirm: "",
});

const confirm = async () => {
  await formRef.value.getForm().validate();
  emit("confirm");
};

const cancel = () => {
  emit("cancel");
};
</script>
