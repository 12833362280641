<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { reactive, watch } from "vue";
import BorderInput from "./BorderInput.vue";
import BorderType from "./BorderType.vue";
import Card from "./Card.vue";
import ColorInputField from "./ColorInputField.vue";
import CountInput from "./CountInput.vue";
import FontFamilyInput from "./FontFamilyInput.vue";
import RichTextEditor from "./RichTextEditor.vue";

import { useI18n } from "vue-i18n";
import { defaultBorder, defaultPadding, defaultShadow } from "./../../util/default";
import { UITextMdNormal } from "@gohighlevel/ghl-ui";

const { t } = useI18n();
const store = useAppStore();

const props = defineProps({
  property: {
    type: Object,
    default() {
      return {
        label: "Text",
        tag: "header",
        type: "h1",
        placeholder: "header",
        color: "000000",
        fontFamily: "Roboto",
        fontSize: 40,
        weight: 400,
        bgColor: "FFFFFF",
        align: "left",
        shadow: defaultShadow,
        padding: defaultPadding,
        border: defaultBorder,
      };
    },
  },
});
const output = reactive({ ...props.property });

watch(
  () => props.property,
  (newVal) => {
    const keys = Object.keys(newVal);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      output[key] = newVal[key];
    }
  },
  {
    deep: true,
  }
);

const emits = defineEmits(["update:text"]);

const handleTextFieldStyling = (value, type) => {
  switch (type) {
    case "text":
      output.label = value;
      break;
    case "fontFamily":
      output.fontFamily = value;
      store.fontCollection.push(value);
      break;
    case "fontColor":
      output.color = value;
      break;
    case "fontSize":
      output.fontSize = value;
      break;
    case "fontWeight":
      output.weight = value;
      break;
    case "background":
      output.bgColor = value;
      break;
    case "border":
      output.border.border = value;
      break;
    case "borderColor":
      output.border.color = value;
      break;
    case "borderType":
      output.border.type = value;
      break;
    case "cornerRadius":
      output.border.radius = value;
      break;
    case "paddingTop":
      output.padding.top = value;
      break;
    case "paddingBottom":
      output.padding.bottom = value;
      break;
    case "paddingLeft":
      output.padding.left = value;
      break;
    case "paddingRight":
      output.padding.right = value;
      break;
    case "shadowColor":
      output.shadow.color = value;
      break;
    case "shadowHorizontal":
      output.shadow.horizontal = value;
      break;
    case "shadowVertical":
      output.shadow.vertical = value;
      break;
    case "shadowBlur":
      output.shadow.blur = value;
      break;
    case "shadowSpread":
      output.shadow.spread = value;
      break;

    default:
      break;
  }
  store.anyUnsavedChanges = true;
  emits("update:text", output);
};
</script>

<template>
  <h6 class="field-name-sidebar">{{ t("common.text") }}</h6>
  <RichTextEditor
    :model-value="output.label"
    :is-submit-message="true"
    @update:model-value="(value) => handleTextFieldStyling(value, 'text')"
  ></RichTextEditor>
  <Card :header="t('common.textStyle')">
    <FontFamilyInput
      :label="t('common.fontFamily')"
      :value="output.fontFamily"
      @update:value="(value) => handleTextFieldStyling(value, 'fontFamily')"
    ></FontFamilyInput>
    <ColorInputField
      :label="t('common.fontColor')"
      :color="output.color"
      @update:color="(value) => handleTextFieldStyling(value, 'fontColor')"
    />
    <CountInput
      :label="t('common.size')"
      :value="output.fontSize"
      @update:value="(value) => handleTextFieldStyling(value, 'fontSize')"
    />
    <CountInput
      :label="t('common.weight')"
      :value="output.weight"
      :max="1800"
      @update:value="(value) => handleTextFieldStyling(value, 'fontWeight')"
    />
    <ColorInputField
      :label="t('common.background')"
      :color="output?.bgColor"
      @update:color="(value) => handleTextFieldStyling(value, 'background')"
    />
    <BorderInput
      :border="output?.border?.border"
      :border-color="output?.border?.color"
      @update:border="(value) => handleTextFieldStyling(value, 'border')"
      @update:color="(value) => handleTextFieldStyling(value, 'borderColor')"
    />
    <BorderType
      :border-type="output?.border?.type"
      @update:border-type="(value) => handleTextFieldStyling(value, 'borderType')"
    />
    <CountInput
      :label="t('common.cornerRadius')"
      :value="output?.border?.radius"
      @update:value="(value) => handleTextFieldStyling(value, 'cornerRadius')"
    />
    <div class="m-2"></div>
    <UITextMdNormal>{{ t("common.padding") }}</UITextMdNormal>
    <div class="my-2 grid grid-cols-2 gap-4">
      <CountInput
        :is-label-small="true"
        :label="t('common.top')"
        :value="output?.padding?.top"
        @update:value="(value) => handleTextFieldStyling(value, 'paddingTop')"
      />
      <CountInput
        :is-label-small="true"
        :label="t('common.bottom')"
        :value="output?.padding?.bottom"
        @update:value="(value) => handleTextFieldStyling(value, 'paddingBottom')"
      />
    </div>
    <div class="my-2 grid grid-cols-2 gap-4">
      <CountInput
        :is-label-small="true"
        :label="t('common.left')"
        :value="output?.padding?.left"
        @update:value="(value) => handleTextFieldStyling(value, 'paddingLeft')"
      />
      <CountInput
        :is-label-small="true"
        :label="t('common.right')"
        :value="output?.padding?.right"
        @update:value="(value) => handleTextFieldStyling(value, 'paddingRight')"
      />
    </div>
    <div class="m-2"></div>
    <UITextMdNormal>{{ t("common.shadow") }}</UITextMdNormal>
    <ColorInputField
      :label="t('common.color')"
      :color="output?.shadow?.color"
      @update:color="(value) => handleTextFieldStyling(value, 'shadowColor')"
    />
    <div class="my-2 grid grid-cols-2 gap-4">
      <CountInput
        :is-label-small="true"
        :label="t('common.horizontal')"
        :value="output?.shadow?.horizontal"
        @update:value="(value) => handleTextFieldStyling(value, 'shadowHorizontal')"
      />
      <CountInput
        :is-label-small="true"
        :label="t('common.vertical')"
        :value="output?.shadow?.vertical"
        @update:value="(value) => handleTextFieldStyling(value, 'shadowVertical')"
      />
    </div>
    <div class="my-2 grid grid-cols-2 gap-4">
      <CountInput
        :is-label-small="true"
        :label="t('common.blur')"
        :value="output?.shadow?.blur"
        @update:value="(value) => handleTextFieldStyling(value, 'shadowBlur')"
      />
      <CountInput
        :is-label-small="true"
        :label="t('common.spread')"
        :value="output?.shadow?.spread"
        @update:value="(value) => handleTextFieldStyling(value, 'shadowSpread')"
      />
    </div>
  </Card>
</template>

<style>
.field-name-sidebar {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center !important;
  margin-top: 1rem;
}
</style>
