<template>
  <section id="style-container" class="styles-container">
    <section class="basic-settings">
      <UICollapse
        :accordion="true"
        :arrow-placement="'left'"
        :default-expanded-names="['onSubmit']"
      >
        <UICollapseItem id="onSubmit" name="onSubmit">
          <template #header
            ><div>{{ t("common.onSubmit") }}</div></template
          >
          <div class="collapse-content-container">
            <div class="collapse-content">
              <SubmitOptions :is-survey="isSurvey"></SubmitOptions>
            </div>
          </div>
        </UICollapseItem>
        <UICollapseItem v-if="isSurvey" id="disqualifyImmediately" name="disqualifyImmediately">
          <template #header
            ><div>{{ t("survey.disqualifyImmediately") }}</div></template
          >
          <div class="collapse-content-container">
            <div class="collapse-content gap-2">
              <DisqualifyImmediately></DisqualifyImmediately>
            </div>
          </div>
        </UICollapseItem>
        <UICollapseItem v-if="isSurvey" id="disqualifyAfterSubmit" name="disqualifyAfterSubmit">
          <template #header
            ><div>{{ t("survey.disqualifyAfterSubmit") }}</div></template
          >
          <div class="collapse-content-container">
            <div class="collapse-content gap-2">
              <DisqualifyAfterSubmit></DisqualifyAfterSubmit>
            </div>
          </div>
        </UICollapseItem>
        <UICollapseItem id="facbookPixelId" name="facbookPixelId">
          <template #header
            ><div>{{ t("common.facebookPixelID") }}</div></template
          >
          <div class="collapse-content-container">
            <div class="collapse-content gap-2">
              <FacebookPixelId></FacebookPixelId>
            </div>
          </div>
        </UICollapseItem>
        <UICollapseItem id="facebookPixelEvents" name="facebookPixelEvents">
          <template #header
            ><div>{{ t("common.facebookPixelEvents") }}</div></template
          >
          <div class="collapse-content-container">
            <div class="collapse-content">
              <FacebookPixelEvent :is-survey="isSurvey"></FacebookPixelEvent>
            </div>
          </div>
        </UICollapseItem>
        <UICollapseItem id="formSettings" name="formSettings">
          <template #header
            ><div>
              {{ isSurvey ? t("survey.surveySettings") : t("form.formSettings") }}
            </div></template
          >
          <div class="collapse-content-container">
            <div class="collapse-content">
              <FormSurveySettings :is-survey="isSurvey"></FormSurveySettings>
            </div>
          </div>
        </UICollapseItem>
        <UICollapseItem v-if="isSurvey" id="footerHtml" name="footerHtml">
          <template #header
            ><div>
              {{ t("survey.footerHTML") }}
            </div></template
          >
          <div class="collapse-content-container">
            <div class="collapse-content">
              <FooterHtml></FooterHtml>
            </div>
          </div>
        </UICollapseItem>
      </UICollapse>
    </section>
  </section>
</template>

<script setup lang="ts">
import { UICollapse, UICollapseItem } from "@gohighlevel/ghl-ui";
import { useI18n } from "vue-i18n";
import DisqualifyAfterSubmit from "../OptionsSettings/DisqualifySettings/DisqualifyAfterSubmit.vue";
import DisqualifyImmediately from "../OptionsSettings/DisqualifySettings/DisqualifyImmediately.vue";
import FacebookPixelEvent from "../OptionsSettings/Facebook/FacebookPixelEvent.vue";
import FacebookPixelId from "../OptionsSettings/Facebook/FacebookPixelId.vue";
import FooterHtml from "../OptionsSettings/FooterHtml.vue";
import FormSurveySettings from "../OptionsSettings/FormSurveySettings.vue";
import SubmitOptions from "../OptionsSettings/SubmitOptions.vue";

const { t } = useI18n();

defineProps({
  isSurvey: {
    type: Boolean,
    default: true,
  },
});
</script>

<style scoped>
.styles-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.styles-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
