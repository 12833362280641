<script setup lang="ts">
import { useEmitter } from "@/composition/useEmitter";
import { useAppStore } from "@/store/app";
import { InfoCircleIcon, XCloseIcon } from "@gohighlevel/ghl-icons/24/outline";
import {
  UIButton,
  UIDivider,
  UIDrawer,
  UIDrawerContent,
  UIInput,
  UISwitch,
  UITooltip,
} from "@gohighlevel/ghl-ui";
import debounce from "lodash/debounce";
import { onBeforeMount, onBeforeUnmount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps({
  builderType: {
    type: String,
    default: "Form",
  },
});
const show = ref(false);
const emitter = useEmitter();
const store = useAppStore();

const emailNotificationsConfig = JSON.parse(JSON.stringify(store.emailNotificationsConfig));
const emails = ref(emailNotificationsConfig?.emailTo || []);
const emailInput = ref("");
const subject = ref(emailNotificationsConfig?.subject || store.builder.name);
const replyToEmail = ref(emailNotificationsConfig?.replyToEmail || "");
const senderName = ref(emailNotificationsConfig?.senderName || "");
const showEmailError = ref(false);
const autoResponderConfig = JSON.parse(JSON.stringify(store.autoResponderConfig));
const autoResponderSubject = ref(autoResponderConfig?.subject || store.builder.name);
const autoResponderReplyToEmail = ref(autoResponderConfig?.replyToEmail || "");
const autoResponderSenderName = ref(autoResponderConfig?.senderName || "");

const addEmail = () => {
  const email = emailInput.value.trim();
  if (email && !emails.value.includes(email)) {
    emails.value.push(email);
  }
  emailInput.value = "";
};

const removeEmail = (index: number) => {
  emails.value.splice(index, 1);
};

const toggleOverlay = () => {
  show.value = !show.value;
  emitter.emit("close-left-side-bar");
};

const closeOverlay = () => {
  show.value = false;
};

const builderTypeConvert = ref(
  props.builderType === "Form" ? t("form.formNotification") : t("survey.surveyNotfication")
);

const toggleEmailNotifications = debounce((value) => {
  store.builderConfig.emailNotifications = value;
  store.addToHistory();
  store.anyUnsavedChanges = true;
}, 10);

const clearFields = () => {
  emails.value = [];
  emailInput.value = "";
  subject.value = "";
  replyToEmail.value = "";
  senderName.value = "";
  autoResponderSubject.value = "";
  autoResponderReplyToEmail.value = "";
  autoResponderSenderName.value = "";
  store.anyUnsavedChanges = true;
};

const saveFields = () => {
  if (emails.value.length === 0) {
    showEmailError.value = true;
  } else {
    showEmailError.value = false;
    store.emailNotificationsConfig = {
      senderName: senderName.value,
      subject: subject.value,
      emailTo: emails.value,
      replyToEmail: replyToEmail.value,
    };
  }
  store.autoResponderConfig = {
    subject: autoResponderSubject.value,
    replyToEmail: autoResponderReplyToEmail.value,
    senderName: autoResponderSenderName.value,
  };
  store.anyUnsavedChanges = true;
  closeOverlay();
};

const toggleAutoResponder = debounce((value) => {
  store.builderConfig.autoResponder = value;
  store.addToHistory();
  store.anyUnsavedChanges = true;
}, 10);

onBeforeMount(() => {
  emitter.on("toggle-email-side-bar", toggleOverlay);
  emitter.on("close-email-side-bar", closeOverlay);
});

onBeforeUnmount(() => {
  emitter.off("toggle-email-side-bar", toggleOverlay);
  emitter.off("close-email-side-bar", closeOverlay);
});
</script>

<template>
  <div>
    <div>
      <UIDrawer
        id="email-builder-drawer"
        :show="show"
        :width="370"
        placement="left"
        :show-mask="false"
        display-directive="show"
        class="border-gray-100 font-sans"
        @update:show="closeOverlay"
      >
        <UIDrawerContent
          id="drawer-component"
          :positive-text="t('common.save')"
          :negative-text="t('common.clear')"
          :title="builderTypeConvert"
          @positive-click="saveFields"
          @negative-click="clearFields"
        >
          <div>
            <div class="flex justify-between py-2">
              <div class="switch-handle my-auto text-base font-semibold text-gray-900">
                {{ t("common.emailNotification") }}
              </div>
              <div class="switch">
                <UISwitch
                  size="small"
                  :value="store.builderConfig.emailNotifications"
                  :disabled="false"
                  @update:value="toggleEmailNotifications"
                />
              </div>
            </div>
            <div class="mt-6 text-sm text-gray-600">
              {{ t("common.emailNotificationSubheader") }}
            </div>
            <div v-if="store.builderConfig.emailNotifications">
              <div class="mb-1.5 mt-6 text-xs text-gray-700">{{ t("common.subject") }}</div>
              <UIInput
                id="email-subject"
                v-model="subject"
                type="textarea"
                placeholder=""
                :autosize="{
                  minRows: 3,
                  maxRows: 3,
                }"
              />
              <div class="mb-1.5 mt-6 text-xs text-gray-700">
                {{ t("common.emailTo") }}<span class="ml-1 text-red-600">*</span>
                <UITooltip class="max-w-[280px]" trigger="hover" placement="top">
                  <template #trigger>
                    <InfoCircleIcon class="ml-1 h-3 w-3 text-gray-400"></InfoCircleIcon>
                  </template>
                  {{ t("common.emailTooltip") }}
                </UITooltip>
              </div>
              <div
                class="flex max-h-52 flex-wrap overflow-y-auto rounded-lg border border-gray-300 px-3.5 py-2.5"
              >
                <div class="flex flex-wrap gap-2">
                  <span
                    v-for="(email, index) in emails"
                    :key="index"
                    class="flex items-center gap-1.5 rounded-md border border-gray-300 px-2 py-0.5 text-sm font-medium text-gray-700"
                  >
                    {{ email }}
                    <UIButton id="remove-email" :quaternary="true" text @click="removeEmail(index)">
                      <XCloseIcon class="h-3 w-3 text-gray-400" />
                    </UIButton>
                  </span>
                </div>
                <input
                  v-model="emailInput"
                  type="text"
                  class="flex-1 border-none p-1.5 text-sm font-medium outline-none"
                  @keydown.enter="addEmail"
                  @blur="addEmail"
                />
              </div>
              <span v-if="showEmailError" class="text-xs text-red-600">{{
                t("common.emailError")
              }}</span>
              <div class="mb-1.5 mt-6 text-xs text-gray-700">
                {{ t("common.replyToEmail")
                }}<UITooltip class="max-w-[280px]" trigger="hover" placement="top">
                  <template #trigger>
                    <InfoCircleIcon class="ml-1 h-3 w-3 text-gray-400"></InfoCircleIcon>
                  </template>
                  {{ t("common.replyToTooltip") }}
                </UITooltip>
              </div>
              <UIInput id="auto-responder-reply-to" v-model="replyToEmail" placeholder="" />
              <div class="mb-1.5 mt-6 text-xs text-gray-700">{{ t("common.senderName") }}</div>
              <UIInput id="email-sender" v-model="senderName" placeholder="" />
              <div class="mt-1.5 text-sm text-gray-600">
                {{ t("common.senderNameWarning") }}
              </div>
            </div>
          </div>
          <UIDivider />
          <div>
            <div class="flex justify-between py-2">
              <div class="switch-handle my-auto text-base font-semibold text-gray-900">
                {{ t("common.autoResponder") }}
              </div>
              <div class="switch">
                <UISwitch
                  size="small"
                  :value="store.builderConfig.autoResponder"
                  :disabled="false"
                  @update:value="toggleAutoResponder"
                />
              </div>
            </div>
            <div class="mt-6 text-sm text-gray-600">
              {{ t("common.autoResponderSubheader") }}
            </div>
            <div v-if="store.builderConfig.autoResponder" class="mb-28">
              <div class="mb-1.5 mt-6 text-xs text-gray-700">{{ t("common.subject") }}</div>
              <UIInput
                id="auto-responder-subject"
                v-model="autoResponderSubject"
                type="textarea"
                placeholder=""
                :autosize="{
                  minRows: 3,
                  maxRows: 3,
                }"
              />
              <div class="mb-1.5 mt-6 text-xs text-gray-700">
                {{ t("common.replyToEmail")
                }}<UITooltip class="max-w-[280px]" trigger="hover" placement="top">
                  <template #trigger>
                    <InfoCircleIcon class="ml-1 h-3 w-3 text-gray-400"></InfoCircleIcon>
                  </template>
                  {{ t("common.replyToTooltip") }}
                </UITooltip>
              </div>
              <UIInput
                id="auto-responder-reply-to"
                v-model="autoResponderReplyToEmail"
                placeholder=""
              />
              <div class="mb-1.5 mt-6 text-xs text-gray-700">{{ t("common.senderName") }}</div>
              <UIInput
                id="auto-responder-sender"
                v-model="autoResponderSenderName"
                placeholder=""
              />
              <div class="mt-1.5 text-sm text-gray-600">
                {{ t("common.senderNameWarning") }}
              </div>
            </div>
          </div>
        </UIDrawerContent>
      </UIDrawer>
    </div>
  </div>
</template>

<style>
#email-builder-drawer {
  top: 100px !important;
}
</style>
