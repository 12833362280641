<template>
  <div>
    <UISelect
      id="add-field"
      v-model:value="selectedField"
      :options="fieldOptions"
      placeholder="Add Field"
      filterable
      @update:value="addField"
    />

    <div class="my-3 flex min-h-[6rem] flex-wrap gap-2 rounded border p-3">
      <UITag
        v-for="field in fieldsWithLabels"
        :key="field"
        class="rounded p-2.5"
        :type="field.length > 1 ? 'info' : 'default'"
      >
        {{ field }}
      </UITag>
    </div>
    <div class="mb-2 grid grid-cols-4 gap-2">
      <UIButton
        v-for="(num, index) in numberPad"
        id="number-pad"
        :key="num"
        size="large"
        class="flex transform items-center justify-center rounded-[100px] px-8 text-lg font-bold transition-transform duration-100 active:scale-90"
        :class="{ 'col-span-2': index >= numberPad.length - 2 }"
        type="tertiary"
        :style="buttonStyle(num)"
        @click="addNumber(num)"
      >
        {{ num }}
      </UIButton>
    </div>
    <div class="my-2 mt-3 flex items-center text-sm">
      <span
        class="mr-2 inline-block h-2.5 w-2.5 rounded-full border border-black bg-primary-500"
      ></span>
      <span class="mr-4">Field</span>
      <span class="mr-2 inline-block h-2.5 w-2.5 rounded-full border border-black bg-black"></span>
      <span class="mr-4">Number</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { UIButton, UITag, UISelect } from "@gohighlevel/ghl-ui";
import { useAppStore } from "@/store/app";
import { standardFields } from "@/util/methods";

const selectedField = ref(null);

const store = useAppStore();

const props = defineProps({
  calculation: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(["update:calculation"]);

const includedTypes = [
  "monetory",
  "numerical",
  "score",
  "textbox_list",
  "single_options",
  "multiple_options",
  "radio",
  "checkbox",
];

const mapFieldOptions = (item) => ({
  value: item.hiddenFieldQueryKey,
  label: item.label,
  type: item.type,
  disabled: !includedTypes.includes(item.type),
  picklistOptions: item.picklistOptions || item?.picklistOptionsImage?.map((x) => x.label) || null,
});

let fieldOptions;

const filterFunction = (i) =>
  (!["button", "header", "html", "image"].includes(i?.tag) &&
    !i?.picklistOptionsImage?.length &&
    (((i?.model === "contact" || !i?.model) &&
      ["monetory", "numerical", "single_options", "multiple_options", "radio", "checkbox"].includes(
        i?.type
      )) ||
      (["monetory", "numerical"].includes(i?.type) && i?.model === "opportunity"))) ||
  i?.type === "score";

if (store.formId) {
  fieldOptions = store.elements.filter(filterFunction).map(mapFieldOptions);
} else {
  fieldOptions = store.slides.reduce((acc, slide) => {
    const slideFieldOptions = slide.slideData.filter(filterFunction).map(mapFieldOptions);
    return acc.concat(slideFieldOptions);
  }, []);
}

const fields = ref(props?.calculation || []);

const fieldsWithLabels = computed(() =>
  fields.value.map((field) => {
    const fieldOption = fieldOptions.find((option) => option.value === field);
    return fieldOption ? fieldOption.label : field;
  })
);

const numberPad = [
  "←",
  "(",
  ")",
  "*",
  "7",
  "8",
  "9",
  "-",
  "4",
  "5",
  "6",
  "+",
  "1",
  "2",
  "3",
  "/",
  "0",
  ".",
];

const buttonStyle = (num) => {
  if (["+", "-", "*", "/"].includes(num)) {
    return { backgroundColor: "#F38744", color: "white" };
  } else if (num === "←") {
    return { backgroundColor: "#101828", color: "white" };
  }
  return { backgroundColor: "#E5E7EB", color: "#000" };
};

const addField = () => {
  if (selectedField.value) {
    addNumber(selectedField.value);
    selectedField.value = null;
  }
};

const addNumber = (num) => {
  if (num === "←") {
    fields.value.pop();
  } else {
    fields.value.push(num);
  }
  emit("update:calculation", fields.value);
};
</script>

<style>
#add-field .n-base-selection-overlay__wrapper,
#add-field .n-base-suffix__arrow {
  color: #333639 !important;
}
</style>
