<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { DEFAULT_LABEL_COLOR, DEFAULT_TEXT_COLOR } from "@/util/constants";
import { applyStyles } from "@/util/methods";
import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  ArrowLeftIcon,
  Edit01Icon,
  InfoCircleIcon,
} from "@gohighlevel/ghl-icons/24/outline";
import {
  UIButton,
  UICheckbox,
  UIDivider,
  UIInput,
  UISelect,
  UITooltip,
  UICollapse,
  UICollapseItem,
  UITabs,
  UITabPane,
  UIAlert,
  UISwitch,
  UIInputGroup,
  UIInputGroupLabel,
  UIInputNumber,
} from "@gohighlevel/ghl-ui";
import { computed, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import CodeEditorModel from "../common/CodeEditorModel.vue";
import ColorInputField from "../common/ColorInputField.vue";
import CountInput from "../common/CountInput.vue";
import DateSettings from "../common/DateSettings.vue";
import EditOpportunitySettingsModal from "../common/EditOpportunitySettingsModal.vue";
import PopupRichTextEditor from "../common/PopupRichTextEditor.vue";
import RichTextEditor from "../common/RichTextEditor.vue";
import TextSidebar from "../common/TextSidebar.vue";
import Card from "./../common/Card.vue";
import ListEditor from "../common/ListEditor.vue";
import DropdownEditor from "../common/DropdownEditor.vue";
import RadioSelectEditor from "../common/RadioSelectEditor.vue";
import FileUploadEditor from "../common/FileUploadEditor.vue";
import ScoreEditor from "../common/ScoreEditor.vue";
import { storeToRefs } from "pinia";
import { cloneDeep, snakeCase } from "lodash";

const store = useAppStore();
const { t } = useI18n();
const { getCustomFieldsFromStore } = storeToRefs(store);
const props = defineProps({
  selectedElement: {
    type: Object,
    default() {
      return {};
    },
  },
});

//Get Slide Settings or Slide Elements Options Settings
const selectedElementStore = reactive(
  props.selectedElement.index !== undefined
    ? store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    : store.slides[props.selectedElement.slideIndex]
);

const dateElement = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index];
});

const showModal = ref(false);
const uniqueKeyPlaceholder = ref(snakeCase(selectedElementStore?.label));

const showModelUpdate = (modalUpdate: boolean) => {
  showModal.value = modalUpdate;
};

const submitUpdate = (updatedCode: string) => {
  selectedElementStore.html = updatedCode;
  store.anyUnsavedChanges = true;
};

const onClickOfEditHTML = () => {
  showModal.value = !showModal.value;
};

const onBack = () => {
  store.currentCustomSidebarPreview = undefined;
  store.isAnyElementActive = false;
};

const getSlideName = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideName;
});

const getLabel = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    .label;
});

const getTypeLabel = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    ?.typeLabel;
});

const getUniqueKey = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    ?.fieldKey;
});

const updatePicklistOptions = (options) => {
  const picklistOptionsCopy = cloneDeep(selectedElementStore.picklistOptions);
  if (
    JSON.stringify(picklistOptionsCopy) !== JSON.stringify(selectedElementStore.options) &&
    selectedElementStore?.id &&
    selectedElementStore?.logic
  ) {
    store.slides[props.selectedElement.slideIndex].logic[selectedElementStore?.id] = null;
  }
  if (
    ["SINGLE_OPTIONS", "MULTIPLE_OPTIONS", "RADIO", "CHECKBOX"].includes(
      selectedElementStore?.dataType
    )
  ) {
    if (selectedElementStore.enableCalculations) {
      selectedElementStore.calculatedOptions = options;
    }
    selectedElementStore.picklistOptions = options.map((option) => option.label);
  } else {
    selectedElementStore.picklistOptions = options;
  }
  if (store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]?.id) {
    store.slides[props.selectedElement.slideIndex].slideData[
      props.selectedElement.index
    ].customfieldUpdated = true;
  }
  store.anyUnsavedChanges = true;
};

const updateEnableCalculations = (newValue) => {
  selectedElementStore.enableCalculations = newValue;
  store.anyUnsavedChanges = true;
};

const updateUniqueKey = (value: string) => {
  if (value.trim() != "") {
    store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].fieldKey =
      value;
    store.anyUnsavedChanges = true;
  }
};

const getPlaceholder = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    .placeholder;
});

const getShortLabel = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    .shortLabel;
});

const getHiddenQuery = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    .hiddenFieldQueryKey;
});

const getRequired = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    .required;
});

const getHidden = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    .hidden;
});

const getEnableCountryPicker = computed(() => {
  if (
    store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
      .enableCountryPicker === undefined
  ) {
    return true;
  }
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    .enableCountryPicker;
});

const enableValidateEmailModel = computed({
  get() {
    return (
      store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
        ?.enableValidateEmail ?? false
    );
  },
  set(value: boolean) {
    store.slides[props.selectedElement.slideIndex].slideData[
      props.selectedElement.index
    ].enableValidateEmail = value;
    store.anyUnsavedChanges = true;
  },
});

const enableValidatePhoneModel = computed({
  get() {
    return (
      store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
        ?.enableValidatePhone ?? false
    );
  },
  set(value: boolean) {
    store.slides[props.selectedElement.slideIndex].slideData[
      props.selectedElement.index
    ].enableValidatePhone = value;
    store.anyUnsavedChanges = true;
  },
});

const getHiddenValue = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    .hiddenFieldValue;
});

const getSource = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    .value;
});

const getSpreadColumns = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    ?.spreadColumns;
});
const getNumberOfColumns = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    ?.columnsNumber;
});

const getDefaultNumberOfColumns = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]
    ?.picklistOptionsImage
    ? undefined
    : 1;
});

const isHiddenFieldDisable = computed(() =>
  Boolean(
    store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]?.required
  )
);

const isRequiredFieldDisable = computed(() =>
  Boolean(
    store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index]?.hidden
  )
);

const pickupFieldOptions = computed(() => {
  if (selectedElementStore.picklistOptions === undefined) {
    return selectedElementStore.picklistOptionsImage;
  }
  return selectedElementStore.picklistOptions;
});

const setSlideJumpTo = (value) => {
  store.slides[props.selectedElement.slideIndex].jumpTo = value;
  store.anyUnsavedChanges = true;
};

const radioOptionSelection = (value, option, optionIndex) => {
  const selectedKey = selectedElementStore.id;
  const logic = store.slides[props.selectedElement.slideIndex].logic || {};
  if (value !== "") {
    if (logic[selectedKey]) {
      logic[selectedKey]["option_" + optionIndex] = {
        name: [-1, -2].includes(value) ? disqualifyName(value) : getName(value),
        id: [-1, -2].includes(value) ? disqualifyId(value) : value,
        index: [-1, -2].includes(value) ? value : slideAndFieldsMapping.value[value],
        fieldId: selectedKey,
        optionIndex: optionIndex,
        optionName: option.label ? option.label : option,
        status: true,
      };
    } else {
      logic[selectedKey] = {
        ["option_" + optionIndex]: {
          name: [-1, -2].includes(value) ? disqualifyName(value) : getName(value),
          id: [-1, -2].includes(value) ? disqualifyId(value) : value,
          index: [-1, -2].includes(value) ? value : slideAndFieldsMapping.value[value],
          fieldId: selectedKey,
          optionIndex: optionIndex,
          optionName: option.label ? option.label : option,
          status: true,
        },
      };
    }
  } else if (logic[selectedKey]) {
    if (logic[selectedKey]["option_" + optionIndex]) {
      delete logic[selectedKey]["option_" + optionIndex];
    }
    if (Object.keys(logic[selectedKey]).length <= 0) {
      delete logic[selectedKey];
    }
  }
  if (Object.keys(logic).length <= 0) {
    delete store.slides[props.selectedElement.slideIndex].logic;
  } else {
    store.slides[props.selectedElement.slideIndex].logic = logic;
  }
  store.anyUnsavedChanges = true;
};

const getName = (index) => {
  return radioOptionMapping.value[index].label;
};

const slideOptions = computed(() => {
  if (props.selectedElement.slideIndex === store.slides.length - 1) {
    return [{ value: "submitSurvey", label: "Submit Survey" }];
  }
  return store.getSlideDropDownMenu(props.selectedElement.slideIndex);
});

const radioOption = computed(() => {
  const radioOptionTemp: any = [{ value: "", label: "Select" }];
  if (store.formAction.fieldSettingEnable) {
    let questionCounter = 0;
    store.slides[0].slideData.forEach((fields, index) => {
      //Duplicate Type of element is not allowed in Question
      if (props.selectedElement.index !== index && !store.duplicateAllowed.includes(fields.tag)) {
        radioOptionTemp.push({
          value: fields.tag,
          label: `Q${questionCounter + 1} - ${fields.label}`,
        });
        questionCounter += 1;
      }
    });
  } else {
    store.slides.forEach((slide, index) => {
      if (props.selectedElement.slideIndex !== index) {
        radioOptionTemp.push({
          value: slide.id,
          label: `P${index + 1} - ${slide.slideName}`,
        });
      }
    });
  }

  radioOptionTemp.push({
    value: -1,
    label: "Disqualify immediately",
  });
  radioOptionTemp.push({
    value: -2,
    label: "Disqualify After Submit",
  });
  return radioOptionTemp;
});

const radioOptionMapping = computed(() => {
  const mapping = {};
  radioOption.value.forEach((current) => {
    if (!mapping[current.value]) {
      mapping[current.value] = current;
    }
  });
  return mapping;
});

const slideAndFieldsMapping = computed(() => {
  const mapping: { [key: string]: number } = {};
  if (!store.formAction.fieldSettingEnable) {
    store.slides.forEach((slide, index) => {
      if (!mapping[slide.id]) {
        mapping[slide.id] = index;
      }
    });
  } else {
    store.slides[0].slideData.forEach((element, index) => {
      if (!mapping[element.tag]) {
        mapping[element.tag] = index;
      }
    });
  }
  return mapping;
});

const fieldRadioActionModel = (index) => {
  const elementId = selectedElementStore.id;

  const logic = store.slides[props.selectedElement.slideIndex].logic;
  if (logic && logic[elementId] && logic[elementId]["option_" + index]) {
    return [-1, -2].includes(logic[elementId]["option_" + index].index)
      ? logic[elementId]["option_" + index].index
      : logic[elementId]["option_" + index].id;
  }
  return "";
};

const disqualifyName = (value) => {
  return value === -1 ? "Disqualify immediately" : "Disqualify after submit";
};
const disqualifyId = (value) => {
  return value === -1 ? "disqualify" : "endsurvey";
};

const handleImageWidth = (val) => {
  store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].width = val;
  store.anyUnsavedChanges = true;
};

const handleImageHeight = (val) => {
  store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].height =
    val;
  store.anyUnsavedChanges = true;
};

const handleTextColor = (val: string) => {
  store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].textColor =
    val;
  store.anyUnsavedChanges = true;
};

const handleLinkColor = (val: string) => {
  store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].linkColor =
    val;
  store.anyUnsavedChanges = true;
};

const getAltImage = computed(() => {
  return store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].alt;
});

const alignImage = (align) => {
  store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].align =
    align;
  store.addToHistory();
  store.anyUnsavedChanges = true;
};

const updatePreview = (element) => {
  element.preview = applyStyles(element.placeholder, element.linkColor, element.textColor);
};

const updateCalculation = (calculation) => {
  store.slides[props.selectedElement.slideIndex].slideData[
    props.selectedElement.index
  ].calculation = calculation;
  store.anyUnsavedChanges = true;
};

const saveOpportunitySettings = (settings) => {
  store.opportunitySettings = settings;
  store.anyUnsavedChanges = true;
  store.showOpportunitiesModal = false;
};

const tncElement =
  store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index];

watch(
  () => [tncElement?.placeholder, tncElement?.linkColor, tncElement?.textColor],
  () => {
    updatePreview(tncElement);
  }
);

const showPopupRichTextEditorModal = ref(false);

const showPopupRichTextEditorUpdate = (modalUpdate: boolean) => {
  showPopupRichTextEditorModal.value = modalUpdate;
};

const submitPopupRichTextEditorUpdate = (updatedCode: string) => {
  selectedElementStore.description = updatedCode;
  store.anyUnsavedChanges = true;
};

const editDescription = () => {
  showPopupRichTextEditorModal.value = !showPopupRichTextEditorModal.value;
};
//To Maintain Reactivity of Store
const handleTextUpdate = (output) => {
  Object.assign(
    store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index],
    output
  );
};

const updateSlideName = (value: string) => {
  store.slides[props.selectedElement.slideIndex].slideName = value;
  store.anyUnsavedChanges = true;
};

const updateLabel = (value: string) => {
  uniqueKeyPlaceholder.value = snakeCase(value);
  if (
    selectedElementStore?.dataType === "SCORE" &&
    !uniqueKeyPlaceholder.value.startsWith("score_")
  ) {
    uniqueKeyPlaceholder.value = "score_" + uniqueKeyPlaceholder.value;
  }
  updateUniqueKey(uniqueKeyPlaceholder.value);
  store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].label =
    value;
  store.anyUnsavedChanges = true;
};

const updatePlaceholder = (value: string) => {
  store.slides[props.selectedElement.slideIndex].slideData[
    props.selectedElement.index
  ].placeholder = value;
  store.anyUnsavedChanges = true;
};

const updateShortLabel = (value: string) => {
  store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].shortLabel =
    value;
  store.anyUnsavedChanges = true;
};

const updateHiddenQuery = (value: string) => {
  store.slides[props.selectedElement.slideIndex].slideData[
    props.selectedElement.index
  ].hiddenFieldQueryKey = value;
  store.anyUnsavedChanges = true;
};

const updateRequired = (value: boolean) => {
  store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].required =
    value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const updateHidden = (value: boolean) => {
  store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].hidden =
    value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const updateEnableCountryPicker = (value: boolean) => {
  store.slides[props.selectedElement.slideIndex].slideData[
    props.selectedElement.index
  ].enableCountryPicker = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const updateHiddenValue = (value: string) => {
  store.slides[props.selectedElement.slideIndex].slideData[
    props.selectedElement.index
  ].hiddenFieldValue = value;
  store.anyUnsavedChanges = true;
};

const updateSource = (value: string) => {
  store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].value =
    value;
  store.anyUnsavedChanges = true;
};

const updateAltImage = (value: string) => {
  store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].alt = value;
  store.anyUnsavedChanges = true;
};

const updateDateFormat = (value: string) => {
  store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].format =
    value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};
const updateDateSeparator = (value: string) => {
  store.slides[props.selectedElement.slideIndex].slideData[props.selectedElement.index].separator =
    value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const updateDatedisablePicker = (value: boolean) => {
  store.slides[props.selectedElement.slideIndex].slideData[
    props.selectedElement.index
  ].disablePicker = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const updateSpreadColumns = (value: boolean) => {
  store.slides[props.selectedElement.slideIndex].slideData[
    props.selectedElement.index
  ].spreadColumns = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};
const updateNumberOfColumns = (value: number) => {
  store.slides[props.selectedElement.slideIndex].slideData[
    props.selectedElement.index
  ].columnsNumber = value;
  store.anyUnsavedChanges = true;
  store.addToHistory();
};
</script>

<template>
  <div>
    <div class="relative mb-6 flex items-center justify-center">
      <div class="arrow-button absolute left-0" @click="onBack">
        <ArrowLeftIcon class="mr-2 h-4 w-4 text-gray-800" />
      </div>
      <div class="text-md text-gray-900">{{ getTypeLabel }}</div>
    </div>
    <div
      :id="
        [
          'TEXTBOX_LIST',
          'SINGLE_OPTIONS',
          'MULTIPLE_OPTIONS',
          'RADIO',
          'CHECKBOX',
          'FILE_UPLOAD',
          'SCORE',
        ].includes(selectedElementStore?.dataType) &&
        !selectedElementStore?.picklistOptionsImage?.length
          ? ''
          : 'hide-tabs'
      "
    >
      <UITabs justify-content="space-around" default-value="Content" type="line">
        <UITabPane name="Content" :tab="t('common.content')">
          <UICollapse
            id="custom-field-settings"
            :arrow-placement="'left'"
            :default-expanded-names="['General Settings']"
          >
            <UICollapseItem id="general-settings" title="General Settings" name="General Settings">
              <div v-if="selectedElementStore?.type == 'html'">
                <h6 class="field-name-sidebar">
                  {{ selectedElementStore.label }}
                </h6>
                <div class="form-group">
                  <Card :header="selectedElementStore.placeholder">
                    <template #default>
                      <UIButton
                        id="editHtml"
                        type="default"
                        class="w-[100%]"
                        @click="onClickOfEditHTML"
                        >{{ t("common.editHTML") }}</UIButton
                      >
                    </template>
                  </Card>
                </div>
                <div>
                  <CodeEditorModel
                    v-if="showModal"
                    :model-active="showModal"
                    :code="
                      store.slides[props.selectedElement.slideIndex].slideData[
                        props.selectedElement.index
                      ].html
                    "
                    @update:show-model="showModelUpdate"
                    @update:submit="submitUpdate"
                  />
                </div>
              </div>
              <div v-else-if="selectedElementStore.tag === 'header'">
                <TextSidebar
                  :property="
                    JSON.parse(
                      JSON.stringify(
                        store.slides[props.selectedElement.slideIndex].slideData[
                          props.selectedElement.index
                        ]
                      )
                    )
                  "
                  @update:text="handleTextUpdate"
                />
              </div>
              <div v-else-if="selectedElementStore.tag === 'source'">
                <h6 class="field-name-sidebar">{{ selectedElementStore.label }}</h6>
                <div class="form-group">
                  <UIInput
                    id="sourceLabel"
                    class="standard-input-ghl"
                    :placeholder="t('common.enterSourceValue')"
                    :model-value="getSource"
                    @update:model-value="updateSource"
                    @blur="store.addToHistory()"
                  ></UIInput>
                </div>
              </div>
              <div v-else-if="selectedElementStore.tag === 'captcha'">
                <h6 class="field-name-sidebar">
                  {{ selectedElementStore.label || selectedElementStore.name }}
                </h6>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("common.label") }}</span
                    >
                  </div>
                  <UIInput
                    id="captchaLabel"
                    class="standard-input-ghl"
                    :model-value="getLabel"
                    @update:model-value="updateLabel"
                    @blur="store.addToHistory()"
                  >
                  </UIInput>
                </div>
              </div>
              <div v-else-if="selectedElementStore.tag === 'image'">
                <h6 class="field-name-sidebar">
                  {{ selectedElementStore.label }}
                </h6>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("common.altText") }}</span
                    >
                  </div>
                  <UIInput
                    id="altText"
                    class="standard-input-ghl"
                    :model-value="getAltImage"
                    @update:model-value="updateAltImage"
                    @blur="store.addToHistory()"
                  ></UIInput>
                </div>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <div class="alignment">
                      <div class="alignment-div">{{ t("common.alignment") }}</div>
                      <div class="icons-collection">
                        <AlignLeftIcon
                          class="custom-icons h-5 w-5"
                          @click="alignImage('left')"
                        ></AlignLeftIcon>
                        <AlignCenterIcon
                          class="custom-icons h-5 w-5"
                          @click="alignImage('center')"
                        ></AlignCenterIcon>
                        <AlignRightIcon
                          class="custom-icons h-5 w-5"
                          @click="alignImage('right')"
                        ></AlignRightIcon>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <CountInput
                      :label="t('common.imageWidth')"
                      :value="selectedElementStore.width || ''"
                      :max="1800"
                      @update:value="handleImageWidth"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <CountInput
                      :label="t('common.imageHeight')"
                      :value="selectedElementStore.height || ''"
                      :max="1800"
                      @update:value="handleImageHeight"
                    />
                  </div>
                </div>
              </div>
              <div v-else-if="selectedElementStore.tag === 'terms_and_conditions'">
                <h6 class="field-name-sidebar">
                  {{ selectedElementStore.label || selectedElementStore.name }}
                </h6>
                <div class="form-group flex flex-col gap-y-4">
                  <div>
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("common.text") }}</span
                    >
                    <RichTextEditor
                      :is-submit-message="true"
                      :model-value="getPlaceholder"
                      @update:model-value="updatePlaceholder"
                    ></RichTextEditor>
                  </div>
                  <div>
                    <div class="flex space-x-3">
                      <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
                        {{ t("common.queryKey") }}
                        <UITooltip trigger="hover" placement="top">
                          <template #trigger>
                            <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
                          </template>
                          {{ t("common.querykeyTooltipMessage") }}
                        </UITooltip>
                      </div>
                    </div>
                    <div>
                      <UIInput
                        id="fieldQueryKey"
                        class="standard-input-ghl"
                        :model-value="getHiddenQuery"
                        @update:model-value="updateHiddenQuery"
                        @blur="store.addToHistory()"
                      ></UIInput>
                    </div>
                  </div>
                  <div class="form-check-req-hid">
                    <UICheckbox
                      id="fieldRequired"
                      :checked="getRequired"
                      @update:checked="updateRequired"
                      >{{ t("common.required") }}</UICheckbox
                    >
                  </div>
                  <div>
                    <span
                      class="hl-text-input-label mt-1 block text-sm font-medium text-gray-700"
                      >{{ t("common.color") }}</span
                    >
                    <div class="flex">
                      <ColorInputField
                        class="w-2/3"
                        :label="t('common.text')"
                        :color="
                          store.slides[props.selectedElement.slideIndex].slideData[
                            props.selectedElement.index
                          ]?.textColor || DEFAULT_TEXT_COLOR
                        "
                        @update:color="handleTextColor"
                      />
                      <ColorInputField
                        class="w-2/3"
                        :label="t('common.link')"
                        :color="
                          store.slides[props.selectedElement.slideIndex].slideData[
                            props.selectedElement.index
                          ]?.linkColor || DEFAULT_LABEL_COLOR
                        "
                        @update:color="handleLinkColor"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="selectedElement.index === undefined">
                <h6 class="field-name-sidebar">
                  {{ selectedElementStore.slideName }}
                </h6>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("survey.slideName") }}</span
                    >
                  </div>
                  <UIInput
                    id="slideName"
                    class="standard-input-ghl"
                    :model-value="getSlideName"
                    @update:model-value="updateSlideName"
                    @blur="store.addToHistory()"
                  >
                  </UIInput>
                </div>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("survey.slidePosition") }}</span
                    >
                  </div>
                  <UIInputNumber
                    id="slidePosition"
                    :value="selectedElement.slideIndex + 1"
                    :disabled="true"
                    size="large"
                    :show-button="false"
                    class="standard-input-ghl"
                    @blur="store.addToHistory()"
                  ></UIInputNumber>
                </div>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("survey.jumpTo") }}</span
                    >
                  </div>
                  <UISelect
                    id="jump"
                    :value="selectedElementStore.jumpTo || ''"
                    :options="slideOptions"
                    :disabled="selectedElement.slideIndex == store.slides.length - 1"
                    class="standard-input-ghl"
                    @update:value="setSlideJumpTo"
                    @change="store.addToHistory()"
                  ></UISelect>
                </div>
              </div>
              <div v-else-if="['date'].includes(dateElement.type)">
                <DateSettings
                  :date-element="dateElement"
                  :index="selectedElement.index"
                  :is-required-field-disable="isRequiredFieldDisable"
                  :is-hidden-field-disable="isHiddenFieldDisable"
                  @update-label="updateLabel"
                  @update-placeholder="updatePlaceholder"
                  @update-short-label="updateShortLabel"
                  @update-hidden-query="updateHiddenQuery"
                  @update-required="updateRequired"
                  @update-hidden="updateHidden"
                  @update-hidden-value="updateHiddenValue"
                  @update-date-format="updateDateFormat"
                  @update-date-separator="updateDateSeparator"
                  @update-date-disable-picker="updateDatedisablePicker"
                ></DateSettings>
                <div v-if="selectedElementStore?.model === 'opportunity'">
                  <UIDivider />
                  <div class="flex justify-center">
                    <UIButton
                      id="edit-opportunity-settings"
                      type="tertiary"
                      @click="store.showOpportunitiesModal = true"
                    >
                      <Edit01Icon class="mr-2 h-4 w-4" /> {{ t("common.editOpportunitySettings") }}
                    </UIButton>
                  </div>
                </div>
                <EditOpportunitySettingsModal
                  :show="store.showOpportunitiesModal"
                  @update:success="saveOpportunitySettings"
                  @update:cancel="store.showOpportunitiesModal = false"
                />
              </div>
              <div v-else>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("common.fieldDescription") }}</span
                    >
                  </div>
                  <UIButton
                    id="fieldDescription"
                    type="tertiary"
                    class="standard-input-ghl w-[100%]"
                    @click="editDescription"
                    >{{ t("common.fieldDescription") }}</UIButton
                  >
                  <div>
                    <PopupRichTextEditor
                      v-if="showPopupRichTextEditorModal"
                      :model-active="showPopupRichTextEditorModal"
                      :code="selectedElementStore.description"
                      @update:show-model="showPopupRichTextEditorUpdate"
                      @update:submit="submitPopupRichTextEditorUpdate"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="flex space-x-3">
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("common.label") }}</span
                    >
                  </div>
                  <UIInput
                    id="fieldTitle"
                    class="standard-input-ghl"
                    :model-value="getLabel"
                    @update:model-value="updateLabel"
                    @blur="store.addToHistory()"
                  >
                  </UIInput>
                </div>
                <div
                  v-if="!['TEXTBOX_LIST', 'SIGNATURE'].includes(selectedElementStore?.dataType)"
                  class="form-group"
                >
                  <div class="flex space-x-3">
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("common.placeholder") }}</span
                    >
                  </div>
                  <UIInput
                    id="fieldPlaceholder"
                    class="standard-input-ghl"
                    :model-value="getPlaceholder"
                    @update:model-value="updatePlaceholder"
                    @blur="store.addToHistory()"
                  ></UIInput>
                </div>
                <div
                  v-if="
                    !['image', 'header', 'button', 'terms_and_conditions'].includes(
                      selectedElementStore.tag
                    )
                  "
                  class="form-group"
                >
                  <div class="flex space-x-3">
                    <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
                      t("common.shortLabel")
                    }}</span>
                  </div>
                  <UIInput
                    id="shortLabel"
                    class="standard-input-ghl"
                    :model-value="getShortLabel"
                    @update:model-value="updateShortLabel"
                    @blur="store.addToHistory()"
                  >
                  </UIInput>
                </div>
                <div
                  v-if="
                    !['TEXTBOX_LIST', 'MULTIPLE_OPTIONS', 'FILE_UPLOAD', 'SIGNATURE'].includes(
                      selectedElementStore?.dataType
                    )
                  "
                  class="form-group"
                >
                  <div class="flex space-x-3">
                    <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
                      {{ t("common.queryKey") }}
                      <UITooltip trigger="hover" placement="top">
                        <template #trigger>
                          <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
                        </template>
                        {{ t("common.querykeyTooltipMessage") }}
                      </UITooltip>
                    </div>
                  </div>
                  <UIInput
                    id="fieldQueryKey"
                    class="standard-input-ghl"
                    :model-value="getHiddenQuery"
                    @update:model-value="updateHiddenQuery"
                    @blur="store.addToHistory()"
                  ></UIInput>
                </div>
                <div class="form-check-req-hid">
                  <UICheckbox
                    id="fieldRequired"
                    :disabled="isRequiredFieldDisable"
                    :checked="getRequired"
                    @update:checked="updateRequired"
                    >{{ t("common.required") }}</UICheckbox
                  >
                  <UICheckbox
                    v-if="
                      ![
                        'TEXTBOX_LIST',
                        'SINGLE_OPTIONS',
                        'MULTIPLE_OPTIONS',
                        'FILE_UPLOAD',
                        'SIGNATURE',
                      ].includes(selectedElementStore?.dataType)
                    "
                    id="fieldCheckbox"
                    :disabled="isHiddenFieldDisable"
                    :checked="getHidden"
                    @update:checked="updateHidden"
                    >{{ t("common.hidden") }}</UICheckbox
                  >
                </div>
                <div v-if="selectedElementStore.hidden" class="form-group hidden-field">
                  <div class="flex space-x-3">
                    <span
                      class="hl-text-input-label mb-1 block text-sm font-medium text-gray-700"
                      >{{ t("common.hiddenValue") }}</span
                    >
                  </div>
                  <UIInput
                    id="fieldHiddenvalue"
                    class="standard-input-ghl"
                    :model-value="getHiddenValue"
                    @update:model-value="updateHiddenValue"
                    @blur="store.addToHistory()"
                  ></UIInput>
                </div>
                <div>
                  <UICheckbox
                    v-if="
                      selectedElementStore.tag == 'phone' ||
                      selectedElementStore.dataType == 'PHONE'
                    "
                    id="fieldCheckbox"
                    :checked="getEnableCountryPicker"
                    @update:checked="updateEnableCountryPicker"
                    >{{ t("common.enableCountryPicker") }}</UICheckbox
                  >
                </div>
                <div
                  v-if="
                    ['RADIO', 'CHECKBOX', 'SINGLE_OPTIONS'].includes(selectedElementStore.dataType)
                  "
                >
                  <Card id="logic-card" header="Logic">
                    <ol class="pl-4">
                      <li v-for="(option, index) in pickupFieldOptions" :key="option">
                        {{ option.label ? option.label : option }}
                        <div class="my-2">
                          <UISelect
                            :id="'radio' + index"
                            :value="fieldRadioActionModel(index)"
                            :options="radioOption"
                            class="standard-input-ghl"
                            :disabled="
                              !(selectedElementStore?.customEdited || !selectedElementStore?.custom)
                            "
                            @update:value="(value) => radioOptionSelection(value, option, index)"
                            @change="store.addToHistory()"
                            >data</UISelect
                          >
                        </div>
                      </li>
                    </ol>
                  </Card>
                </div>
                <div v-if="selectedElementStore?.model === 'opportunity'">
                  <UIDivider />
                  <div class="flex justify-center">
                    <UIButton
                      id="edit-opportunity-settings"
                      type="tertiary"
                      @click="store.showOpportunitiesModal = true"
                    >
                      <Edit01Icon class="mr-2 h-4 w-4" />
                      {{ t("common.editOpportunitySettings") }}
                    </UIButton>
                  </div>
                  <p v-if="!store.opportunitySettings" class="pt-6">
                    <UIAlert id="warning-message" type="warning">
                      <template #content>
                        {{ t("common.opportunitySettingsWarning") }}
                      </template>
                    </UIAlert>
                  </p>
                </div>
                <EditOpportunitySettingsModal
                  :show="store.showOpportunitiesModal"
                  @update:success="saveOpportunitySettings"
                  @update:cancel="store.showOpportunitiesModal = false"
                />
                <div v-if="selectedElementStore?.tag === 'email' && !selectedElementStore?.hidden">
                  <div class="mt-8 flex justify-between">
                    <span class="hl-text-input-label mb-1 block text-gray-700">
                      {{ t("common.validateEmailHeader") }}
                    </span>
                    <UICheckbox
                      id="email-checkbox"
                      v-model:checked="enableValidateEmailModel"
                      @update:checked="store.addToHistory"
                    />
                  </div>
                  <div class="text-xs text-gray-700">
                    {{ t("common.validateEmailDescription") }}
                  </div>
                </div>
                <div
                  v-if="
                    (selectedElementStore?.tag === 'phone' ||
                      selectedElementStore?.dataType === 'PHONE') &&
                    !selectedElementStore?.hidden
                  "
                >
                  <div class="mt-8 flex justify-between">
                    <span class="hl-text-input-label mb-1 block text-gray-700">
                      {{ t("common.validatePhoneHeader") }}
                    </span>
                    <UICheckbox
                      id="phone-checkbox"
                      v-model:checked="enableValidatePhoneModel"
                      @update:checked="store.addToHistory"
                    />
                  </div>
                  <div class="mb-4 text-xs text-gray-700">
                    {{ t("common.validatePhoneDescription") }}
                  </div>
                  <UIAlert id="warning-message" type="warning">
                    <template #content>
                      {{ t("common.validatePhoneWarning") }}
                    </template>
                  </UIAlert>
                </div>
              </div>
            </UICollapseItem>
            <UICollapseItem
              v-if="!selectedElementStore?.standard && selectedElement.index"
              id="advanced-settings"
              title="Advanced Settings"
              name="Advanced Settings"
            >
              <div>
                <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
                  Custom Field Name
                  <UITooltip trigger="hover" placement="top">
                    <template #trigger>
                      <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
                    </template>
                    This field name is unique for each custom field.
                  </UITooltip>
                </div>
                <UIInput
                  id="custom-field-label"
                  class="standard-input-ghl"
                  :model-value="
                    selectedElementStore?.customFieldLabel ||
                    selectedElementStore?.name ||
                    selectedElementStore?.label
                  "
                  :disabled="selectedElementStore?.customEdited || !selectedElementStore?.custom"
                  @update:model-value="updateLabel"
                  @blur="store.addToHistory()"
                />
                <div class="switch-handle mb-1 mt-6 block text-sm font-medium text-gray-700">
                  Unique Key
                  <UITooltip trigger="hover" placement="top">
                    <template #trigger>
                      <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
                    </template>
                    This key is unique for each custom field.
                  </UITooltip>
                </div>
                <UIInput
                  id="uniqueKey"
                  class="standard-input-ghl"
                  :model-value="getUniqueKey"
                  :disabled="
                    selectedElementStore?.customEdited ||
                    !selectedElementStore?.custom ||
                    selectedElementStore?.dataType === 'SCORE'
                  "
                  :placeholder="uniqueKeyPlaceholder"
                  @update:model-value="updateUniqueKey"
                  @blur="store.addToHistory()"
                />
              </div>
            </UICollapseItem>
          </UICollapse>
        </UITabPane>
        <UITabPane
          name="Options"
          :tab="
            selectedElementStore?.dataType === 'FILE_UPLOAD'
              ? t('common.uploadOptions')
              : selectedElementStore?.dataType === 'SCORE'
              ? t('common.calculations')
              : t('common.options')
          "
          ><ListEditor
            v-if="selectedElementStore?.dataType === 'TEXTBOX_LIST'"
            :picklist-options="selectedElementStore?.picklistOptions"
            :custom-field-created="
              selectedElementStore?.customEdited || !selectedElementStore?.custom
            "
            @update:picklist-options="updatePicklistOptions"
          />
          <DropdownEditor
            v-if="
              ['SINGLE_OPTIONS', 'MULTIPLE_OPTIONS', 'CHECKBOX'].includes(
                selectedElementStore?.dataType
              )
            "
            :options="
              selectedElementStore?.enableCalculations
                ? selectedElementStore?.calculatedOptions
                : selectedElementStore?.picklistOptions
            "
            :enable-calculations="selectedElementStore?.enableCalculations"
            :custom-field-created="
              selectedElementStore?.customEdited || !selectedElementStore?.custom
            "
            @update:options="updatePicklistOptions"
          />
          <RadioSelectEditor
            v-if="selectedElementStore?.dataType === 'RADIO'"
            :options="
              selectedElementStore?.enableCalculations
                ? selectedElementStore?.calculatedOptions
                : selectedElementStore?.picklistOptions
            "
            :enable-calculations="selectedElementStore?.enableCalculations"
            :custom-field-created="
              selectedElementStore?.customEdited || !selectedElementStore?.custom
            "
            @update:options="updatePicklistOptions"
          />
          <ScoreEditor
            v-if="selectedElementStore?.dataType === 'SCORE'"
            :calculation="selectedElementStore?.calculation"
            @update:calculation="updateCalculation"
          />
          <FileUploadEditor
            v-if="selectedElementStore?.dataType === 'FILE_UPLOAD'"
            :options="selectedElementStore?.picklistOptions || ['all']"
            @update:options="updatePicklistOptions"
          />
          <div v-if="selectedElementStore?.dataType === 'FILE_UPLOAD'">
            <div class="my-4 flex items-center justify-between">
              <div class="my-auto text-sm font-normal text-gray-700">Allow Multiple Files</div>
              <UISwitch v-model:value="selectedElementStore.isMultipleFile" size="small" />
            </div>
            <div v-if="selectedElementStore.isMultipleFile">
              <div class="mb-2 mt-4 text-sm font-normal text-gray-700">Max File Limit</div>
              <UIInput id="max-file-limit" v-model:value="selectedElementStore.maxFileSize" />
            </div>
          </div>
          <div
            v-if="
              ['SINGLE_OPTIONS', 'MULTIPLE_OPTIONS', 'RADIO', 'CHECKBOX'].includes(
                selectedElementStore?.dataType
              ) && selectedElementStore?.model !== 'opportunity'
            "
            class="my-4 flex items-center justify-between"
          >
            <div class="my-auto block text-sm font-medium text-gray-700">
              {{ t("common.enableCalculations") }}
            </div>
            <UISwitch
              v-model:value="selectedElementStore.enableCalculations"
              @update:value="updateEnableCalculations"
            />
          </div>
          <div
            v-if="['RADIO', 'CHECKBOX'].includes(selectedElementStore?.dataType)"
            class="form-group mt-4"
          >
            <div class="flex justify-between pb-2">
              <div class="switch-handle mb-1 block text-sm font-medium text-gray-700">
                Spread to Columns
              </div>
              <UISwitch :value="getSpreadColumns" @update:value="updateSpreadColumns"></UISwitch>
            </div>

            <UIInputGroup v-if="getSpreadColumns">
              <UIInputNumber
                id="fieldQueryKey"
                size="large"
                class="w-100"
                :min="1"
                :max="10"
                :default-value="getDefaultNumberOfColumns"
                :show-button="false"
                :model-value="getNumberOfColumns"
                @update:model-value="updateNumberOfColumns"
                @blur="store.addToHistory()"
              ></UIInputNumber
              ><UIInputGroupLabel>Cols</UIInputGroupLabel>
            </UIInputGroup>
          </div>
          <UIAlert
            v-if="
              ['TEXTBOX_LIST', 'SINGLE_OPTIONS', 'MULTIPLE_OPTIONS', 'RADIO', 'CHECKBOX'].includes(
                selectedElementStore?.dataType
              ) &&
              (selectedElementStore?.customEdited || !selectedElementStore?.custom)
            "
            id="warning-message"
            type="warning"
          >
            <template #content>
              {{ t("common.swapWarningMessageSurvey") }}
            </template>
          </UIAlert>
        </UITabPane>
      </UITabs>
    </div>
  </div>
</template>

<style>
.field-name-sidebar {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center !important;
  margin-bottom: 1rem !important;
}

#custom-sidebar-form {
  font-size: 14px;
}

.standard-input-ghl {
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.query-key {
  display: flex;
  align-items: baseline;
}

.form-group {
  margin-bottom: 1rem;
}

.hidden-field {
  margin-top: 1rem;
}

.form-check-req-hid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.arrow-button {
  margin-bottom: 0px;
  cursor: pointer;
}

.alignment {
  display: flex;
  margin-bottom: 10px;
}

.alignment-div {
  margin-right: 10px;
}

.custom-icons {
  color: #38a0db;
  margin: 0 10px;
  cursor: pointer;
}

#hide-tabs .n-tabs-nav-scroll-wrapper {
  display: none !important;
}

#logic-card > div.p-3 {
  padding-bottom: 1px !important;
}
</style>
