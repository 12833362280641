<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { XCircleIcon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton } from "@gohighlevel/ghl-ui";
import { ref } from "vue";
import { CONSTANTS } from "../../util/constants";
import AlertModal from "./AlertModal.vue";
import CheckboxField from "./CheckboxField.vue";
import FileUploadField from "./FileUploadField.vue";
import LargeTextField from "./LargeTextField.vue";
import MonetaryField from "./MonetaryField.vue";
import NumericalField from "./NumericalField.vue";
import RadioField from "./RadioField.vue";
import SignatureField from "./SignatureField.vue";
import SingleOptionsField from "./SingleOptionsField.vue";
import TextField from "./TextField.vue";
import TextboxListField from "./TextboxListField.vue";

const store = useAppStore();

defineProps({
  element: {
    type: Object,
    default() {
      return {};
    },
  },
  fieldStyle: Object,
  index: Number,
  slideIndex: {
    type: Number,
    default: undefined,
  },
  currentElement: Object,
  isForm: {
    type: Boolean,
    default: true,
  },
  formLabelVisible: {
    type: Boolean,
    default: true,
  },
});

const showDeleteModal = ref(false);
const deleteIndex = ref(0);

const successCallbackDeleteUpdate = () => {
  store.builderConfig.conditionalLogic = store.builderConfig.conditionalLogic.filter((item) => {
    item.conditions = item?.conditions?.filter(
      (condition) =>
        condition.selectedField !== store.elements[deleteIndex.value].hiddenFieldQueryKey
    );
    return item.conditions?.length > 0;
  });
  store.elements.splice(deleteIndex.value, 1);
  showDeleteModal.value = false;
};

const cancelCallbackDeleteUpdate = () => {
  showDeleteModal.value = false;
};

const removeField = (tag, index, slideIndex) => {
  store.anyUnsavedChanges = true;
  let opportunityCustomFields = [];
  if (store.surveyId) {
    store.slides[slideIndex].slideData.splice(index, 1);
    store.isAnyElementActive = false;
    store.currentActiveIndex = [undefined, undefined];
    opportunityCustomFields = store.slides.reduce((acc, slide) => {
      const filteredFields = slide.slideData.filter((field) => field.model === "opportunity");
      return acc.concat(filteredFields);
    }, []);
  } else {
    if (
      store.builderConfig.conditionalLogic?.some((item) =>
        item.conditions?.some(
          (condition) => condition.selectedField === store.elements[index].hiddenFieldQueryKey
        )
      )
    ) {
      showDeleteModal.value = true;
      deleteIndex.value = index;
    } else {
      store.elements.splice(index, 1);
    }
    store.currentCustomSidebarPreview = undefined;
    opportunityCustomFields = store.elements.filter((field) => field.model === "opportunity");
  }
  if (opportunityCustomFields?.length === 0) {
    store.opportunitySettings = null;
  }
  store.opportunitySettings.payload.customFields = opportunityCustomFields.map(
    ({ Id, fieldKey }) => ({
      id: Id,
      key: fieldKey.replace("opportunity.", ""),
    })
  );

  store.addToHistory();
};

const isActiveElement = (index, slideIndex) => {
  if (store.formId) {
    return store.currentCustomSidebarPreview === index;
  }
  return store.currentActiveIndex[0] === slideIndex && store.currentActiveIndex[1] === index;
};

const labelAlignment = () => {
  if (store.builderConfig.layout === CONSTANTS.ONE_COLUMN) {
    const validAlign = ["left", "right"].includes(store.fieldStyle.labelAlignment);
    return {
      width: validAlign ? store.fieldStyle.labelWidth + "px" : "",
      display: validAlign ? "inline-block" : "block",
      float: validAlign ? "left" : "",
      textAlign: validAlign ? store.fieldStyle.labelAlignment : "",
      padding: validAlign ? "0 10px 0 0" : "",
    };
  }
};

const getInputCSSList = (): Array<String> => {
  return store.builderConfig.inputStyleType === "line" ? ["line-form-style"] : [];
};

const getStyle = () => {
  return {
    fontSize: store.fieldStyle.placeholderFontSize + "px",
    width: "100%",
  };
};
const getEmailPhoneValidateClass = (fieldWidthPercentage: number) => {
  return [CONSTANTS.TWO_COLUMN, CONSTANTS.SINGLE_LINE].includes(store.builderConfig.layout) &&
    fieldWidthPercentage <= 50
    ? "block space-y-3"
    : "flex";
};
</script>

<template>
  <div class="menu-item">
    <div class="form-builder--item">
      <label
        v-if="!isForm && element?.description !== undefined"
        style="display: block; overflow-wrap: break-word"
        v-html="element.description"
      ></label>
      <div style="display: inline-flex" :style="labelAlignment()">
        <label v-if="formLabelVisible"
          >{{ element.label }} <span v-if="element.required">*</span></label
        >
        <div v-if="element.hidden">
          <small style="background: #e1e1e1; padding: 2px 5px; border-radius: 2px; margin-left: 5px"
            >Hidden</small
          >
        </div>
      </div>
      <TextField
        v-if="element.dataType === 'TEXT' || element.dataType === 'SCORE'"
        :element="element"
        :placeholder="element.placeholder"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
        :readonly="true"
      />
      <LargeTextField
        v-if="element.dataType === 'LARGE_TEXT'"
        :element="element"
        :placeholder="element.placeholder"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
        :readonly="true"
      />
      <TextboxListField
        v-if="element.dataType === 'TEXTBOX_LIST'"
        :placeholder="element.placeholder"
        :picklist-options="element.picklistOptions"
        :picklist-options-image="element.picklistOptionsImages"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
        :readonly="true"
        :element="element"
      />
      <CheckboxField
        v-if="element.dataType === 'CHECKBOX'"
        :element="element"
        :placeholder="element.placeholder"
        :picklist-options="element.picklistOptions"
        :layout="store?.builderConfig?.layout"
        :style-object="getStyle()"
        :readonly="true"
        :field="element"
      />
      <RadioField
        v-if="element.dataType === 'RADIO'"
        :field="element"
        :placeholder="element.placeholder"
        :picklist-options="element.picklistOptions"
        :picklist-options-image="element.picklistOptionsImage"
        :style-object="getStyle()"
        :layout="store?.builderConfig?.layout"
        :disabled="true"
      />
      <NumericalField
        v-if="element.dataType === 'NUMERICAL'"
        :element="element"
        :placeholder="element.placeholder"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
        :readonly="true"
      />
      <div v-if="element.dataType === 'PHONE'" class="flex flex-col">
        <div
          class="gap-x-3"
          :class="[getEmailPhoneValidateClass(element.fieldWidthPercentage)]"
          :style="getStyle()"
        >
          <input
            :placeholder="element.placeholder"
            class="form-control"
            :class="getInputCSSList()"
            :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
            :readonly="true"
          />
          <UIButton
            v-if="element?.enableValidatePhone && !element.hidden"
            id="verify-phone"
            class="pointer-events-none h-auto"
            :style="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
            >Verify Phone</UIButton
          >
        </div>
      </div>

      <MonetaryField
        v-if="element.dataType === 'MONETORY'"
        :element="element"
        :placeholder="element.placeholder"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
        :style-input="{ fontSize: store.fieldStyle.placeholderFontSize + 'px' }"
        :readonly="true"
      />
      <SingleOptionsField
        v-if="element.dataType === 'SINGLE_OPTIONS'"
        :element="element"
        :placeholder="element.placeholder"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
      />
      <SingleOptionsField
        v-if="element.dataType === 'MULTIPLE_OPTIONS'"
        :element="element"
        :placeholder="element.placeholder"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
      />
      <TextField
        v-if="element.dataType === 'DATE'"
        :element="element"
        :placeholder="element.placeholder"
        :class-list="getInputCSSList()"
        :style-object="getStyle()"
        :readonly="true"
      />
      <FileUploadField
        v-if="element.dataType === 'FILE_UPLOAD'"
        :placeholder="element.placeholder"
        :picklist-options="element.picklistOptions"
        :field="element"
      />
      <SignatureField
        v-if="element.dataType === 'SIGNATURE'"
        :element="element"
        :style-object="getStyle()"
      />
    </div>
    <a
      v-if="isActiveElement(index, slideIndex)"
      class="close-icon"
      @mousedown="removeField(element.tag, index, slideIndex)"
    >
      <XCircleIcon class="h-5 w-5"></XCircleIcon>
    </a>
  </div>
  <AlertModal
    v-if="showDeleteModal"
    :description="'Are you sure you want to delete this custom field? The condition associated with this custom field will also be removed.'"
    :title="'Delete Field'"
    :positive-text="'Delete'"
    :negative-text="'Cancel'"
    :show-modal-popup="showDeleteModal"
    header-type="error"
    footer-type="error"
    @pop:change="cancelCallbackDeleteUpdate"
    @pop:positive="successCallbackDeleteUpdate"
    @pop:negative="cancelCallbackDeleteUpdate"
  />
</template>

<style scoped>
.form-control {
  border-radius: 0.3125rem;
  padding: 15px 20px;
  font-size: 0.875rem;
}
.form-control:disabled,
.form-control [readonly] {
  background-color: #e9ecef !important;
  opacity: 1;
}
.vs__search,
.vs__search:focus {
  padding: 12px 18px !important;
}
.form-builder--item .v-select {
  width: 100%;
  background: #f3f8fb;
  border-radius: 4px !important;
  height: auto;
  border: transparent;
}
.form-builder--item .v-select .vs__dropdown-toggle {
  height: 50px;
}
.add-custom-opt {
  padding: 3px;
  font-size: 14px;
}

.close-icon {
  position: absolute;
  right: -7px;
  top: -10px;
  opacity: 1;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  font-size: 10px;
  background: #f5f5f5;
  color: #2a3135;
  border-radius: 50%;
  height: 20px;
  cursor: pointer;
}
.form-builder--item {
  border-radius: 4px;
  border: 1px solid transparent;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  position: relative;
}
/* .form-builder--item:hover {
  border: 2px dashed rgba(175, 184, 188, 0.5);
}
.form-builder--item:hover .form-builder--actions {
  opacity: 1;
  visibility: visible;
} */
.form-builder--item h1,
.form-builder--item h2,
.form-builder--item h3,
.form-builder--item h4,
.form-builder--item h5,
.form-builder--item h6 {
  margin-bottom: 0;
}
.form-builder--item label {
  font-size: 0.875rem;
  color: #2a3135;
  margin-bottom: 0.5;
}
</style>
