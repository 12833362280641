{
  "common": {
    "addCustomFields": "Ajouter des champs personnalisés",
    "addElement": "Ajouter un élément",
    "addUrlLike": "Ajoutez des URL valides comme",
    "address": "Adresse",
    "agencyBranding": "Image de marque de l'agence",
    "alert": "Alerte",
    "alignment": "Alignement",
    "altText": "Texte alternatif",
    "back": "Dos",
    "background": "Arrière-plan",
    "backgroundImage": "Image de fond",
    "backgroundImageTooltipMessage": "L'image d'arrière-plan est destinée à couvrir la page entière, fournissant ainsi une toile de fond visuellement attrayante pour le formulaire. \nPour afficher l'image d'arrière-plan, cliquez sur l'option Aperçu.",
    "blur": "Se brouiller",
    "border": "Frontière",
    "borderType": "Type de bordure",
    "bottom": "Bas",
    "buttonStyle": "Style de bouton",
    "cancel": "Annuler",
    "cancelKeep": "Annuler, conserver",
    "color": "Couleur",
    "conditionalLogicAlertOnSubmit": "La logique conditionnelle aura priorité sur le message/redirection ici",
    "contactInfo": "Informations de contact",
    "cornerRadius": "Rayon de coin",
    "customCSS": "CSS personnalisé",
    "customCSSTooltip": "Le CSS personnalisé a priorité sur le style du formulaire",
    "customFieldSearch": "Rechercher par nom ou par type",
    "customFields": "Les champs personnalisés",
    "customized": "Personnalisé",
    "delete": "Supprimer",
    "doNotAddRelativePath": "N'ajoutez pas de chemins relatifs comme",
    "doNotShowMessage": "Ne plus montrer",
    "done": "Fait",
    "editHTML": "Modifier le code HTML",
    "embed": "Intégrer",
    "enableCountryPicker": "Activer le sélecteur de pays",
    "enterSourceValue": "Entrez la valeur source",
    "facebookPixelEvents": "Événements de pixels Facebook",
    "facebookPixelID": "Identifiant de pixel Facebook",
    "facebookPixelIDMessage": "Ignorez ce champ si vous prévoyez d'utiliser ce formulaire/enquête dans un entonnoir",
    "fieldDescription": "Champ Description",
    "fieldStyle": "Style de champ",
    "fieldTitle": "Titre du champ",
    "fontColor": "Couleur de la police",
    "fontFamily": "Famille de polices",
    "fontFamilyTooltip": "Builder prend en charge tous les types de polices si vous ne le voyez pas dans la liste. \nVeuillez le rechercher dans la liste déroulante",
    "fullWidth": "Pleine largeur",
    "headerImage": "Image d'en-tête",
    "headerImageTooltipMessage": "L'image d'en-tête est conçue pour couvrir toute la largeur du formulaire et reste positionnée en haut du formulaire. \nPour afficher l'image d'en-tête, cliquez sur l'option Aperçu.",
    "hidden": "Caché",
    "hiddenValue": "Valeur cachée",
    "horizontal": "Horizontal",
    "htmlEditorBodyMessage": "Ajoutez votre code ici",
    "htmlEditorHeader": "JAVASCRIPT PERSONNALISÉ / HTML",
    "htmlMessage": "Le HTML personnalisé va ici",
    "iframeEmbed": "Ifrmae Intégrer",
    "imageHeight": "hauteur de l'image",
    "imageUploadMessage": "Cliquez ou faites glisser un fichier image vers cette zone pour le télécharger",
    "imageWidth": "Largeur de l'image",
    "inputStyle": "Style de saisie",
    "integrate": "Intégrer",
    "label": "Étiquette",
    "layout": "Mise en page",
    "left": "Gauche",
    "link": "Lien",
    "logic": "Logique",
    "miscellaneous": "Divers",
    "onSubmit": "Lors de la soumission",
    "onpageView": "Sur la page",
    "options": "Possibilités",
    "or": "ou",
    "padding": "Rembourrage",
    "personalInfo": "Informations personnelles",
    "placeholder": "Espace réservé",
    "placeholderFacebookPixelIdInput": "Entrez l'identifiant de pixel Facebook",
    "pleaseInput": "Veuillez saisir",
    "preview": "Aperçu",
    "proceed": "Procéder",
    "queryKey": "Clé de requête",
    "querykeyTooltipMessage": "La clé de requête qui peut être utilisée comme paramètre d'URL pour remplir ce champ",
    "quickAdd": "Ajout rapide",
    "required": "Requis",
    "right": "Droite",
    "save": "Sauvegarder",
    "saving": "Économie",
    "shadow": "Ombre",
    "shortLabel": "Étiquette courte",
    "showLabel": "Afficher l'étiquette",
    "size": "Taille",
    "spread": "Propagé",
    "stickyContact": "Contact collant",
    "stickyContactMessage": "Le contact collant remplira automatiquement les informations de contact précédemment saisies afin que vous n'ayez pas à les saisir deux fois.",
    "styles": "modes",
    "subText": "Sous-texte",
    "submit": "Soumettre",
    "text": "Texte",
    "textStyle": "Style de texte",
    "themes": {
      "alertThemeMessage": "Changer de thème entraînera la perte de toutes les modifications de style actuelles.",
      "removeTheme": "Supprimer le thème",
      "themes": "Thèmes",
      "useTheme": "Utiliser le thème"
    },
    "top": "Haut",
    "useGDPRCompliantFont": "Utiliser une police conforme au RGPD",
    "vertical": "Verticale",
    "weight": "Poids",
    "width": "Largeur",
    "yesSave": "Oui, enregistrer",
    "box": "Boîte",
    "line": "Doubler",
    "message": "Message",
    "openUrl": "Ouvrir le lien",
    "borderTypeList": {
      "dashed": "En pointillés",
      "dotted": "Pointé",
      "none": "Aucun",
      "solid": "Solide"
    },
    "button": "Bouton",
    "enableTimezone": "Activer le fuseau horaire",
    "emailNotification": "Notification par courrier électronique",
    "emailNotificationSubheader": "Recevez un e-mail lorsque quelqu'un remplit votre formulaire",
    "subject": "Sujet",
    "emailTo": "Envoyer à)",
    "senderName": "Nom de l'expéditeur",
    "senderNameWarning": "Votre (nom du sous-compte) sera ajouté s'il est laissé vide",
    "emailTooltip": "La première adresse e-mail est désignée pour le champ « À ». \nToutes les adresses e-mail ultérieures seront incluses dans le champ « Cc ».",
    "emailError": "Au moins une adresse e-mail est requise",
    "clear": "Clair",
    "showNotificationsSettings": "Afficher les paramètres de notifications",
    "emailServicePaid": "Cette fonctionnalité est un service payant et entraînera des frais en fonction de votre utilisation.",
    "integration": "L'intégration",
    "payment": {
      "connect": "Connecter",
      "connected": "Connecté",
      "footerInfo": "Informations sur le pied de page",
      "includeOtherAmount": "Inclure un autre montant",
      "live": "En direct",
      "paymentFields": "Champs de paiement",
      "selectCurrency": "Sélectionnez la devise",
      "status": "Statut",
      "suggestAnAmount": "Proposer un montant",
      "test": "Test",
      "liveModeOn": "Mode direct",
      "customAmount": "Montant personnalisé",
      "paymentType": "Type de paiement",
      "product": {
        "addProduct": "Ajouter un produit",
        "clickHere": "Cliquez ici",
        "description": "Description",
        "editThisProduct": "Pour modifier ce produit",
        "image": "Image",
        "layoutSettings": "Paramètres de mise en page",
        "maxProductError": "Un maximum de 20 produits sont autorisés dans un formulaire",
        "productSelectPlaceholder": "Veuillez sélectionner un produit",
        "productSettings": "Paramètres du produit",
        "selectProduct": "Sélectionner un produit",
        "show": "Montrer",
        "singleColumn": "Seule colonne",
        "threeColumn": "Trois colonnes",
        "twoColumn": "Deux colonnes",
        "orderConfirmationWarning": "Une page de confirmation de commande s'affichera une fois le paiement réussi",
        "orderConfirmation": "Confirmation de commande",
        "createProduct": "Pour créer un produit",
        "quantity": "Quantité"
      },
      "sellProducts": "Vendez des produits",
      "generalSettings": "Champs généraux",
      "tagActiveBGColor": "Couleur de l'étiquette active",
      "collectPayment": "Collecter le paiement",
      "payments": "Paiements",
      "coupons": {
        "couponAlignment": "Alignement des coupons",
        "coupons": "Coupons",
        "enableCoupon": "Activer le coupon",
        "fullWidth": "Pleine largeur",
        "left": "Gauche",
        "right": "Droite",
        "tooltipMessage": "Définir la position de la saisie du coupon parmi les types ci-dessous"
      }
    },
    "autoResponder": "Répondeur automatique",
    "autoResponderSubheader": "Envoyer un e-mail automatisé à la personne qui soumet le formulaire (l'e-mail comprend une copie des informations saisies dans le formulaire)",
    "replyToEmail": "Répondre à un e-mail",
    "open": "Ouvrir",
    "won": "Gagné",
    "lost": "Perdu",
    "abandon": "Abandonner",
    "selectAPipeline": "Sélectionnez un pipeline",
    "stage": "Scène",
    "status": "Statut",
    "opportunityValue": "Valeur d'opportunité",
    "createNewPipeline": "Créer un nouveau pipeline",
    "editOpportunitySettings": "Modifier les paramètres de l'opportunité",
    "contact": "Contact",
    "opportunity": "Opportunité",
    "add": "Ajouter",
    "more": "Plus",
    "opportunityHeading": "Où souhaitez-vous que votre opportunité soit mise à jour ?",
    "date": {
      "dateFormat": "Format de date",
      "dateFormatTooltipMessage": "Sélectionnez un format de date. \nD signifiait jour, M pour mois et Y pour année",
      "dateSeparator": "Séparateur de dates",
      "dateSeparatorTooltipMessage": "Sélectionnez un séparateur qui séparera le jour, le mois et l'année dans le format sélectionné dans Format de date",
      "dateSettings": "Paramètres de dates",
      "disablePicker": "Désactiver le sélecteur"
    },
    "restoreVersion": "Restaurer la version",
    "versionHistory": "Historique des versions",
    "errorMessage": "Erreur, réessayez",
    "version": "Version:",
    "currentVersion": "Version actuelle",
    "conditionalLogicAlertOnShowHide": "La logique conditionnelle aura priorité sur les paramètres cachés ici",
    "validateEmailHeader": "Email validé",
    "validateEmailDescription": "Le bouton deviendra visible une fois que l'utilisateur aura saisi son e-mail. \nCette fonctionnalité est conçue pour vous aider à générer des prospects de qualité.",
    "validatePhoneHeader": "Valider le téléphone",
    "validatePhoneDescription": "Le bouton deviendra visible une fois que l’utilisateur entrera dans son téléphone. \nCette fonctionnalité est conçue pour vous aider à générer des prospects de qualité.",
    "validatePhoneWarning": "Pour activer la vérification SMS OTP, veuillez vous assurer que votre numéro de téléphone est configuré dans vos paramètres de localisation.",
    "style": {
      "oneColumn": "Une colonne",
      "singleLine": "Une seule ligne",
      "twoColumn": "Deux colonnes",
      "fieldWidth": "Largeur du champ",
      "formLayoutType": "Type de formulaire",
      "fieldSpacing": "Espacement des champs",
      "advanceSettings": "Paramètres avancés",
      "borderColor": "Couleur de la bordure",
      "borderStyle": "Style de bordure",
      "borderWidth": "Largeur de la bordure",
      "color": "couleur",
      "colorsAndBackground": "Couleurs",
      "fontColor": "Couleur de la police",
      "fontSize": "Taille de police",
      "fontWeight": "Poids de la police",
      "inputBackground": "Arrière-plan de saisie",
      "inputField": "Champ de saisie",
      "labelColor": "Couleur de l'étiquette",
      "layout": "Mise en page",
      "placeholderColor": "Couleur de l'espace réservé",
      "shortLabelColor": "Couleur de l'étiquette courte",
      "labelAlignment": {
        "labelAlignment": "Alignement des étiquettes",
        "labelWidth": "Largeur de l'étiquette"
      }
    },
    "replyToTooltip": "Personnalisez l'adresse e-mail de réponse par défaut en utilisant {'{'}{'{'}contact.email{'}'}{'}'} pour activer les réponses au contact qui soumet le formulaire.",
    "content": "Contenu",
    "calculations": "Calculs",
    "uploadOptions": "Options de téléchargement",
    "messageStyling": "Style des messages",
    "opportunitySettingsWarning": "Veuillez configurer les paramètres de l'opportunité en cliquant sur le bouton Modifier les paramètres de l'opportunité.",
    "swapWarningMessageSurvey": "Les options de modification ou d'échange auront un impact sur ce champ personnalisé à tous les endroits où le champ est utilisé. \nIl peut également réinitialiser la logique conditionnelle.",
    "swapWarningMessageForm": "Les options de modification ou d'échange auront un impact sur ce champ personnalisé à tous les endroits où le champ est utilisé.",
    "addOption": "Ajouter une option",
    "calculationValue": "Valeur de calcul",
    "enableCalculations": "Activer les calculs",
    "optionLabel": "Étiquette d'option"
  },
  "form": {
    "activateOn": "Activer sur",
    "activationOptions": "Options d'activation",
    "addConditionalLogic": "Ajouter une logique conditionnelle",
    "addFormElement": "Ajouter un élément de formulaire",
    "allowMinimize": "Autoriser la réduction",
    "alwaysActivated": "Toujours activé",
    "alwaysShow": "Montre toujours",
    "conditonalLogic": {
      "addNewCondition": "Ajouter une nouvelle condition",
      "alertConditionDelete": "Êtes-vous sûr de vouloir supprimer cette condition ?",
      "conditionExecutedMessage": "Les conditions seront exécutées selon un ordre descendant.",
      "conditions": "Conditions",
      "deleteCondition": "Supprimer la condition",
      "displayCustomMessage": "Afficher un message personnalisé",
      "disqualifyLead": "Disqualifier le prospect",
      "freeRoamMessage": "Vous êtes libre de vous déplacer sans règles – du moins pour le moment",
      "noExistingCondition": "Aucune condition existante pour l'instant",
      "openBelowUrl": "Ouvrir l'URL ci-dessous",
      "redirectToUrl": "Redirection vers l'URL",
      "showCustomMessage": "Afficher un message personnalisé",
      "showHideFields": "Afficher/masquer les champs",
      "allOperationOptions": {
        "startsWith": "Commence avec",
        "lessThan": "Moins que",
        "greaterThan": "Plus grand que",
        "after": "Après",
        "before": "Avant",
        "contains": "Contient",
        "endsWith": "Se termine par",
        "isEmpty": "Est vide",
        "isEqualTo": "Est égal à",
        "isFilled": "Est rempli",
        "isNotEqualTo": "N'est pas égal à"
      },
      "logic": {
        "if": "Si",
        "or": "Ou",
        "then": "Alors",
        "and": "Et"
      },
      "phoneAlertMessage": "Pour rechercher des numéros de téléphone avec un indicatif de pays, vous devez inclure explicitement le symbole «+» dans votre requête de recherche.",
      "deleteAlertMessage": "Êtes-vous sûr de vouloir supprimer ce champ ? \nLa condition associée à ce champ sera également supprimée.",
      "deleteField": "Supprimer le champ"
    },
    "minimizedTitlePlaceholder": "Titre réduit",
    "copyEmbedCode": "Copier le code d'intégration",
    "deactivateAfterShowing": "Désactiver après affichage",
    "deactivationOptions": "Options de désactivation",
    "deactiveOnceLeadCollected": "Désactiver une fois le prospect collecté",
    "embedlayoutType": "Type de mise en page intégré",
    "embedlayoutTypeSubMessage": "Sélectionnez une mise en page à intégrer dans un entonnoir ou un site Web externe",
    "formElement": "Élément de formulaire",
    "formName": "Nom de forme",
    "formSettings": "Paramètres du formulaire",
    "formStyle": "Style de formulaire",
    "fullScreenMode": "Mode plein écran",
    "fullScreenModetooltip": "L'activer entraînera l'expansion du formulaire sur toute la largeur de son conteneur, s'étirant jusqu'au bord de la zone d'intégration tout en adhérant à la largeur du formulaire.",
    "inline": "En ligne",
    "inlineDescription": "Un formulaire en ligne apparaît avec le contenu de la page en tant qu'élément natif. \nIl ne se superpose pas et ne peut pas être fermé ou réduit.",
    "inlineForm": "Formulaire en ligne",
    "inlineHoverDescription": "Un formulaire en ligne apparaît avec le contenu de la page en tant qu'élément natif. \nIl ne se superpose pas et ne peut pas être fermé ou réduit.",
    "integrateForm": "Intégrer le formulaire",
    "neverDeactivate": "Ne jamais désactiver",
    "onFormSubmission": "Lors de la soumission du formulaire",
    "politeSlideIn": "Encastrable poli",
    "politeSlideInDescription": "Un slide-in poli apparaît en bas de l'écran et disparaît une fois fermé.",
    "politeSlideInHoverDescription": "Apparaît en bas de l'écran et disparaît une fois fermé",
    "popup": "Surgir",
    "popupDescription": "Une fenêtre contextuelle affiche le formulaire sous forme de superposition à l'intérieur d'une boîte lumineuse. \nIl ne peut pas être minimisé mais fermé.",
    "popupHoverDescription": "Une fenêtre contextuelle affiche le formulaire sous forme de superposition à l'intérieur d'une boîte lumineuse. \nIl ne peut pas être minimisé mais fermé.",
    "seconds": "secondes",
    "showAfter": "Afficher après",
    "showOnScrolling": "Afficher au défilement",
    "stickySidebar": "Barre latérale collante",
    "stickySidebarDescription": "Une barre latérale collante apparaîtra sur le côté de l'écran et peut être réduite à un onglet qui colle sur le côté de la fenêtre.",
    "stickySidebarHoverDescription": "Apparaît sur le côté de l'écran et peut être réduit à un onglet qui colle au côté de la fenêtre",
    "thirdPartyScriptAlertMessage": "Les scripts tiers à l'intérieur du bloc HTML peuvent fournir des fonctionnalités puissantes, mais ils comportent également des risques pour la confidentialité, la sécurité, les performances et le comportement des pages. \nPar conséquent, nous vous recommandons de vérifier le contenu de ce script avant de l'enregistrer dans le formulaire.",
    "times": "fois",
    "triggerType": "Type de déclencheur",
    "typeOfBuilder": "Formulaire",
    "visit": "visite",
    "dragYourElementFromLeft": "Faites glisser vos éléments ici depuis la gauche",
    "enableTimezoneForm": "L'activation de cette option capturera les informations de fuseau horaire de la personne soumettant le formulaire.",
    "formNotification": "Formulaire de notification",
    "copyFormLink": "Copier le lien du formulaire",
    "openFormLink": "Ouvrir le lien du formulaire",
    "unsavedAlertMessage": "Le formulaire contient du travail non enregistré. \nÊtes-vous sûr de vouloir quitter et supprimer tout le travail non enregistré ?",
    "style": {
      "formPadding": "Remplissage de formulaire"
    }
  },
  "survey": {
    "addSlide": "Ajouter une diapositive",
    "addSurveyElement": "Ajouter un élément d'enquête",
    "backButton": "Bouton Retour",
    "backButtonTooltipMessage": "Le bouton Retour aidera l'utilisateur à déplacer la diapositive précédente",
    "delete": "Supprimer",
    "disableAnimation": "Désactiver l'animation",
    "disableAnimationTooltipMessage": "L'activer désactivera l'animation de transition de diapositive",
    "disableAutoNavigation": "Désactiver la navigation automatique",
    "disableAutoNavigationTooltipMessage": "Si cette option est activée, l'enquête ne passera pas automatiquement à la diapositive suivante",
    "disqualifyAfterSubmit": "Disqualifier après la soumission",
    "disqualifyImmediately": "Disqualifier immédiatement",
    "editFooter": "Modifier le pied de page",
    "footer": {
      "backgroundFill": "Remplissage d'arrière-plan",
      "buttonType": "Type de bouton",
      "buttons": "Boutons",
      "enableNewFooter": "Activer le nouveau pied de page",
      "enableProgressBar": "Activer la barre de progression",
      "fillComplete": "Remplissage terminé",
      "fillInactive": "Remplir inactif",
      "fillNext": "Remplir (suite)",
      "fillPrevious": "Remplir (précédent)",
      "fillSubmit": "Remplir (Soumettre)",
      "footer": "Bas de page",
      "footerHeight": "Hauteur du pied de page",
      "nextText": "Suivant (texte)",
      "position": "Position",
      "previousText": "Précédent (texte)",
      "stickToSurvey": "S'en tenir à l'enquête",
      "submitText": "Soumettre (texte)",
      "textColor": "Couleur du texte",
      "footerType": "Type de pied de page",
      "stickToCard": "S'en tenir à la carte",
      "stickToPage": "S'en tenir à la page"
    },
    "footerHTML": "Pied de page HTML",
    "footerHTMLMessage": "Vous pouvez écrire du CSS personnalisé (à l'aide de la balise de style) et du HTML dans la même boîte",
    "integrateCopyLinkMessage": "Copiez le lien ci-dessous et partagez-le facilement où vous le souhaitez.",
    "integrateHeader": "Intégrer l'enquête",
    "integrateSubHeader": "Utilisez les options suivantes pour intégrer votre enquête personnalisée à votre site Web",
    "jumpTo": "Sauter à",
    "onSurveySubmission": "Sur la soumission de l'enquête",
    "oneQuestionAtTime": "Une question à la fois",
    "progressBar": "Barre de progression",
    "progressBarTooltipMessage": "Désactiver cette option supprimera la barre de progression de l'enquête",
    "scrollToTop": "Faire défiler vers le haut",
    "scrollToTopTooltipMessage": "Si cette option est activée, l'enquête défilera vers le haut sur la diapositive suivante.",
    "settings": "Paramètres",
    "slideConflictAlertMessage": "Cette diapositive contient plusieurs questions qui ont une logique. \nCela peut provoquer un comportement involontaire",
    "slideDeleteAlertMessage": "Êtes-vous sûr de vouloir supprimer cette diapositive ?",
    "slideDeleteBetweenAlertMessage": "Cela réorganisera les autres diapositives. Êtes-vous sûr de vouloir supprimer cette diapositive ?",
    "slideName": "Nom de la diapositive",
    "slidePosition": "Position de la diapositive",
    "surveyElement": "Élément d'enquête",
    "surveyName": "Nom de l'enquête",
    "surveySettings": "Paramètres d'enquête",
    "survyeStyle": "Style d'enquête",
    "thirdPartyScriptAlertMessage": "Les scripts tiers à l'intérieur du bloc HTML peuvent fournir des fonctionnalités puissantes, mais ils comportent également des risques pour la confidentialité, la sécurité, les performances et le comportement des pages. \nPar conséquent, nous vous recommandons de revoir le contenu de ce script avant de l'enregistrer dans l'enquête.",
    "typeOfBuilder": "Enquête",
    "enableTimezoneSurvey": "L'activation de cette option capturera les informations de fuseau horaire de la personne soumettant l'enquête.",
    "surveyNotfication": "Notification d'enquête",
    "unsavedAlertMessage": "L'enquête comporte du travail non enregistré. \nÊtes-vous sûr de vouloir quitter et supprimer tout le travail non enregistré ?",
    "style": {
      "surveyPadding": "Remplissage d'enquête"
    }
  }
}
