<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import CustomFieldsPreview from "../common/CustomFieldsPreview.vue";
import HLImage from "../common/HLImage.vue";
import StandardFieldPreview from "../common/StandardFieldPreview.vue";
import FormbuilderBranding from "./../common/BuilderBranding.vue";
import { getAllFontsImport, fieldFullWidth } from "@/util/methods";
import { CONSTANTS } from "@/util/constants";
const store = useAppStore();

const { elements } = storeToRefs(store);

const currentElement = ref({ label: "" });
const globalScriptCSSStyle = ref();
const globalScriptCSSLabelHolder = ref();
const globalScriptCSSPlaceHolder = ref();
const globalScriptCSSFieldBGColor = ref();
const globalScriptCSSFieldBorder = ref();
const globalScriptCSSFieldShadow = ref();
const globalScriptCSSFieldWidth = ref();
const globalScriptCSSFieldPadding = ref();
const globalScriptCSSFontHolder = ref();
const globalScriptCSSShortLabelHolder = ref();

const formStyle = computed(() => {
  const style: any = store.formStyle;
  return {
    padding:
      style?.padding?.top +
      "px " +
      style?.padding?.right +
      "px " +
      style?.padding?.bottom +
      "px " +
      style?.padding?.left +
      "px",
    backgroundColor: "#" + style?.background,
    border: style?.border?.border + "px " + style?.border?.style + " #" + style?.border?.color,
    borderRadius: style?.border?.radius + "px",
    boxShadow:
      style?.shadow?.horizontal +
      "px " +
      style?.shadow?.vertical +
      "px " +
      style?.shadow?.blur +
      "px " +
      style?.shadow?.spread +
      "px #" +
      style?.shadow?.color,
    width: (style?.width || 548) + "px",
  };
});

const clickedElement = (e, element, index) => {
  e.stopPropagation();
  store.currentCustomSidebarPreview = index;
  store.isAnyElementActive = true;
  currentElement.value = element;
};

const fieldStyle = computed(() => {
  const fieldStyleStore = store.fieldStyle;
  const style = {
    padding: `${fieldStyleStore?.padding?.top}px ${fieldStyleStore?.padding?.right}px ${fieldStyleStore?.padding?.bottom}px ${fieldStyleStore?.padding?.left}px`,
    backgroundColor: "#" + fieldStyleStore?.bgColor,
    border: `${fieldStyleStore?.border?.border}px ${
      fieldStyleStore?.border?.type === "none" ? "" : fieldStyleStore?.border?.type
    } #${fieldStyleStore?.border?.color}`,
    borderRadius: fieldStyleStore?.border?.radius + "px",
    boxShadow: `${fieldStyleStore?.shadow?.horizontal}px ${fieldStyleStore?.shadow?.vertical}px ${fieldStyleStore?.shadow?.blur}px ${fieldStyleStore?.shadow?.spread}px #${fieldStyleStore?.shadow?.color}`,
    maxWidth: (fieldStyleStore?.width || 516) + "px",
    color: "#" + fieldStyleStore?.labelColor,
    font: `${fieldStyleStore.labelFontSize}px  ${fieldStyleStore.labelFontWeight} ${fieldStyleStore.labelFontFamily}`,
  };
  return style;
});

onMounted(() => {
  const globalScriptCSS = document.createElement("style");
  globalScriptCSS.type = "text/css";

  // Google and bunny font css script
  const globalScriptFontFamily = document.createElement("style");
  globalScriptFontFamily.type = "text/css";
  globalScriptFontFamily.appendChild(document.createTextNode(""));
  globalScriptCSSFontHolder.value = globalScriptFontFamily.childNodes[0];
  document.head.appendChild(globalScriptFontFamily);

  for (let i = 0; i < 9; i++) {
    globalScriptCSS.appendChild(document.createTextNode(""));
  }
  globalScriptCSSStyle.value = globalScriptCSS.childNodes[0];
  //For Label CSS Change
  globalScriptCSSLabelHolder.value = globalScriptCSS.childNodes[1];
  //For Placeholder CSS Change
  globalScriptCSSPlaceHolder.value = globalScriptCSS.childNodes[2];
  globalScriptCSSFieldBGColor.value = globalScriptCSS.childNodes[3];
  globalScriptCSSFieldBorder.value = globalScriptCSS.childNodes[4];
  globalScriptCSSFieldShadow.value = globalScriptCSS.childNodes[5];
  globalScriptCSSFieldWidth.value = globalScriptCSS.childNodes[6];
  globalScriptCSSFieldPadding.value = globalScriptCSS.childNodes[7];
  globalScriptCSSShortLabelHolder.value = globalScriptCSS.childNodes[8];

  document.head.appendChild(globalScriptCSS);
  globalScriptCSSFontHolder.value.textContent = getAllFontsImport(
    store.fontCollection,
    store.builderConfig.isGDPRCompliant
  );
  setGlobalStyleInHTMl();
});

const setGlobalStyleInHTMl = () => {
  const fieldStyle = store.fieldStyle;
  const formStyle = store.formStyle;
  globalScriptCSSLabelHolder.value.textContent = `#_builder-form label {
        color: #${fieldStyle?.labelColor};
        font-size: ${fieldStyle.labelFontSize}px;
        font-weight: ${fieldStyle.labelFontWeight};
        font-family: '${fieldStyle.labelFontFamily}';
        margin-bottom: 0.5rem;
        line-height: 1.8;
      }
      #_builder-form .tnc {
        font-family: '${fieldStyle.labelFontFamily}';
      }
      #_builder-form .product-amount {
        color: #${fieldStyle?.labelColor};
        font-size: ${parseFloat(fieldStyle.labelFontSize) + 4}px;
        font-weight: ${parseFloat(fieldStyle.labelFontWeight) + 200};
        font-family: ${fieldStyle.labelFontFamily};
        line-height: 1.5rem;
      }
      #_builder-form .crossed-amount {
        color: #${fieldStyle?.labelColor};
        font-size: ${parseFloat(fieldStyle.labelFontSize) + 2}px;
        font-weight: ${parseFloat(fieldStyle.labelFontWeight) + 100};
        font-family: ${fieldStyle.labelFontFamily};
        line-height: 1.5rem;
      }
      #_builder-form .product-label {
        color: #${fieldStyle?.labelColor};
        font-size: ${parseFloat(fieldStyle.labelFontSize) + 2}px;
        font-weight: ${parseFloat(fieldStyle.labelFontWeight) + 100};
        font-family: ${fieldStyle.labelFontFamily};
        line-height: 1.575rem;
      }
      #_builder-form .setup-fee {
        color: #${fieldStyle?.labelColor};
        font-size: ${parseFloat(fieldStyle.labelFontSize) - 2}px;
        font-weight: ${parseFloat(fieldStyle.labelFontWeight)};
        font-family: ${fieldStyle.labelFontFamily};
        line-height: 1.575rem;
      }
      #_builder-form .variant-tag {
        color: #${fieldStyle?.labelColor};
        font-size: ${parseFloat(fieldStyle.labelFontSize) - 1}px;
        font-weight: ${parseFloat(fieldStyle.labelFontWeight)};
        font-family: ${fieldStyle.labelFontFamily};
        line-height: 1.5rem;
      }
      `;

  globalScriptCSSPlaceHolder.value.textContent = `#_builder-form ::placeholder, #_builder-form .form-dropdown a, 
      #_builder-form .form-dropdown .dropdown-toggle , #_builder-form .currency-code { 
        color : #${fieldStyle.placeholderColor}; 
        font-family:'${fieldStyle.placeholderFontFamily}'; 
        font-size:${fieldStyle.placeholderFontSize}px; 
        font-weight: ${fieldStyle.placeholderFontWeight};
      }`;
  globalScriptCSSFieldBGColor.value.textContent = `.form-control:disabled, .form-control[readonly] , #_builder-form .form-dropdown, #_builder-form .payment-tag, #_builder-form .quantity-container-counter { background-color : #${fieldStyle.bgColor};}`;
  globalScriptCSSFieldBorder.value.textContent =
    "  .form-control:disabled, .form-control[readonly] , #_builder-form .form-dropdown{ border:" +
    fieldStyle.border.border +
    "px " +
    fieldStyle.border.type +
    " #" +
    fieldStyle.border.color +
    ";border-radius:" +
    fieldStyle.border.radius +
    "px ; }" +
    ` .line-form-style { border-bottom: ${fieldStyle.border.border}px ${fieldStyle.border.type} #${fieldStyle.border.color} !important; background-color: #${fieldStyle.bgColor} !important;}`;
  globalScriptCSSFieldPadding.value.textContent =
    "  .form-control:disabled, .form-control[readonly] , #_builder-form .form-dropdown { padding:" +
    fieldStyle.padding.top +
    "px " +
    fieldStyle.padding.right +
    "px " +
    fieldStyle.padding.bottom +
    "px " +
    fieldStyle.padding.left +
    "px}";
  globalScriptCSSFieldShadow.value.textContent =
    "  .form-control:disabled, .form-control[readonly] , #_builder-form .form-dropdown, #_builder-form .payment-tag #_builder-form .quantity-container-counter { box-shadow:" +
    fieldStyle.shadow.horizontal +
    "px " +
    fieldStyle.shadow.vertical +
    "px " +
    fieldStyle.shadow.blur +
    "px " +
    fieldStyle.shadow.spread +
    "px #" +
    fieldStyle.shadow.color +
    "}";
  globalScriptCSSFieldWidth.value.textContent =
    "  .form-control:disabled, .form-control[readonly] , #_builder-form .form-dropdown {max-width:" +
    fieldStyle.width +
    "px}";
  globalScriptCSSShortLabelHolder.value.textContent = `#_builder-form .short-label {
        color: #${fieldStyle.shortLabel?.color} !important;
        font-size: ${fieldStyle.shortLabel?.fontSize}px !important;
        font-weight: ${fieldStyle.shortLabel?.fontWeight};
        font-family: '${fieldStyle.shortLabel?.fontFamily}';
  }`;
  const regex = /\n/g;
  const styleStore = formStyle.customStyle.replace(regex, "").split("}");
  let styleString = "";
  styleStore.map(function (value: any, key: any) {
    if (value !== "" && value.length > 0) {
      styleString += "#_builder-form " + value + "} ";
    }
  });
  globalScriptCSSStyle.value.textContent = styleString;
};

//Store Subscription to watch on store value change using patch
store.$subscribe((mutation, state) => {
  setGlobalStyleInHTMl();
});

watch([store.fontCollection, () => store.builderConfig.isGDPRCompliant], () => {
  globalScriptCSSFontHolder.value.textContent = getAllFontsImport(
    store.fontCollection,
    store.builderConfig.isGDPRCompliant
  );
});
const isActiveElement = (index) => {
  return store.currentCustomSidebarPreview === index;
};
const changeLog = (e) => {
  clickedOutSideForm();
  if (e.added) {
    store.handleDropAndMove(e.added.element, e.added.newIndex);
  } else {
    store.handleDropAndMove(e.moved.element, e.moved.newIndex, e.moved.oldIndex);
  }
};

const clickedOutSideForm = () => {
  store.currentCustomSidebarPreview = undefined;
  store.isAnyElementActive = false;
};

const applyInlineStyle = (element) => {
  if (store.builderConfig.layout === CONSTANTS.SINGLE_LINE && !fieldFullWidth(element.type, true)) {
    return {
      width: element.fieldWidthPercentage + "%",
      marginBottom: store.formStyle.fieldSpacing + "px",
    };
  }
  if (
    store.builderConfig.layout !== CONSTANTS.SINGLE_LINE &&
    !fieldFullWidth(element.type, false)
  ) {
    return {
      width: element.fieldWidthPercentage + "%",
      marginBottom: store.formStyle.fieldSpacing + "px",
    };
  }
  return { marginBottom: store.formStyle.fieldSpacing + "px" };
};
</script>

<template>
  <section
    id="form-builder"
    class="hl_wrapper--inner form-builder"
    :style="{ backgroundImage: `url(${store.formStyle?.bgImage})`, backgroundSize: 'cover' }"
    @click.prevent="clickedOutSideForm"
  >
    <section class="hl_form-builder--main">
      <form id="_builder-form" name="builder-form" class="builder-preview">
        <div v-if="store.formAction?.headerImageSrc?.trim().length > 0">
          <div
            class="ghl-header-banner w-full max-w-full"
            :style="{
              maxWidth: store.formStyle.width + 'px',
            }"
          >
            <HLImage
              :url="store.formAction?.headerImageSrc"
              :width="store.formStyle.width"
              alt="banner"
            ></HLImage>
          </div>
        </div>
        <div id="the-form-builder" class="form-element form-builder--wrap" :style="formStyle">
          <div
            :class="
              store.builderConfig.layout !== CONSTANTS.TWO_COLUMN
                ? 'drop form-builder-drop builder-padding-remove'
                : 'drop form-builder-drop builder-inline'
            "
          >
            <VueDraggableNext
              id="form-builder-container"
              :model-value="store.elements"
              :class="
                [CONSTANTS.TWO_COLUMN, CONSTANTS.SINGLE_LINE].includes(store.builderConfig.layout)
                  ? 'smooth-dnd-container vertical row'
                  : 'smooth-dnd-container vertical'
              "
              group="elements"
              :sort="true"
              @change="changeLog"
            >
              <div
                v-for="(element, index) in elements"
                :key="index"
                :style="applyInlineStyle(element)"
                class="smooth-dnd-draggable-wrapper element-box menu-field-wrap form-field-wrapper"
                @click="(e) => clickedElement(e, element, index)"
              >
                <div :class="{ active: isActiveElement(index) }">
                  <StandardFieldPreview
                    v-if="!(element.id !== undefined || element?.custom)"
                    :element="element"
                    :index="index"
                    :current-element="currentElement"
                    :field-style="fieldStyle"
                    :form-label-visible="store.builderConfig.formLabelVisible"
                    :class="index % 2 == 0 ? 'f-even' : 'f-odd'"
                  ></StandardFieldPreview>
                  <CustomFieldsPreview
                    v-if="element.id !== undefined || element?.custom"
                    :element="element"
                    :index="index"
                    :current-element="currentElement"
                    :field-style="fieldStyle"
                    :form-label-visible="store.builderConfig.formLabelVisible"
                    :is-form="true"
                    :class="index % 2 == 0 ? 'f-even' : 'f-odd'"
                  ></CustomFieldsPreview>
                </div>
              </div>
            </VueDraggableNext>
          </div>
          <FormbuilderBranding
            :is-branding-active="store.formStyle.acBranding"
            :company="store.company"
          />
        </div>
      </form>
    </section>
  </section>
</template>

<style scoped>
.builder-preview {
  min-height: 450px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

#form-builder {
  background-color: #f3f4f6;
  margin-bottom: 0px;
  overflow: auto;
  height: calc(100vh - 52px);
}

.builder-item {
  cursor: pointer;
  width: 100%;
  background: grey;
  margin: 0.5rem 0;
  min-height: 3rem;
}

#_builder-form .active {
  border: 1px solid #ff9900;
}

.form-builder--item {
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid transparent;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  position: relative;
}
.builder-inline .smooth-dnd-draggable-wrapper.col-md-6 .f-even .form-builder--item {
  padding-left: 4px !important;
}

.builder-inline .smooth-dnd-draggable-wrapper.col-md-6 .f-odd .form-builder--item {
  padding-left: 10px;
  padding-right: 4px;
}

.builder-inline .smooth-dnd-draggable-wrapper.col-12 .form-builder--item {
  padding-left: 10px;
  padding-right: 4px;
}
.form-builder--item h1,
.form-builder--item h2,
.form-builder--item h3,
.form-builder--item h4,
.form-builder--item h5,
.form-builder--item h6 {
  margin-bottom: 0;
}
.form-builder--item label {
  font-size: 0.875rem;
  color: #2a3135;
}
.captcha-wrap-img {
  width: 335px;
  display: block;
}

.button-element {
  min-width: 85px;
}

.menu-item {
  position: relative;
}
.menu-field-wrap {
  position: relative;
}
#the-form-builder .menu-field-wrap .close-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  opacity: 1;
  transition: all 0.5s ease-in;
  font-size: 10px;
  background: #f5f5f5;
  padding: 5px 7px;
  border-radius: 50%;
  height: 20px;
  cursor: pointer;
}

#the-form-builder {
  margin: 0 auto;
  margin-bottom: 70px;
}

.form-builder-drop {
  min-height: 400px;
  padding: 30px 12px;
}
.builder-padding-remove {
  padding: 30px 0;
}
.add-dropzone-builder {
  background: transparent;
  width: 100%;
  height: 86px;
  border-width: 1;
  border-style: solid;
  border-color: #e5e7eb;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6c757d !important;
}
.add-dropzone-builder p {
  margin-left: 5px !important;
}
#form-builder-container {
  height: 100%;
  min-height: 340px;
}
.ghl-header-banner {
  margin: 0 auto;
}
:deep(.form-control) {
  font-size: 0.875rem;
}

.element-box {
  display: flex;
  flex-flow: column;
  height: 100%;
}
</style>
