<template>
  <p v-if="store.builderConfig?.conditionalLogic?.length" class="mb-3">
    <UIAlert id="warning-message" type="warning">
      <template #content> {{ t("common.conditionalLogicAlertOnSubmit") }}</template>
    </UIAlert>
  </p>
  <UISelect
    id="submit"
    :value="submitOption"
    :options="options"
    @update:value="selectionSubmitType"
  />
  <UIInput
    v-if="submitOption === 'url'"
    id="submitMessage"
    :model-value="store.formAction.redirectUrl"
    :placeholder="store.formAction.redirectUrl"
    @update:model-value="updateSubmitMessage"
    @blur="store.addToHistory()"
  />
  <div v-if="submitOption === 'url'" class="small-alert mt-2">
    <UIAlert id="info-message" type="info">
      <template #content>
        {{ t("common.addUrlLike") }} <b>example.com</b>, <b>https://example.com</b>,
        <b>example.com/path</b> {{ t("common.or") }} <b>https://www.example.com/path</b>.</template
      >
    </UIAlert>
  </div>
  <div v-if="submitOption === 'order'" class="small-alert mt-2">
    <UIAlert id="info-message" type="info">
      <template #content> {{ t("common.payment.product.orderConfirmationWarning") }}</template>
    </UIAlert>
  </div>
  <section v-if="submitOption === 'message'" class="w-100 flex flex-col gap-3">
    <RichTextEditor v-model:modelValue="thankYouMessage" :is-submit-message="true"></RichTextEditor>
    <MessageStyling></MessageStyling>
  </section>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UIAlert, UIInput, UISelect } from "@gohighlevel/ghl-ui";
import { computed, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import RichTextEditor from "../RichTextEditor.vue";
import MessageStyling from "./MessageStyling.vue";
const { t } = useI18n();

const store = useAppStore();

const props = defineProps({
  isSurvey: {
    type: Boolean,
    default: true,
  },
});
const formStoreSubmitMessageStyle = store.getFormSubmitMessageStyle;
const formSubmitMessageStyle = reactive(formStoreSubmitMessageStyle);

const options = [
  { label: t("common.openUrl"), value: "url" },
  { label: t("common.message"), value: "message" },
  { label: t("common.payment.product.orderConfirmation"), value: "order" },
];

//Remove Order confirmation option from option dropdown
if (props.isSurvey) {
  options.pop();
}

watch(
  () => store.formAction.actionType,
  async (newValue: any) => {
    await (submitOption.value = options[parseInt(store.formAction.actionType) - 1].value);
  }
);

const submitOption = ref(options[parseInt(store.formAction.actionType) - 1].value);

const selectionSubmitType = (type) => {
  if (type === "url") {
    store.formAction.actionType = "1";
    submitOption.value = "url";
  }
  if (type === "message") {
    store.formAction.actionType = "2";
    submitOption.value = "message";
  }
  if (type === "order") {
    store.formAction.actionType = "3";
    submitOption.value = "order";
  }
  store.anyUnsavedChanges = true;
  store.addToHistory();
};

const thankYouMessage = computed({
  get() {
    return store.formAction.thankyouText;
  },
  set(value: string) {
    store.formAction.thankyouText = value;
    store.anyUnsavedChanges = true;
  },
});

const updateSubmitMessage = (value: string) => {
  store.formAction.redirectUrl = value;
  store.anyUnsavedChanges = true;
};
</script>
