<template>
  <div class="flex items-center justify-between gap-4 self-stretch">
    <UITextSmMedium>
      {{ props.label }}
      <UITooltip v-if="tooltip" :style="{ maxWidth: '300px' }" trigger="hover" placement="top">
        <template #trigger>
          <InfoCircleIcon class="ml-1 h-4 w-4 text-gray-400"></InfoCircleIcon>
        </template>
        {{ props.tooltipMessage }}
      </UITooltip>
    </UITextSmMedium>
    <div :class="tooltip ? 'text-center' : 'text-left'">
      <UISwitch
        size="medium"
        :value="props.value"
        :disabled="props.disabled"
        @update:value="handleUpdate"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { InfoCircleIcon } from "@gohighlevel/ghl-icons/24/outline";
import { UISwitch, UITextSmMedium, UITooltip } from "@gohighlevel/ghl-ui";
import debounce from "lodash/debounce";
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  value: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: Boolean,
    default: false,
  },
  tooltipMessage: {
    type: String,
    default: "Added some tooltip message",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:value"]);
const store = useAppStore();

const handleUpdate = debounce((val) => {
  emit("update:value", val);
  store.addToHistory();
}, 10);
</script>

<style scoped>
.switch-container {
  font-size: 0.8rem;
}
.switch-handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.switch {
  display: inline;
}
</style>
